import {useMutation, useQueryClient} from 'react-query'
import {QUERIES_KEYS} from '../../../helpers/crud-helpers/consts'
import {updateVehicle} from '../core/_requests'
import {IVehicle} from '../core/_models'

function useUpdateVehicle() {
  const queryClient = useQueryClient()

  const {mutate, isLoading, isError, isSuccess} = useMutation(
    (body: IVehicle) => {
      const {_id, ...rest} = body
      return updateVehicle(rest, _id ?? '')
    },
    {
      // ✅ Show success message
      onSuccess: () => {
        queryClient.invalidateQueries(QUERIES_KEYS.VEHICLE_LIST)
      },
    }
  )

  return {
    updateVehicle: mutate,
    isLoadingUpdateVehicle: isLoading,
    isErrorUpdateVehicle: isError,
    isSuccessUpdateVehicle: isSuccess,
  }
}

export default useUpdateVehicle
