import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'

import {USER_ROLE} from '../constants/constants'
import ProfilePage from '../modules/profile/ProfilePage'
import Products from '../pages/product/products'
import Workshops from '../pages/workshop/workshop'
import Users from '../pages/users/user'
import Vehicles from '../pages/vehicles/vehicle'
import {WorkshopDetails} from '../pages/workshop/components/workshop-details'
import Service from '../pages/service/service'
import {ServiceItem} from '../pages/service/components/ServiceItem'
import {VehicleDetails} from 'app/pages/vehicles/components/VehicleDetails'
import {VerifyUserEmail} from 'app/modules/auth/components/VerifyUserEmail'
import {Profile} from 'app/pages/profile/profile'
import Customer from 'app/pages/customers/customer'
import ServicesList from 'app/pages/services-list/lists'
import CustomerVehicle from 'app/pages/customers/vehicle/customerVehicle'

const PrivateRoutes = ({role}) => {
  const getRoleBasedRoutes = (role) => {
    if (role === USER_ROLE.SUPER_USER) {
      return (
        <>
          <Route path='dashboard' element={<DashboardWrapper />} />
          <Route path='emailverification' element={<VerifyUserEmail />} />
          <Route path='profile' element={<Profile />} />
          <Route path='workshop' element={<Workshops />} />
          <Route path='workshop/:id' element={<WorkshopDetails />} />
          <Route path='service' element={<Service />} />
          <Route path='checklist' element={<ServicesList />} />
          <Route path='vehicle' element={<Vehicles />} />
          <Route path='vehicle/:id' element={<VehicleDetails />} />
          <Route path='service/:id' element={<ServiceItem />} />
          <Route path='product' element={<Products />} />
          <Route path='staff' element={<Users />} />
          <Route path='customers' element={<Customer />} />
        </>
      )
    }
    if (role === USER_ROLE.WORKSHOP_OWNER) {
      return (
        <>
          <Route path='dashboard' element={<DashboardWrapper />} />
          <Route path='emailverification' element={<VerifyUserEmail />} />
          <Route path='profile' element={<Profile />} />
          <Route path='workshop' element={<Workshops />} />
          <Route path='workshop/:id' element={<WorkshopDetails />} />
          <Route path='service' element={<Service />} />
          <Route path='checklist' element={<ServicesList />} />
          <Route path='vehicles' element={<CustomerVehicle />} />
          <Route path='service/:id' element={<ServiceItem />} />
          <Route path='product' element={<Products />} />
          <Route path='staff' element={<Users />} />
          <Route path='customers' element={<Customer />} />
        </>
      )
    }

    if (role === USER_ROLE.SERVICE_MANAGER) {
      return (
        <>
          <Route path='dashboard' element={<DashboardWrapper />} />
          <Route path='emailverification' element={<VerifyUserEmail />} />
          <Route path='workshop' element={<Workshops />} />
          <Route path='workshop/:id' element={<WorkshopDetails />} />
          <Route path='profile' element={<Profile />} />
          <Route path='service' element={<Service />} />
          <Route path='service/:id' element={<ServiceItem />} />
          <Route path='product' element={<Products />} />
          <Route path='staff' element={<Users />} />
          <Route path='customers' element={<Customer />} />
          <Route path='vehicles' element={<CustomerVehicle />} />
          <Route path='checklist' element={<ServicesList />} />
        </>
      )
    }

    if (role === USER_ROLE.SENIOR_MECHANIC) {
      return (
        <>
          <Route path='dashboard' element={<DashboardWrapper />} />
          <Route path='emailverification' element={<VerifyUserEmail />} />
          <Route path='profile' element={<Profile />} />
          <Route path='service' element={<Service />} />
          <Route path='service/:id' element={<ServiceItem />} />
          {/* <Route path='profile' element={<Service />} /> */}
        </>
      )
    }

    if (role === USER_ROLE.JUNIOR_MECHANIC) {
      return (
        <>
          <Route path='dashboard' element={<DashboardWrapper />} />
          <Route path='emailverification' element={<VerifyUserEmail />} />
          <Route path='service' element={<Service />} />
          <Route path='service/:id' element={<ServiceItem />} />
          {/* <Route path='profile' element={<Service />} /> */}
          <Route path='product' element={<Products />} />
          <Route path='profile' element={<Profile />} />
        </>
      )
    }

    if (role === USER_ROLE.VEHICLE_OWNER) {
      return (
        <>
          <Route path='dashboard' element={<DashboardWrapper />} />
          <Route path='emailverification' element={<VerifyUserEmail />} />
          <Route path='service' element={<Service />} />
          <Route path='profile' element={<Profile />} />
          <Route path='service/:id' element={<ServiceItem />} />
          <Route path='vehicle' element={<Vehicles />} />
          <Route path='vehicle/:id' element={<VehicleDetails />} />
          <Route path='profile' element={<Service />} />
        </>
      )
    }
  }

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {getRoleBasedRoutes(role)}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
