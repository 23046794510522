import {useMutation, useQueryClient} from 'react-query'

import {QUERIES_KEYS} from '../../../../helpers/crud-helpers/consts'
import {createProduct, updateProduct} from '../_request'
import {IProduct} from '../_models'

function useUpdateProduct() {
  const queryClient = useQueryClient()

  const {mutate, isLoading, isError, isSuccess} = useMutation(
    (body: IProduct) => {
      const {_id, ...rest} = body
      return updateProduct(rest, _id ?? '')
    },
    {
      // ✅ Show success message
      onSuccess: () => {
        // console.log('Test updated successfully')
        queryClient.invalidateQueries(QUERIES_KEYS.PRODUCT_LIST)
      },
    }
  )

  return {
    updateProduct: mutate,
    isLoadingUpdateProduct: isLoading,
    isErrorUpdateProduct: isError,
    isSuccessUpdateProduct: isSuccess,
  }
}

export default useUpdateProduct
