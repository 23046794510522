import {useQuery} from 'react-query'
import {getWorkshopById} from '../core/_requests'
import {QUERIES_KEYS} from '../../../helpers/crud-helpers/consts'

function useWorkshopById(workshopId) {
  const {data, isSuccess, isLoading, isFetching} = useQuery(
    [QUERIES_KEYS.WORKSHOP_LIST, workshopId], // Unique query key based on workshopId
    () => getWorkshopById(workshopId), // Fetch workshop by its ID
    {
      select: (res) => res,
      refetchOnWindowFocus: false,
    }
  )

  return {
    workshopData: data,
    isLoadingWorkshop: isLoading || isFetching,
    isLoadingWorkshopData: isSuccess,
  }
}

export default useWorkshopById

// import {useQuery} from 'react-query'
// import {getWorkshopById} from '../core/_requests'
// import {QUERIES_KEYS} from '../../../helpers/crud-helpers/consts'

// function useWorkshopById(id) {
//   const {data, isSuccess, isLoading, isFetching, isError} = useQuery(
//     QUERIES_KEYS.WORKSHOP_LIST, // Use a unique query key based on the ID
//     () => getWorkshopById(id), // Call fetchWorkshopById with the provided ID
//     {
//       select: (res) => res,
//       refetchOnWindowFocus: false,
//     }
//   )

//   return {
//     workshopDataGotById: data,
//     isLoadingWorkshop: isLoading || isFetching,
//     isSuccess,
//     isError,
//     // getUserWorkshopById: refetch, // Expose the refetch function
//   }
// }

// export default useWorkshopById
