import React from 'react'
import {useNavigate} from 'react-router-dom'

export const DashboardCard = ({color, img, title, quantity}) => {
  const navigate = useNavigate()
  return (
    <div
      className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end mb-5 mb-xl-10 w-md-25 w-75 p-lg-6 p-3`}
      style={{
        backgroundColor: color,
        // backgroundImage: `url('${img}')`,
        marginLeft: '2%',
        height: '100%',
      }}
      // onClick={()=>{navigate(`${route}`)}}
    >
      <div className='card-header pt-5'>
        <div className='card-title d-flex align-content-start flex-column'>
          <span className='fs-lg-2hx fs-1 fw-bold text-white me-2 lh-1 ls-n2'>{title}</span>

          <span className='text-white opacity-75 pt-1 fw-semibold fs-6'>
            {/* {vehicle?.year + '-' + vehicle?.make || 'Make model'} */}
          </span>
        </div>
      </div>
      <div className='card-body d-flex align-items-end pt-0'>
        <div className='d-flex align-items-center flex-column mt-3 w-100'>
          <div className='d-flex justify-content-between fw-bold fs-1 text-white opacity-75 w-100 mt-auto mb-2'>
            <span>{quantity ? quantity : 0}</span>
          </div>
        </div>
      </div>
    </div>
  )
}
