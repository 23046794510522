import {useMutation, useQueryClient} from 'react-query'
import {QUERIES_KEYS} from '../../../helpers/crud-helpers/consts'

import {IAddService} from '../core/_models'
import {createService} from '../core/_requests'

function useCreateService() {
  const queryClient = useQueryClient()

  const {mutate, isLoading, isError, error, isSuccess} = useMutation(
    (body: IAddService) => createService(body),
    {
      onSuccess: () => {
        //console.log('Service created succesfully')
        queryClient.invalidateQueries(QUERIES_KEYS.SERVICE_LIST)
      },
    }
  )

  return {
    createService: mutate,
    isLoadingCreateService: isLoading,
    isErrorCreateService: isError,
    errorCreateService: error,
    isSuccessCreateService: isSuccess,
  }
}

export default useCreateService
