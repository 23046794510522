import {useMutation, useQueryClient} from 'react-query'
import {updateServiceCheckList} from '../_requests'
import {QUERIES_KEYS} from 'app/helpers/crud-helpers/consts'

function useUpdateServiceCheckList() {
  const queryClient = useQueryClient()

  const {mutate, isLoading, isError, isSuccess} = useMutation(
    (body: any) => {
      const {_id, ...rest} = body
      return updateServiceCheckList(rest, _id ?? '')
    },
    {
      // ✅ Show success message
      onSuccess: () => {
        queryClient.invalidateQueries(QUERIES_KEYS.SERVICE_CHECK_LIST)
      },
    }
  )

  return {
    updateServiceCheckList: mutate,
    isLoadingUpdateServiceCheckList: isLoading,
    isErrorUpdateServiceCheckList: isError,
    isSuccessUpdateServiceCheckList: isSuccess,
  }
}

export default useUpdateServiceCheckList
