import {useQuery} from 'react-query'

import {getDashboardData} from '../_request'

const DASHBOARD_DATA = 'get-dashboard-data'

function useDashboard() {
  const {data, isLoading, isFetching, isSuccess} = useQuery(DASHBOARD_DATA, getDashboardData, {
    select: (res) => {
      if (res) {
        return res?.counts
      }
      return undefined
    },
    refetchOnWindowFocus: false,
  })
  return {
    userDashboardData: data,
    isLoadingUserDashboardData: isLoading || isFetching,
    isSuccessUserDashboardData: isSuccess,
  }
}

export default useDashboard
