import {useCallback, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useDataContext} from '../../../modules/data'
import {UserForm} from './addUserForm'
import UserRow from './userRow'

function UserTable({users}) {
  const {modal, setModal} = useDataContext()

  const [searchQuery, setSearchQuery] = useState('')

  const [filteredUsers, setFilteredUsers] = useState([])

  const updateUsers = (str) => {
    setSearchQuery(str)
    setFilteredUsers(() => searchArray(str))
  }

  const searchArray = useCallback(
    (searchTerm) => {
      searchTerm = searchTerm.toLowerCase() // Convert the search term to lowercase for case-insensitive search

      return users.filter((obj) => {
        obj = {...obj, workshop: obj.workshop.name}
        // Check if any property of the object contains the search term
        return Object.values(obj).some((value) => {
          if (typeof value === 'string' || typeof value === 'number') {
            return value.toString().toLowerCase().includes(searchTerm)
          }
          return false
        })
      })
    },
    [users]
  )

  return (
    <>
      <div className={`card`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Users</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>All Users</span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <button
              type='button'
              className='btn btn-primary'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_user_add'
              onClick={() => {
                setModal(true)
              }}
            >
              Add User
            </button>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='row mb-8'>
            <div className='input-group w-50'>
              <span className='input-group-text'>
                <i className='fa fa-search'></i>
              </span>
              <input
                type='text'
                className='form-control'
                placeholder='Search'
                value={searchQuery}
                onChange={(e) => {
                  updateUsers((e.target as HTMLInputElement).value)
                }}
              />
              {searchQuery.length ? (
                <span
                  className='input-group-text'
                  onClick={() => {
                    updateUsers('')
                  }}
                >
                  <i className='far fa-window-close'></i>
                </span>
              ) : null}
            </div>
          </div>

          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}

            {(searchQuery.length > 0 ? filteredUsers.length > 0 : true) && users.length > 0 ? (
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='ps-4 min-w-200px rounded-start'>Name</th>
                    <th className=''>Email</th>
                    <th className=''>Workshop</th>
                    <th className=''>Actions</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {searchQuery.length > 0
                    ? filteredUsers?.map((user) => <UserRow user={user} />)
                    : users?.map((user) => <UserRow user={user} />)}
                </tbody>
                {/* end::Table body */}
              </table>
            ) : (
              'No users found'
            )}
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export default UserTable
