import useVehicle from './hooks/use-vehicle'
import {VehicleTable} from './components/VehicleTable'
import {CustomPageLoader} from 'app/modules/auth/components/CustomPageLoader'

function Vehicles() {
  const {vehicleData, isLoadingVehicle} = useVehicle()

  return isLoadingVehicle ? (
    <CustomPageLoader />
  ) : (
    <>
      <VehicleTable products={vehicleData} />
    </>
  )
}

export default Vehicles
