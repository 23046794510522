import {CustomPageLoader} from 'app/modules/auth/components/CustomPageLoader'
import {ServiceTable} from './components/ServiceTable'
import useService from './hooks/use-get-service'

function Service() {
  const {serviceData, isLoadingService} = useService()
  return isLoadingService ? (
    <CustomPageLoader />
  ) : (
    <ServiceTable services={serviceData} className=''></ServiceTable>
  )
}

export default Service
