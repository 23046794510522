/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

type Props = {
  className: string
  checks: any
}

const ChecksInfo: React.FC<Props> = ({className, checks}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <h3 className='card-title fw-bold text-dark'> Service Checks</h3>
      </div>

      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-2'>
        {checks?.map((check, idx) => {
          // Define an array of bullet colors
          const bulletColors = ['primary', 'warning', 'primary', 'danger', 'success']

          // Calculate the color based on the index
          const bulletColor = bulletColors[idx % bulletColors.length]

          return (
            <div className='d-flex align-items-center mb-8'>
              {/* begin::Bullet */}
              <span className={`bullet bullet-vertical h-40px bg-${bulletColor}`}></span>
              {/* end::Bullet */}
              {/* ... rest of the code ... */}
              <div className='flex-grow-1'>
                <a href='#' className='text-gray-800 text-hover-primary fw-bold fs-6 mx-5'>
                  {check}
                </a>
              </div>
            </div>
          )
        })}

        {/* end:Item */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ChecksInfo}
