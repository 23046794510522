import {QUERIES_KEYS} from 'app/helpers/crud-helpers/consts'
import {useMutation, useQueryClient} from 'react-query'
import {addServiceCheckList} from '../_requests'

function useCreateServiceCheckList() {
  const queryClient = useQueryClient()

  const {mutate, isLoading, isError, isSuccess} = useMutation(
    (body: any) => addServiceCheckList(body),
    {
      onSuccess: () => {
        // console.log('Test created successfully')
        queryClient.invalidateQueries(QUERIES_KEYS.SERVICE_CHECK_LIST)
      },
    }
  )

  return {
    createServiceCheckList: mutate,
    isLoadingCreateServiceCheckList: isLoading,
    isErrorCreateServiceCheckList: isError,
    isSuccessCreateServiceCheckList: isSuccess,
  }
}

export default useCreateServiceCheckList
