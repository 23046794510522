import axios from 'axios'
const SERVICES_CHECKLIST = '/check'
export function getServicesCheckList(): Promise<any> {
  return axios.get(SERVICES_CHECKLIST).then((response) => response.data)
}

export function addServiceCheckList(body: any) {
  return axios.post(SERVICES_CHECKLIST, body)
}
export function updateServiceCheckList(body: any, id: string): Promise<any | undefined> {
  return axios.patch(`${SERVICES_CHECKLIST}/${id}`, body)
}

export function deleteServiceCheckList(Id: string) {
  return axios.delete(`${SERVICES_CHECKLIST}/${Id}`)
}
