import {KTIcon} from '../../../_metronic/helpers'
import {useDataContext} from '../../modules/data'
import {ProductTable} from './components/ProductTable'
import useProduct from './core/hooks/use-products'
import {CustomPageLoader} from 'app/modules/auth/components/CustomPageLoader'
// const dummyProduct = [
//   {
//     name: 'poin',
//     cost: 11,
//     units: 10,
//     retailPrice: 12,
//     type: 'forklift',
//     brand: 'nvnvnv',
//     workshop: '65c31615da3002731f090e6c',
//   },
// ]
function Products() {
  const {productsData, isLoadingUsers, productQueryParams, productUpdateQueryParams} = useProduct()

  const {setProducts} = useDataContext()

  setProducts(productsData)

  return isLoadingUsers ? (
    <CustomPageLoader />
  ) : (
    <>
      <ProductTable
        products={productsData}
        productQueryParams={productQueryParams}
        updateProductQueryParams={productUpdateQueryParams}
      />
    </>
  )
}

export default Products
