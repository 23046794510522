import {KTIcon} from '_metronic/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import React, {useEffect, useState, useRef} from 'react'
import {useDataContext} from 'app/modules/data'
import {IUpdateInspection} from '../../core/inspection/_inspectionModels'
import Swal from 'sweetalert2'
import JoditEditor from 'jodit-react'
import UploadIcon from '../../../../assets/new-proj-upload-icon.svg'
import useUpdateInspection from '../../hooks/inspection-hooks/use-update-inspection'
import {check} from 'prettier'
import useInspectionByServiceId from '../../hooks/inspection-hooks/use-get-insepection-by-serviceid'
import {useAuth} from 'app/modules/auth'
import {USER_ROLE} from 'app/constants/constants'
import {MediaType} from 'app/helpers/image-uploader/_model'
import useHandleImageUpload from 'app/helpers/image-uploader/hooks/use-handle-upload-image'
import useUpdateService from '../../hooks/use-update-service'
// import useCreateVehicle from '../hooks/use-create-vehicle'
// import useUpdateVehicle from '../hooks/use-update-vehicle'
type Props = {
  setServiceData: any
}

const productDetailsSchema = Yup.object().shape({
  notes: Yup.string().required('Notes are Required'),
  //externalNotes: Yup.string().required('External Notes are Required'),
})

const UpdateInspectionForm: React.FC<Props> = ({setServiceData}) => {
  const {handleChange, repoData, isLoadingRepo} = useHandleImageUpload()
  const {inspection, setInspection, setService, setCallCreate} = useDataContext()
  // console.log('inspection here is ', inspection?.notes, inspection?.notes.length)
  const [editorData, setEditorData] = useState('')
  // const [externalNotesEditorData, seexternalNotesEditorData] = useState('')
  const {updateService} = useUpdateService()
  useEffect(() => {
    inspection?.notes && setEditorData(inspection.notes)
    // inspection?.externalNotes && seexternalNotesEditorData(inspection.externalNotes)
  }, [inspection])
  const {service} = useDataContext()
  const editorRef = useRef(null)
  // const externalNotesEditorRef = useRef(null)
  const [dum, setdum] = useState(false)
  const {currentUser} = useAuth()
  const isSeniorMechanic = currentUser?.data.user.role === USER_ROLE.SENIOR_MECHANIC
  const {
    updateInspection,
    isLoadingUpdateInspection,
    updatedService,
    isErrorUpdateInspection,
    isSuccessUpdateInspection,
  } = useUpdateInspection()
  const [imageLoading, setImageLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState<string[]>([])
  const handleUploadImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setImageLoading(true)

    const mediaType = `${MediaType.SERVICE}`
    handleChange(event, setImageLoading, setImageUrl, mediaType, service?._id)
  }
  // console.log(isSeniorMechanic, inspection?.status, service)
  const isReadOnly = isSeniorMechanic && inspection?.status === 0 && service?.status === 7
  // const {getInspections,isGetInspectionSuccess, isGetInspectionError} =
  //   useInspectionByServiceId(inspection?._id)
  //console.log(updatedService, 'updated dta')
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      status: inspection?.status,
      checks: inspection?.checks,
      notes: inspection?.notes || '',
      //  externalNotes: inspection?.externalNotes,
    },
    // validationSchema: productDetailsSchema,
    onSubmit: async (values) => {
      if (imageUrl.length > 0) {
        updateService({id: service?._id, body: {photos: [...service?.photos, ...imageUrl]}})
      }
      const updatedData: IUpdateInspection = {
        // ...(imageUrl.length > 0 && {photos: [...imageUrl]}),
        status: formik.values.checks?.every((check) => check.status === 1) ? 2 : 2,
        checks: values.checks?.map((check) => {
          return {name: check.name, status: check.status, notes: ''}
        }),
        service: service?._id,
        notes: values?.notes,
        //   externalNotes: values?.externalNotes,
      }
      updateInspection({body: updatedData, _id: inspection?._id})

      // setdum(true)
    },
  })
  const handleSetToInitial = () => {
    setInspection(undefined)
    setEditorData('')
    setImageUrl([])
    //  seexternalNotesEditorData('')
    formik.resetForm()
    setCallCreate(true)
  }
  useEffect(() => {
    if (isErrorUpdateInspection) {
      handleSetToInitial()
      Swal.fire({
        title: 'Error',
        text: 'Error Updating Inspection. Try again',
        icon: 'error',
        confirmButtonText: 'Ok',
      }).then((result) => {
        // console.log('Fire erro result ', result)
      })
    } else if (isSuccessUpdateInspection) {
      handleSetToInitial()
      Swal.fire({
        title: 'Success',
        text: 'updated Successfully!',
        icon: 'success',
        confirmButtonText: 'Done',
      }).then((result) => {
        //  console.log('Fire result ', result)
        //clode modal here and re-get all inspections
      })
    }
  }, [isErrorUpdateInspection, isSuccessUpdateInspection])
  useEffect(() => {
    setServiceData(updatedService?.data)
    // console.log('Updated dta =>', updatedService)
  }, [updatedService])
  useEffect(() => {
    //console.log('Setting editor data in formik', editorData)
    formik.setFieldValue('notes', editorData)
  }, [editorData])
  // useEffect(() => {
  //   //console.log('Setting editor data in formik', editorData)
  //   formik.setFieldValue('externalNotes', externalNotesEditorData)
  // }, [externalNotesEditorData])
  // // useEffect(() => {
  //   //console.log('DUM IS ', dum)
  //   if (dum == true) {
  //     setInspection(inspection)
  //     setEditorData('')
  //   }
  // }, [isSuccessUpdateInspection, dum])
  const uploadButton = (
    <div className=' h-36 w-36 bg-gray-100 border-[3px] border-[#BA9A28] hover:bg-[#e1e1e1] transition rounded-full flex flex-centered'>
      <img src={UploadIcon} alt='Upload Icon' />
    </div>
  )
  useEffect(() => {
    if (repoData) {
      // console.log('repo data', repoData)
      setImageUrl((images) => [...images, repoData?.signedUrl?.split('?')[0]])
      //setImageUrl(repoData?.signedUrl?.split('?')[0])
    }
  }, [repoData])

  return (
    <div className='modal fade' id='kt_modal_update_inspection' aria-hidden='true'>
      <div className='modal-dialog mw-750px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-space-between'>
            <h3 className='text-center'>Inspection Update</h3>
            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              data-bs-dismiss='modal'
              style={{borderRadius: '50%', border: '1px solid'}}
              onClick={(e) => {
                e.preventDefault()
                handleSetToInitial()
                setImageUrl([])
              }}
            >
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <form onSubmit={formik.handleSubmit} noValidate className='form'>
              <div className='card-body border-top p-5'>
                <div className='row mb-6'>
                  <label className='col-lg-2 col-form-label required fw-bold fs-6'>
                    Service Checks
                  </label>

                  <div className='col-lg-10'>
                    {formik.values.checks?.map((check, index) => (
                      <div className='row mt-5'>
                        <div className='col-lg-12 fv-row' key={index}>
                          <div className='form-check form-check-sm form-check-custom form-check-solid'>
                            <input
                              className='form-check-input widget-13-check mx-5'
                              type='checkbox'
                              readOnly={isReadOnly == false}
                              value={check.status}
                              checked={check.status == 2}
                              onChange={
                                !isReadOnly
                                  ? undefined
                                  : (e) => {
                                      formik.setFieldValue(
                                        `checks[${index}].status`,
                                        e.target.checked ? 2 : 1
                                      )
                                    }
                              }
                              // onChange={(e) => {
                              //   formik.setFieldValue(
                              //     `checks[${index}].status`,
                              //     e.target.checked ? 2 : 1
                              //   )
                              // }}
                            />
                            <a href='#' className='text-gray-900 fw-bold text-hover-primary fs-6 '>
                              {check.name}
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-2 col-form-label required fw-bold fs-6'>Notes</label>

                  <div className='col-lg-10'>
                    <div className='row' style={{maxHeight: '300px', overflowY: 'auto'}}>
                      <div className='col-lg-12 fv-row'>
                        <JoditEditor
                          ref={editorRef}
                          value={editorData}
                          config={{
                            readonly: !isReadOnly,
                          }}
                          onBlur={(newContent) => setEditorData(newContent)}
                          //onChange={(newContent) => setEditorData(`${editorData} ${newContent}`)}  setEditorData(newContent.replace(/<[^>]+>/g, '').trim())
                        />
                      </div>
                    </div>
                    {formik.touched.notes && formik.errors.notes && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.notes}</div>
                      </div>
                    )}
                  </div>
                </div>
                {isReadOnly && (
                  <div className='row mb-6'>
                    <label className='col-lg-2 col-form-label required fw-bold fs-6'>Photos</label>
                    <div className='col-lg-10'>
                      <input
                        type='file'
                        accept='image/*'
                        onChange={handleUploadImageChange}
                        style={{display: 'none'}}
                        id='imageinspectionUploadInput'
                      />
                      <label
                        htmlFor='imageinspectionUploadInput'
                        className='relative flex rounded-full cursor-pointer flex-centered md:h-40 md:w-40 h-36 w-36'
                      >
                        {imageUrl?.map((imageItem: any) => (
                          <img
                            className='rounded-circle object-cover mt-5 ms-5  hover:cursor-pointer md:h-40 md:w-40 h-36 w-36'
                            src={imageItem}
                            alt='avatar'
                            style={{width: '100px', height: '100px'}}
                          />
                        ))}

                        <div
                          className='ms-5 mt-5 rounded-circle bg-secondary d-flex align-items-center justify-content-center'
                          style={{width: '90px', height: '80px'}}
                        >
                          <img src={UploadIcon} alt='Upload Icon' className='w-20 h-20' />
                        </div>
                      </label>
                      <p className='mt-1 ms-5 font-medium text-gray-400'>Upload Picture</p>
                    </div>
                  </div>
                )}

                {/*   {!isReadOnly && (
                      <>
                        <input
                          type='file'
                          required
                          accept='image/*'
                          onChange={handleUploadImageChange}
                          style={{display: 'none'}}
                          id='imageUploadInputinspection'
                          className='d-none'
                        />
                        <label
                          htmlFor='imageUploadInputinspection'
                          className='d-inline-flex align-items-center justify-content-center rounded-circle border border-gray-300 w-36 h-36 cursor-pointer'
                        >
                          <p className='text-gray-400 font-weight-bold'>Upload Picture</p>
                        </label>
                      </>
                    )}
                    {imageUrl.length > 0 ? (
                      <div className='d-flex'>
                        {imageUrl.map((imageItem, index) => (
                          <img
                            key={index}
                            className='rounded-circle mx-2 border border-gray-300'
                            src={imageItem}
                            alt='avatar'
                            style={{width: '100px', height: '100px'}}
                          />
                        ))}

                        <label
                          htmlFor='imageUploadInputinspection'
                          className='d-flex align-items-center justify-content-center rounded-circle border border-gray-300 w-36 h-36 m-2'
                        >
                          <div className='absolute inset-0 bg-black opacity-0 hover:opacity-25 transition duration-300 rounded-full flex items-center justify-center'>
                            <img src={UploadIcon} alt='Upload Icon' className='w-6 h-6' />
                          </div>
                        </label>
                      </div>
                    ) : (
                      uploadButton
                    )}
                    
                    
                    <div className='row mb-6'>
                  <label className='col-lg-2 col-form-label required fw-bold fs-6'>
                    External Notes
                  </label>

                  <div className='col-lg-10'>
                    <div className='row' style={{maxHeight: '300px', overflowY: 'auto'}}>
                      <div className='col-lg-12 fv-row'>
                        <JoditEditor
                          ref={externalNotesEditorRef}
                          value={externalNotesEditorData}
                          config={{
                            readonly: !isReadOnly,
                          }}
                          onBlur={(newContent) => seexternalNotesEditorData(newContent)}
                          //onChange={(newContent) => setEditorData(`${editorData} ${newContent}`)}  setEditorData(newContent.replace(/<[^>]+>/g, '').trim())
                        />
                      </div>
                    </div>
                    {formik.touched.externalNotes && formik.errors.externalNotes && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.externalNotes}</div>
                      </div>
                    )}
                  </div>
                </div> */}
              </div>

              {isReadOnly && (
                <div className='card-footer d-flex justify-content-end py-6 px-5 '>
                  <button
                    type='submit'
                    disabled={
                      isLoadingRepo ||
                      !formik.isValid ||
                      !formik.dirty ||
                      isLoadingUpdateInspection ||
                      formik.values.notes.length === 0
                    }
                    className='btn btn-primary'
                    data-bs-dismiss='modal'
                    //workright {...(isSuccessUpdateInspection ? null : {'data-bs-dismiss': 'modal'})}
                  >
                    {!isLoadingRepo
                      ? formik.values.checks?.every((check) => check.status === 2)
                        ? 'Submit'
                        : 'Reject'
                      : 'Loading image...'}
                    {/* {isLoadingCreateService && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )} */}
                  </button>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export {UpdateInspectionForm}
