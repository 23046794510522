import {useCallback, useState} from 'react'
import {useDataContext} from '../../../modules/data'
import {CheckListCard} from './checklistCard'
import useWorkshop from 'app/pages/workshop/hooks/use-workshop'
import {Select} from 'antd'

function ServiceCheckListTable({workshops}) {
  const {workshopsData, isLoadingWorkshops} = useWorkshop()

  const [searchQuery, setSearchQuery] = useState('')
  const [filteredWorkshop, setFilteredWorkshop] = useState([])

  const updateWorkshops = (str) => {
    setSearchQuery(str)
    setFilteredWorkshop(() => searchArray(str))
  }

  const searchArray = useCallback(
    (searchTerm) => {
      searchTerm = searchTerm.toLowerCase() // Convert the search term to lowercase for case-insensitive search

      return workshops.filter((obj) => {
        // Check if name, email, or phone contains the search term
        return ['name', 'email', 'phoneNumber', 'address', 'website', 'workshop'].some((key) => {
          const value = obj[key]

          if (typeof value === 'string' || typeof value === 'number') {
            return value.toString().toLowerCase().includes(searchTerm)
          }

          return false
        })
      })
    },
    [workshops]
  )

  const filterOption = (input: string, option?: {children: string; value: string}) => {
    return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
  }

  const onSelectWorkShop = (value: string) => {
    updateWorkshops(value)
  }
  const onClearWorkShop = () => {
    updateWorkshops('')
  }

  // const onSearchWorkShop = (value: string) => {
  //   // setSearchTerm(value);
  // }

  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>
          My Service Check List
          <span className='fs-6 text-gray-400 fw-bold ms-1'>Active</span>
        </h3>

        <div className='card-header border-0 pt-5'>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a workshop'
          >
            <button
              type='button'
              className='btn btn-primary'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_servicechecklist_add'
            >
              Add New CheckList
            </button>
          </div>
        </div>
      </div>

      {/* begin::Row */}
      <div className='row g-6 g-xl-9'>
        <div style={{display: 'flex', gap: '1rem'}}>
          <div className='w-400px'>
            <input
              type='text'
              className='form-control'
              style={{borderColor: '#DCDCDC', color: '#DCDCDC'}}
              placeholder='Search'
              onChange={(e) => {
                updateWorkshops((e.target as HTMLInputElement).value.trim())
              }}
            />
          </div>
          <div className='w-400px p-0'>
            <Select
              showSearch
              allowClear
              className='w-100 h-100'
              placeholder='Select WorkShop'
              optionFilterProp='children'
              onSelect={onSelectWorkShop}
              // onSearch={onSearchWorkShop}
              onClear={onClearWorkShop}
              filterOption={filterOption}
              loading={isLoadingWorkshops}
            >
              {workshopsData &&
                workshopsData?.map((workShop) => (
                  <Select.Option key={workShop?._id}>{`${workShop?.name}`}</Select.Option>
                ))}
            </Select>
          </div>
        </div>

        {searchQuery?.length > 0
          ? filteredWorkshop?.map((product) => (
              <div className='col-md-6 col-xl-4'>
                <CheckListCard
                  //   icon='https://media.istockphoto.com/id/1285071153/photo/smiling-happy-bearded-tattooed-worker-in-overalls-standing-next-to-truck-with-arms-crossed.jpg?s=612x612&w=0&k=20&c=mmuyDwuu8B6QOZ9x4I3VHL6XmxF9QR9qZROAnulei4E='
                  badgeColor='info'
                  workshop={product}
                />
              </div>
            ))
          : workshops?.map((product) => (
              <div className='col-md-6 col-xl-4'>
                <CheckListCard
                  //   icon='https://media.istockphoto.com/id/1285071153/photo/smiling-happy-bearded-tattooed-worker-in-overalls-standing-next-to-truck-with-arms-crossed.jpg?s=612x612&w=0&k=20&c=mmuyDwuu8B6QOZ9x4I3VHL6XmxF9QR9qZROAnulei4E='
                  badgeColor='info'
                  workshop={product}
                />
              </div>
            ))}
      </div>
    </>
  )
}

export default ServiceCheckListTable
