import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/verify-token`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/auth/signup`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot-password`
export const RESET_PASSWORD_URL = `${API_URL}/auth/reset-password`
export const VERIFY_OTP_URL = `${API_URL}/auth/verify-password-otp`
export const VERIFY_EMAIL_URL = `${API_URL}/auth/verify-email`
export const RESEND_OTP_URL = `${API_URL}/auth/generate-verify-email-otp`
// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  name: string,
  password: string,
  role: string = 'VEHICLE_OWNER'
) {
  return axios.post(REGISTER_URL, {
    email,
    name,
    password,
    role,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function resetPassword(email: string, newPassword: string, otp) {
  return axios.post<{result: boolean}>(RESET_PASSWORD_URL, {
    email,
    newPassword,
    otp,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function verifyOTP(email: string, otp) {
  return axios.post<{result: boolean}>(VERIFY_OTP_URL, {
    email,
    otp,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function verifyEmail(email: string, otp) {
  return axios.post<{result: boolean}>(VERIFY_EMAIL_URL, {
    email,
    otp,
  })
}
export function resendEmailOtp(email: string) {
  return axios.post<{result: boolean}>(RESEND_OTP_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios
    .post<UserModel>(
      GET_USER_BY_ACCESSTOKEN_URL,
      {
        api_token: token,
      },
      {
        headers: {
          Bearer: token,
        },
      }
    )
    .then((res) => {
      return res
    })
}
