/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useCallback, useEffect, useState} from 'react'
import {ProductRow} from './productRow'
import {ProductForm} from './addProductForm'
import {useDataContext} from '../../../modules/data'
import Pagination from '../../../components/Pagination'
import {Search} from '../../../components/core-ui/search'
import useProduct from '../core/hooks/use-products'
import {useAuth} from 'app/modules/auth'
import {Dropdown} from 'react-bootstrap'
import {USER_ROLE} from 'app/constants/constants'
import {Select} from 'antd'
import useWorkshop from 'app/pages/workshop/hooks/use-workshop'
type Props = {
  products: any
  updateProductQueryParams: any
  productQueryParams: any
}

const ProductTable: React.FC<Props> = ({
  products,
  productQueryParams,
  updateProductQueryParams,
}) => {
  const {workshopsData, isLoadingWorkshops} = useWorkshop()

  const {setModal} = useDataContext()
  const {currentUser} = useAuth()
  const [searchQuery, setSearchQuery] = useState('All')

  const [filteredProducts, setFilteredProducts] = useState([])
  const [productData, setProductData] = useState<any>()

  useEffect(() => {
    if (products) setProductData(products)
  }, [products])

  const updateUsers = (workshop) => {
    setSearchQuery(workshop !== 'All' ? workshop.name : 'All')
    setFilteredProducts(() => searchArray(workshop._id))
  }

  const searchArray = useCallback(
    (searchTerm) => {
      return searchTerm !== undefined
        ? productData?.filter(
            (product) => product.workshop == searchTerm

            // Check if any property of the object contains the search term
            // return Object.values(obj).some((value) => {
            //   if (typeof value === 'string' || typeof value === 'number') {
            //     return value.toString().includes(searchTerm)
            //   }
            //   return false
            // })
          )
        : productData
    },
    [productData]
  )
  // console.log(productData)

  const handleSearch = useCallback((searchValue: string) => {
    //console.log(searchValue)
    updateProductQueryParams({...productQueryParams, filter: {search: searchValue}})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const filterOption = (input: string, option?: {children: string; value: string}) => {
    return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
  }

  const onSelectWorkShop = (value: string) => {
    if (productData && productData.length > 0) {
      setProductData(productData.filter((product) => product?.workshop === value))
    }
  }
  const onClearWorkShop = () => {
    setProductData(products)
  }
  return (
    <div className={`card info`}>
      {/* begin::Header */}
      <div className=' w-400px ms-8 mt-8'>
        <Select
          showSearch
          allowClear
          className='w-full ml-0 h-100'
          placeholder='Select WorkShop'
          optionFilterProp='children'
          onSelect={onSelectWorkShop}
          onClear={onClearWorkShop}
          filterOption={filterOption}
          loading={isLoadingWorkshops}
        >
          {workshopsData &&
            workshopsData?.map((workShop) => (
              <Select.Option key={workShop?._id}>{`${workShop?.name}`}</Select.Option>
            ))}
        </Select>
      </div>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Products</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>All Products</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a product'
        >
          <button
            type='button'
            className='btn btn-primary'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_product_add'
            onClick={() => {
              setModal(true)
            }}
          >
            Add Products
          </button>
        </div>
      </div>

      {/* end::Header */}
      {/* begin::Body */}

      {/* {<Search onUpdateState={handleSearch}></Search>} */}
      <div className='card-body py-3'>
        {currentUser?.data.user.role === USER_ROLE.WORKSHOP_OWNER && (
          <>
            <h4 className='d-inline text-muted'>Select Workshop :</h4>
            <Dropdown className=' ms-10 w-30 d-inline'>
              <Dropdown.Toggle variant='primary' id='dropdown-basic' className='w-40'>
                {searchQuery}
              </Dropdown.Toggle>
              <Dropdown.Menu className='w-30'>
                {currentUser?.data.associatedEntities.workshops?.map((workshop, index) => (
                  <Dropdown.Item key={index} onClick={() => updateUsers(workshop)}>
                    {workshop.name}
                  </Dropdown.Item>
                ))}
                <Dropdown.Item key={0} onClick={() => updateUsers('All')}>
                  All
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        )}
        <div className='table-responsive mt-5'>
          {/* begin::Table */}
          {(searchQuery !== 'All' ? filteredProducts?.length > 0 : true) &&
          productData?.length > 0 ? (
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-20px'>S/No</th>
                  <th className='min-w-120px'>Name</th>
                  <th className='min-w-120px'>Type</th>
                  <th className='min-w-120px'>Workshop</th>
                  <th className='min-w-120px'>Cost Price </th>
                  <th className='min-w-120px'>Retail Price</th>
                  <th className='min-w-120px'>Units</th>
                  <th className='min-w-100px text-end'>Actions</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {searchQuery !== 'All'
                  ? filteredProducts?.map((product, index) => (
                      <ProductRow
                        key={index}
                        product={product}
                        sId={index + 1}
                        workshopsData={workshopsData}
                      />
                    ))
                  : productData?.map((product, index) => (
                      <ProductRow
                        key={index}
                        product={product}
                        sId={index + 1}
                        workshopsData={workshopsData}
                      />
                    ))}
              </tbody>
              {/* end::Table body */}
            </table>
          ) : (
            'No Products Found'
          )}
          {/* end::Table */}
          {/* <Pagination limit={10} count={20} /> */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {ProductTable}
