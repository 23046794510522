import {useMutation} from 'react-query'

import {message} from 'antd'

import {IPops} from '../_model'
import {createRepository} from '../_request'

function useCreateSignedUrl() {
  const {mutate, data, isSuccess, isError, isLoading} = useMutation(
    ({name, type, url}: IPops): Promise<any | undefined> => {
      if (url) return createRepository({type, name}, url)
      return Promise.resolve(undefined)
    },
    {
      onError: () => {
        message.error('Failed to upload Image')
      },
    }
  )
  return {
    createRepo: mutate,
    repoData: data,
    isSuccessRepo: isSuccess,
    isErrorRepo: isError,
    isLoadingRepo: isLoading,
  }
}

export default useCreateSignedUrl
