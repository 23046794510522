import React, {useState, useEffect} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {IWorkshop} from '../core/_models'
import {useDataContext} from '../../../modules/data'
import useCreateWorkshop from '../hooks/use-create-workshop'
import useUpdateWorkshop from '../hooks/use-update-workshop'
import Swal from 'sweetalert2'

import UploadIcon from '../../../assets/new-proj-upload-icon.svg'

import 'bootstrap/js/dist/modal'
import useGetServiceCheckList from '../hooks/use-service-checklists'
import useHandleImageUpload from 'app/helpers/image-uploader/hooks/use-handle-upload-image'
import {MediaType} from 'app/helpers/image-uploader/_model'
const phoneNumberRegex = /^\+?\d+$/
const phoneNumberValidation = (phoneNumber) => {
  if (!phoneNumber) return true // Accept empty value if not required
  return phoneNumberRegex.test(phoneNumber) // Validate against regex
}

const phoneNumberErrorMessage = 'Please enter a valid phone number'
const productDetailsSchema = Yup.object().shape({
  name: Yup.string().required('Workshop name is required'),
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9]([a-zA-Z0-9_.-]*[a-zA-Z0-9])?@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
      'Wrong Primary email format'
    )
    .email('Wrong Primary email format')
    .required('Primary email is required'),

  phoneNumber: Yup.string()
    .test('phoneNumber', phoneNumberErrorMessage, phoneNumberValidation)
    .required('Phone number is required'),
  website: Yup.string().url().required('Website url is required'),
  address: Yup.string().required('address  is required'),
  // checks: Yup.array().of(Yup.string().required('Check is required')).required('Checks Required'),
})

type Props = {
  setShowModal: any
}

const WorkshopForm: React.FC<Props> = () => {
  const {handleChange, repoData} = useHandleImageUpload()

  const {servicesCheckListData} = useGetServiceCheckList()
  const {createWorkshop, isErrorCreateWorkshop, isSuccessCreateWorkshop, isLoadingCreateWorkshop} =
    useCreateWorkshop()
  const {updateWorkshop, isErrorUpdateWorkshop, isSuccessUpdateWorkshop, isLoadingUpdateWorkshop} =
    useUpdateWorkshop()
  const {workshop, setWorkshop, checks, setChecks} = useDataContext()
  const [selectedServiceTemplate, setSelectedServiceTemplate] = useState('')
  const [imageLoading, setImageLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState<string>()
  let initialValues = workshop || {
    name: '',
    phoneNumber: '',
    website: '',
    email: '',
    address: '',
    checks: [''],
  }
  const handleUploadImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setImageLoading(true)

    const mediaType = `${MediaType.WORKSHOP}`
    handleChange(event, setLoading, setImageUrl, mediaType)
  }

  // console.log('workshopchecks are ', checks)
  // const [StateChecks, setStateChecks] = useState(checks.length > 0 ? checks : [''])
  const [StateChecks, setStateChecks] = useState<any>([])
  useEffect(() => {
    if (repoData) {
      setImageUrl(repoData?.signedUrl?.split('?')[0])
    }
  }, [repoData])
  useEffect(() => {
    if (workshop) {
      setImageUrl(workshop?.image)
    }
  }, [workshop])
  useEffect(() => {
    // console.log(
    //   'success is ',
    //   isSuccessUpdateWorkshop,
    //   isSuccessCreateWorkshop,
    //   isErrorUpdateWorkshop,
    //   isErrorCreateWorkshop
    // )
    if (isSuccessUpdateWorkshop || isSuccessCreateWorkshop) {
      setStateChecks([])
      setSelectedServiceTemplate('')
      Swal.fire({
        title: 'Success',
        text: workshop ? 'Updated Successfully!' : 'Created Successfully!',
        icon: 'success',
        confirmButtonText: 'Ok',
      }).then((result) => {
        setWorkshop({name: ' ', email: '', website: '', phoneNumber: '', address: ''})
        setChecks([''])
      })
    } else if (isErrorUpdateWorkshop || isErrorCreateWorkshop) {
      setStateChecks([])
      setSelectedServiceTemplate('')
      Swal.fire({
        title: 'Error',
        text: workshop ? 'Error Updating!' : 'Error Creating!',
        icon: 'error',
        confirmButtonText: 'Ok',
      }).then((result) => {
        setWorkshop({name: ' ', email: '', website: '', phoneNumber: '', address: ''})
        setChecks([''])
        //console.log('in fire then =>', result)
      })
    }
  }, [
    isSuccessCreateWorkshop,
    isSuccessUpdateWorkshop,
    isErrorUpdateWorkshop,
    isErrorCreateWorkshop,
  ])
  useEffect(() => {
    if (workshop) {
      // If workshop data exists, reset StateChecks
      setStateChecks([])
      setSelectedServiceTemplate('')
    }
  }, [workshop])
  // useEffect(() => {
  //   setStateChecks(checks)
  //   //console.log('checks inside effect are ', StateChecks)
  // }, [checks])
  const formik = useFormik<IWorkshop>({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: productDetailsSchema,
    onSubmit: async (values) => {
      if (!values.checks) {
        delete values.checks
      }
      // console.log(values)
      // return
      workshop
        ? await updateWorkshop({...values, image: imageUrl})
        : await createWorkshop({...values, image: imageUrl})
      setStateChecks([]) // Reset StateChecks after form submission
      formik.resetForm()
      setSelectedServiceTemplate('')

      // isLoadingUpdateWorkshop && console.log('msg isLoadingUpdateWorkshop')
      // isSuccessUpdateWorkshop && console.log('msg isSuccessUpdateWorkshop')
      // isSuccessCreateWorkshop && console.log('msg isSuccessCreateWorkshop')
      // isLoadingCreateWorkshop && console.log('msg isLoadingCreateWorkshop')
      // if (isSuccessCreateWorkshop || isSuccessUpdateWorkshop) {
      //   Swal.fire({
      //     title: 'Success',
      //     text: workshop ? 'Updated Successfully!' : 'Created Successfully!',
      //     icon: 'success',
      //     confirmButtonText: 'Ok',
      //   }).then((result) => {
      //     setWorkshop({name: ' ', email: '', website: '', phoneNumber: '', address: ''})
      //     setChecks([''])
      //     console.log('in fire then =>', result)
      //   })
      // } else {
      //   Swal.fire({
      //     title: 'Error',
      //     text: workshop ? 'Updated Successfully!' : 'Created Successfully!',
      //     icon: 'error',
      //     confirmButtonText: 'Ok',
      //   }).then((result) => {
      //     //  setWorkshop({name: ' ', email: '', website: '', phoneNumber: '', address: ''})
      //     //  setChecks([''])
      //     console.log('in fire then =>', result)
      //   })
      // }
    },
  })
  // const handleInputChange = (index: number, value: string) => {
  //   setStateChecks((prevChecks) => {
  //     const newChecks = [...prevChecks]
  //     newChecks[index] = value
  //     return newChecks
  //   })
  // }

  // const handleAddCheck = () => {
  //   setStateChecks((prevChecks) => [...prevChecks, ''])
  // }

  // const handleRemoveCheck = (index: number) => {
  //   setStateChecks((prevChecks) => {
  //     const newChecks = [...prevChecks]
  //     newChecks.splice(index, 1)
  //     return newChecks
  //   })
  // }
  const handleCheckChange = (check, isChecked) => {
    setStateChecks((prevChecks) => {
      if (isChecked) {
        // If checkbox is checked, add the check to the array
        return [...prevChecks, check]
      } else {
        // If checkbox is unchecked, remove the check from the array
        return prevChecks?.filter((item) => item !== check)
      }
    })
  }
  const handleServiceTemplateChange = (event) => {
    const selectedTemplateId = event.target.value
    setSelectedServiceTemplate(selectedTemplateId)
  }
  const uploadButton = (
    <div className='h-36 w-36 bg-gray-100 border-[3px] border-[#BA9A28] hover:bg-[#e1e1e1] transition rounded-full flex flex-centered'>
      <img src={UploadIcon} alt='Upload Icon' />
    </div>
  )

  return (
    <div className='modal fade' id='kt_modal_workshop_add' aria-hidden='true'>
      <div className='modal-dialog mw-700px '>
        <div className='modal-content '>
          <div className='modal-header pb-0 border-0 justify-content-space-between'>
            <h3 className='text-center'>{workshop ? 'Update Workshop' : 'Add Workshop'}</h3>
            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              data-bs-dismiss='modal'
              style={{borderRadius: '50%', border: '1px solid'}}
              onClick={(e) => {
                e.preventDefault()
                setWorkshop({name: ' ', email: '', website: '', phoneNumber: '', address: ''})
                setChecks([''])
                setStateChecks([''])
                setSelectedServiceTemplate('')
                setImageUrl('')
                //  console.log(workshop)
              }}
            >
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='modal-body scroll-y  mx-10 pt-0 pb-15 '>
            <form onSubmit={formik.handleSubmit} noValidate className='form'>
              <div className='card-body border-top py-9 '>
                <div>
                  <div className=''>
                    <input
                      type='file'
                      required
                      accept='image/*'
                      onChange={handleUploadImageChange}
                      style={{display: 'none'}}
                      id='imageUploadInput'
                    />
                    <label
                      htmlFor='imageUploadInput'
                      className='relative flex rounded-full cursor-pointer flex-centered md:h-40 md:w-40 h-36 w-36'
                    >
                      {imageUrl ? (
                        <img
                          className='object-cover mx-auto  hover:cursor-pointer md:h-40 md:w-40 h-36 w-36'
                          src={imageUrl}
                          alt='avatar'
                          style={{width: '100px', height: '100px'}}
                        />
                      ) : (
                        uploadButton
                      )}
                      {imageUrl && (
                        <div className='absolute [&>svg>path]:fill-black opacity-0 hover:opacity-100 transition hover:bg-white hover:bg-opacity-60 rounded-full centered-xy md:h-40 md:w-40 h-36 w-36 grid place-content-center cursor-pointer'>
                          <img src={UploadIcon} alt='Upload Icon' />
                        </div>
                      )}
                    </label>
                  </div>
                  <p className='mt-2 font-medium text-gray-400'>Upload Picture</p>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                    Workshop Name
                  </label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='Workshop Name'
                          {...formik.getFieldProps('name')}
                          // onChange={(e) => {
                          //   formik.handleChange('name')(e.target.value.trim())
                          // }}
                        />
                        {formik.touched.name && formik.errors.name && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.name}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                    Primary Email
                  </label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='email'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='Primary Email'
                          {...formik.getFieldProps('email')}
                          // onChange={(e) => {
                          //   formik.handleChange('email')(e.target.value.trim())
                          // }}
                        />
                        {formik.touched.email && formik.errors.email && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.email}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                    Phone Number
                  </label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='Phone Number'
                          {...formik.getFieldProps('phoneNumber')}
                          // onChange={(e) => {
                          //   formik.handleChange('phoneNumber')(e.target.value.trim())
                          // }}
                        />
                        {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.phoneNumber}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>Website</label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='url'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='https://www.google.com/'
                          {...formik.getFieldProps('website')}
                          // onChange={(e) => {
                          //   formik.handleChange('website')(e.target.value.trim())
                          // }}
                        />
                        {formik.touched.website && formik.errors.website && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.website}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>Address</label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <textarea
                          className='form-control form-control-solid mb-8'
                          rows={3}
                          placeholder='Type Address Here'
                          {...formik.getFieldProps('address')}
                          // onChange={(e) => {
                          //   formik.handleChange('address')(e.target.value.trim())
                          // }}
                        ></textarea>
                        {formik.touched.address && formik.errors.address && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.address}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label  fw-bold fs-6'>Service Templates</label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <select
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          onChange={handleServiceTemplateChange}
                          // {...formik.getFieldProps('service')}
                        >
                          <option
                            value=''
                            disabled={!selectedServiceTemplate}
                            selected={!selectedServiceTemplate}
                          >
                            Select Service
                          </option>
                          {servicesCheckListData?.map((workshop) => (
                            <option key={workshop._id} value={workshop._id}>
                              {workshop.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className='row mb-6'
                  onBlur={() => formik.setFieldValue('checks', StateChecks)}
                >
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                    Service Checks
                  </label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <div className='form-control form-control-lg d-flex flex-column'>
                          {/* Add workshop services here */}
                          {servicesCheckListData
                            ?.filter((service) => service._id === selectedServiceTemplate)
                            ?.map((service) => (
                              <React.Fragment key={service._id}>
                                <h6>{service.name} </h6>
                                {service?.checks?.map((check, index) => (
                                  <div className='form-check' key={index}>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      id={`check-${index}`}
                                      value={check}
                                      // checked={StateChecks.includes(check)}
                                      onChange={(e) => handleCheckChange(check, e.target.checked)}
                                    />
                                    <label className='form-check-label' htmlFor={`check-${index}`}>
                                      {check}
                                    </label>
                                  </div>
                                ))}
                              </React.Fragment>
                            ))}
                          {/* <button
                            type='button'
                            className='btn btn-sm fw-bold btn-primary align-self-end'
                            onClick={handleAddCheck}
                          >
                            +
                          </button> */}
                          {/* {StateChecks?.map((check, index) => (
                            <div className='d-flex align-items-center mt-5' key={index}>
                              <input
                                placeholder='Enter check'
                                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                type='text'
                                value={check}
                                onChange={(e) => handleInputChange(index, e.target.value)}
                              />
                              <span onClick={() => handleRemoveCheck(index)}>
                                <KTIcon iconName='cross' className='fs-1 cursor-pointer' />
                              </span>
                            </div>
                          ))} */}
                        </div>
                        {formik.touched.checks && formik.errors.checks && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.checks}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='card-footer d-flex justify-content-end '>
                <button
                  id='submitButton'
                  type='submit'
                  className='btn btn-primary'
                  disabled={
                    isLoadingCreateWorkshop ||
                    isLoadingUpdateWorkshop ||
                    !formik.isValid ||
                    !formik.dirty
                  }
                  // {...(isSuccessUpdateWorkshop || isSuccessCreateWorkshop
                  //   ? null
                  //   : {
                  //       'data-bs-dismiss': 'modal',
                  //     })}
                  {...(isSuccessCreateWorkshop ? null : {'data-bs-dismiss': 'modal'})}
                  {...(isSuccessUpdateWorkshop ? null : {'data-bs-dismiss': 'modal'})}
                >
                  {(!isLoadingCreateWorkshop || isLoadingUpdateWorkshop) &&
                    (workshop ? 'Update' : 'Add')}
                  {isLoadingCreateWorkshop ||
                    (isLoadingUpdateWorkshop && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    ))}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export {WorkshopForm}
