import {useMutation, useQueryClient} from 'react-query'
import {QUERIES_KEYS} from 'app/helpers/crud-helpers/consts'
import {updateUser} from '../core/_request'
import {IUserUpdate} from '../core/_models'
import {AxiosError} from 'axios'

function useUpdateUser() {
  const queryClient = useQueryClient()

  const {mutate, isLoading, isError, isSuccess, error} = useMutation(
    (body: IUserUpdate) => {
      const {_id, ...rest} = body
      return updateUser(rest, _id ?? '')
    },
    {
      onSuccess: () => {
        //console.log('User updated successfully')
        queryClient.invalidateQueries(QUERIES_KEYS.USERS_LIST)
      },
    }
  )

  return {
    updateUserById: mutate,
    isLoadingUpdateUser: isLoading,
    isErrorUpdateUser: isError,
    isSuccessUpdateUser: isSuccess,
    errorInPasswordUpdate: error instanceof AxiosError && error?.response?.data?.message,
  }
}

export default useUpdateUser
