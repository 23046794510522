import {useQuery} from 'react-query'

import {QUERIES_KEYS} from './../../../../helpers/crud-helpers/consts'
import {getProducts} from '../_request'
import useRequestQuery from '../../../../helpers/crud-helpers/hooks/use-request-query'

function useProduct() {
  const {queryParams, updateQueryParams, queryString} = useRequestQuery()
  const {data, isLoading, isFetching} = useQuery(
    QUERIES_KEYS.PRODUCT_LIST,
    () => getProducts(queryString),
    {
      select: (res) => res.data,
      refetchOnWindowFocus: false,
    }
  )

  return {
    productsData: data,
    isLoadingUsers: isLoading || isFetching,
    queryString: queryString,
    productQueryParams: queryParams,
    productUpdateQueryParams: updateQueryParams,
  }
}

export default useProduct
