import {useMutation, useQueryClient} from 'react-query'
import {QUERIES_KEYS} from 'app/helpers/crud-helpers/consts'
import {IAddInspection} from '../../core/inspection/_inspectionModels'
import {createInspection} from '../../core/inspection/_inspectionRequests'

function useCreateInspection() {
  const queryClient = useQueryClient()

  const {mutate, data, isLoading, isError, error, isSuccess} = useMutation(
    (body: IAddInspection) => createInspection(body),
    {
      onSuccess: () => {
        //console.log('Inspection created succesfully')
        queryClient.invalidateQueries(QUERIES_KEYS.INSPECTION_LIST)
      },
    }
  )

  return {
    createInspection: mutate,
    newInspection: data,
    isLoadingCreateInspection: isLoading,
    isErrorCreateInspection: isError,
    errorCreateInspection: error,
    isSuccessCreateInspection: isSuccess,
  }
}

export default useCreateInspection
