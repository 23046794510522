import {KTIcon} from '_metronic/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import React, {useEffect, useState, useRef} from 'react'
import {useDataContext} from 'app/modules/data'
import {IUpdateInspection} from '../../core/inspection/_inspectionModels'
import Swal from 'sweetalert2'
import JoditEditor from 'jodit-react'

import useUpdateInspection from '../../hooks/inspection-hooks/use-update-inspection'
import {check} from 'prettier'
import useInspectionByServiceId from '../../hooks/inspection-hooks/use-get-insepection-by-serviceid'
import {useAuth} from 'app/modules/auth'
import {USER_ROLE} from 'app/constants/constants'
import usePauseResumeService from '../../hooks/use-pause-resume-service'
// import useCreateVehicle from '../hooks/use-create-vehicle'
// import useUpdateVehicle from '../hooks/use-update-vehicle'
type Props = {
  serviceId?: string | undefined
}

const productDetailsSchema = Yup.object().shape({
  notes: Yup.string().required('Reason is Required'),
})

const ServiceReasonForm: React.FC<Props> = () => {
  const {
    pauseResumeService,
    isLoadingpauseResumeService,
    isSuccesspauseResumeService,
    isErrorpauseResumeService,
  } = usePauseResumeService()
  // console.log('inspection here is ', inspection?.notes, inspection?.notes.length)
  const [editorData, setEditorData] = useState('')
  const {setServiceId, serviceId} = useDataContext()
  // console.log('service id is ', serviceId)
  const editorRef = useRef(null)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      reason: '',
    },
    // validationSchema: productDetailsSchema,
    onSubmit: async (values) => {
      pauseResumeService({
        id: serviceId,
        body: values,
      })
    },
  })
  const handleSetToInitial = () => {
    setEditorData('')
    formik.resetForm()
  }
  useEffect(() => {
    if (isErrorpauseResumeService) {
      handleSetToInitial()
      Swal.fire({
        title: 'Error',
        text: 'Error . Try again',
        icon: 'error',
        confirmButtonText: 'Ok',
      }).then((result) => {
        setServiceId(undefined)
      })
    } else if (isSuccesspauseResumeService) {
      handleSetToInitial()
      Swal.fire({
        title: 'Success',
        text: 'updated Successfully!',
        icon: 'success',
        confirmButtonText: 'Done',
      }).then((result) => {
        setServiceId(undefined)
        //clode modal here and re-get all inspections
      })
    }
  }, [isSuccesspauseResumeService, isErrorpauseResumeService])

  useEffect(() => {
    //console.log('Setting editor data in formik', editorData)
    formik.setFieldValue('reason', editorData)
  }, [editorData])

  return (
    <div className='modal fade' id='kt_modal_pause_service' aria-hidden='true'>
      <div className='modal-dialog mw-750px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-space-between'>
            <h3 className='text-center'>Service Pause</h3>
            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              data-bs-dismiss='modal'
              style={{borderRadius: '50%', border: '1px solid'}}
              onClick={(e) => {
                e.preventDefault()
                handleSetToInitial()
              }}
            >
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <form onSubmit={formik.handleSubmit} noValidate className='form'>
              <div className='card-body border-top p-5'>
                <div className='row mb-6'>
                  <label className='col-lg-2 col-form-label required fw-bold fs-6'>Reason</label>

                  <div className='col-lg-10'>
                    <div className='row' style={{maxHeight: '300px', overflowY: 'auto'}}>
                      <div className='col-lg-12 fv-row'>
                        <JoditEditor
                          ref={editorRef}
                          value={editorData}
                          onBlur={(newContent) => setEditorData(newContent)}
                        />
                      </div>
                    </div>
                    {formik.touched.reason && formik.errors.reason && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.reason}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='card-footer d-flex justify-content-end py-6 px-5 '>
                <button
                  type='submit'
                  disabled={!formik.isValid || !formik.dirty}
                  className='btn btn-primary'
                  data-bs-dismiss='modal'
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export {ServiceReasonForm}
