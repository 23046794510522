import {FC, useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {CardsWidget7, EngageWidget10, ListsWidget4} from '../../../../_metronic/partials/widgets'
import {VehicleInfo} from './service-detail-components/VehicleInfo'
import {ServiceInfo} from './service-detail-components/ServiceInfo'
import {WorkshopInfo} from './service-detail-components/WorkshopInfo'
import {ChecksInfo} from './service-detail-components/CheckInfo'
import {AssociatedUser} from './service-detail-components/AssociatedUser'
import {ServiceLogs} from './service-detail-components/LogsComponent'
import {ServiceInspection} from './inspection-components/ServiceInspectionTable'
import {useLocation} from 'react-router-dom'
import {IService} from '../core/_models'
import {ServiceTimelogs} from './service-detail-components/Timelogs'
import {ProductUsed} from './service-detail-components/ProductUsed'
import {useDataContext} from 'app/modules/data'
import {ServiceImageCarousel} from './service-detail-components/serviceImageCarousel'
import {useAuth} from 'app/modules/auth'
import useServiceById from '../hooks/user-get-service-by-id'
import {CustomPageLoader} from 'app/modules/auth/components/CustomPageLoader'

export const ServiceItem: FC<any> = () => {
  const state = useLocation()
  const {currentUser} = useAuth()
  const serviceId = state.pathname.split('/')[2]
  const {isLoadingService, serviceData} = useServiceById(serviceId)
  const [stateService, setstateService] = useState<IService>()
  const {service, setService} = useDataContext()
  const role = currentUser?.data.user.role
  useEffect(() => {
    if (serviceData) {
      setstateService(serviceData)
    }
  }, [serviceId])
  if (isLoadingService) {
    return <CustomPageLoader />
  }
  return (
    <div>
      {/* begin::Row */}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10 w-xl-100'>
        {/* begin::Col  mb-md-5 mb-xl-10 */}
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 w-xl-50'>
          <VehicleInfo
            className='h-md-50 mb-5 mb-xl-10'
            description='Active Projects'
            color='#F1416C'
            status={serviceData?.status}
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
            vehicle={serviceData?.vehicle}
            workshop={serviceData?.workshop}
            service={serviceData}
            role={role}
          />
          <WorkshopInfo
            className='h-lg-40'
            vehicleOwnerNotes={serviceData?.vehicleOwnerNotes}
            internalNotes={serviceData?.internalNotes}
            externalNotes={serviceData?.externalNotes}
            invoiceNotes={serviceData?.invoiceNotes}
            invoiceUrl={serviceData?.invoiceUrl}
            serviceDetailsGenerated={serviceData?.serviceDetailsGenerated}
            serviceId={serviceData?._id}
            serviceStatus={serviceData?.status}
          />
        </div>
        {/* end::Col */}

        {/* begin::Col mb-md-5 mb-xl-10*/}
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 w-xl-50'>
          <ServiceInfo
            className='h-md-50 mb-5 mb-xl-10'
            payment={serviceData?.paymentDetails}
            endDate={serviceData?.endDate}
            scheduledFor={serviceData?.scheduledFor}
            startDate={serviceData?.startDate}
            creationDate={serviceData?.createdAt || '2024-02-01T12:30:45'}
            status={serviceData?.status || 0}
            // timeLapse={service?.currentTimeLapse || '2024-02-01T12:30:45'}
          />
          <ProductUsed className='h-md-50 mb-5 mb-xl-10 ' products={serviceData?.products} />
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        {/* <div className='col-xxl-6'>
          <EngageWidget10 className='h-md-100' />
        </div> */}
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gx-5 gx-xl-10'>
        {/* begin::Col */}
        <div className='col-xxl-6 mb-5 mb-xl-10'>
          {/* <app-new-charts-widget8 cssclassName="h-xl-100" chartHeight="275px" [chartHeightNumber]="275"></app-new-charts-widget8> */}
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xxl-6 mb-5 mb-xl-10'>
          {/* <app-cards-widget18 cssclassName="h-xl-100" image="./assets/media/stock/600x600/img-65.jpg"></app-cards-widget18> */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gy-5 gx-xl-8 mt-10'>
        <div className='col-xxl-4'>
          <ChecksInfo className='card-xxl-stretch mb-xl-3' checks={serviceData?.checks || []} />
        </div>
        <div className='col-xxl-8'>
          <ServiceInspection
            className='card-stretch card-xxl-stretch mb-5 mb-xl-8'
            serviceId={serviceData?._id}
            serviceStatus={serviceData?.status}
            mechanicId={serviceData?.fixedBy?._id}
            service={serviceData}
          />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gy-5 g-xl-8'>
        {/* <div className='col-xl-4'>
          <AssociatedUser className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-4'>
          <ServiceLogs className='card-xl-stretch mb-xl-8' logs={service?.serviceLogs || []} />
          <ServiceLogs className='card-xl-stretch mb-xl-8' logs={serviceLogs} />
        </div> */}
        <div className='col-xl-6'>
          <ServiceLogs className='card-xl-stretch mb-xl-8' logs={serviceData?.serviceLogs || []} />
          {/* <ServiceLogs className='card-xl-stretch mb-xl-8' logs={serviceLogs} /> */}
        </div>
        <div className='col-xl-6'>
          {/* <ServiceTimelogs className='card-xl-stretch mb-5 mb-xl-8' timeLogs={service?.timeLogs} /> */}
          <ServiceTimelogs
            className='card-xl-stretch mb-5 mb-xl-8'
            timeLogs={serviceData?.timeLogs}
          />
        </div>
      </div>
      <div className='row gy-5 g-xl-8 mb-10'>
        <ServiceImageCarousel photos={serviceData?.photos} />
      </div>
    </div>
  )
}
