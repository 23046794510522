import {useMutation, useQueryClient} from 'react-query'
import {QUERIES_KEYS} from '../../../helpers/crud-helpers/consts'

import {IVehicle} from '../core/_models'
import {createVehicle} from '../core/_requests'

function useCreateVehicle() {
  const queryClient = useQueryClient()

  const {mutate, isLoading, isError, isSuccess} = useMutation(
    (body: IVehicle) => createVehicle(body),
    {
      onSuccess: () => {
        // console.log('Test created successfully')
        queryClient.invalidateQueries(QUERIES_KEYS.VEHICLE_LIST)
      },
    }
  )

  return {
    createVehicle: mutate,
    isLoadingCreateVehicle: isLoading,
    isErrorCreateVehicle: isError,
    isSuccessCreateVehicle: isSuccess,
  }
}

export default useCreateVehicle
