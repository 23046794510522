import axios from 'axios'
import {IAddService, IService, IServiceQueryResponse} from './_models'

const SERVICE = '/vehicle-service'
const PAUSE_RESUME = 'pause-resume'
export function getServices(): Promise<IServiceQueryResponse | any> {
  return axios.get(SERVICE).then((response) => response.data)
}
export async function createService(body: IAddService) {
  let result = await axios.post(SERVICE, body).then((response) => response.data)
  //console.log('Result by backend of post service ', result)
  return result
}
export async function updateService(body, id) {
  // console.log('DATA TO update ', body)
  let result = await axios.patch(`${SERVICE}/${id}`, body).then((response) => response.data)
  // console.log('UPDATE respononse ', result)
  return result
}

export async function createInvoice(body: any) {
  // console.log('service id sending is ', body)
  let result = await axios
    .post(`${SERVICE}/generate-invoice`, body)
    .then((response) => response.data)
  // console.log('Result by backend of post invoicegeneration ', result)
  return result
}

export async function getServicesByVehicle(id: string): Promise<IServiceQueryResponse> {
  // console.log('hooklp Got service id in getServicesByVehicle', id)
  let result = await axios.get(`${SERVICE}?vehicle=${id}`).then((response) => response.data)
  //console.log('result by getServicesByVehicle', result)
  return result
}
export async function generateHistory(body: any) {
  // console.log('service id sending is ', body)
  let result = await axios
    .post(`${SERVICE}/generate-service-pdf`, body)
    .then((response) => response.data)
  // console.log('Result by backend of post invoicegeneration ', result)
  return result
}

export async function servicePauseResume(body, id) {
  let result = await axios
    .patch(`${SERVICE}/${PAUSE_RESUME}/${id}`, body)
    .then((response) => response.data)
  return result
}
export async function getServicesById(id?: string): Promise<any> {
  if (id) {
    let result = await axios.get(`${SERVICE}/${id}`).then((response) => response.data)

    return result
  }
}
