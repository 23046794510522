import {useQuery} from 'react-query'

import {getCalendarData} from '../_request'

const CALENDAR_DATA = 'get-calendar-data'

function useCalendar() {
  const {data, isLoading, isFetching, isSuccess} = useQuery(CALENDAR_DATA, getCalendarData, {
    refetchOnWindowFocus: false,
  })
  return {
    calendarData: data,
    isLoadingCalendarData: isLoading || isFetching,
    isSuccessCalendarData: isSuccess,
  }
}

export default useCalendar
