import React from 'react'
import {KTIcon} from '_metronic/helpers'
import {Dropdown1} from '_metronic/partials'
import {NotesConverter} from '../NotesConverter'
type Props = {
  className: string
  timeLogs:
    | {
        startTime: string
        endTime: string
        reason: string
        notes: string
      }[]
    | undefined
}

const ServiceTimelogs: React.FC<Props> = ({className, timeLogs}) => {
  // console.log('Timelogs: ', timeLogs)

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header align-items-center border-0 mt-4'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold mb-2 text-gray-900'>Time logs</span>
          <span className='text-muted fw-semibold fs-7'>{timeLogs?.length} Activities</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-5'>
        {/* begin::Timeline */}
        <div className='timeline-label'>
          {/* begin::Item */}
          {timeLogs?.map((timelog, idx) => {
            const badgeColors = ['success', 'warning', 'primary', 'danger']

            // Calculate the color based on the index
            const badgeColor = badgeColors[idx % badgeColors.length]
            return (
              <div className='timeline-item'>
                {/* begin::Label */}
                <div className='timeline-label fw-bold text-gray-800 fs-7 me-5'>
                  {new Date(timelog.startTime).toLocaleDateString(undefined, {
                    day: 'numeric',
                    month: 'numeric',
                    year: '2-digit',
                  })}
                </div>
                {/* end::Label */}
                {/* begin::Badge */}
                <div className='timeline-badge'>
                  <i className={`fa fa-genderless text-${badgeColor} fs-1`}></i>
                </div>
                {/* end::Badge */}
                {/* begin::Text */}
                <div className='fw-mormal timeline-content text-muted ps-3'>
                  <NotesConverter data={timelog?.reason} />
                </div>
                {/* end::Text */}
              </div>
            )
          })}
          {/* end::Item */}
        </div>
        {/* end::Timeline */}
      </div>
      {/* end: Card Body */}
    </div>
  )
}

export {ServiceTimelogs}
