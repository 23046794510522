import {DashboardCard} from 'app/components/core-ui/DashboardCard'
import {toAbsoluteUrl} from '_metronic/helpers'
import {USER_ROLE} from 'app/constants/constants'
import CustomizedCalendar from './components/CustomizedCalendar'

export const VehicleOwnerDashboard = ({userDashboardData, user}: any) => {
  const role = user.data.user.role

  let totalCount = 0
  Object.keys(userDashboardData?.servicesCount).forEach((key) => {
    totalCount += userDashboardData?.servicesCount[key]
  })

  return (
    <div className='d-flex align-content-start flex-wrap flex-md-row flex-column'>
      <>
        {/* <div className={`card card-flush h-md-50 mb-5 mb-xl-10`}>
          <div className='card-header pt-md-2'>
            <div className='card-title d-flex flex-column'>
            
              <span className='text-gray-400 pt-1 fw-semibold fs-6'>User Details</span>
            </div>
          
          </div>

          <div className='card-body pt-sm-2 pt-sm-4 pt-md-2 pb-md-4 pt-1 pb-2 pt-lg-2 pb-lg-4 d-flex flex-wrap align-items-center'>
            <div className='d-flex flex-column content-justify-center flex-row-fluid py-2 py-md-4'>
              <div className='d-flex fw-semibold align-items-center'>
                <div className='bullet w-8px h-5px rounded-2 bg-success me-3'></div>
                <div className='text-gray-500 flex-grow-1 me-4'>Email </div>
                <div className='fw-bolder text-gray-700 text-xxl-end'>{user.data.user.email}</div>
              </div>
              <div className='d-flex fw-semibold align-items-center my-6'>
                <div className='bullet w-8px h-5px rounded-2 bg-primary me-3'></div>
                <div className='text-gray-500 flex-grow-1 me-4'>Name</div>
                <div className='fw-bolder text-gray-700 text-xxl-end'>{user.data.user.name}</div>
              </div>
              <div className='d-flex fw-semibold align-items-center'>
                <div
                  className='bullet w-8px h-5px rounded-2 me-3'
                  style={{backgroundColor: '#E4E6EF'}}
                ></div>
                <div className='text-gray-500 flex-grow-1 me-4'>Role</div>
                <div className=' fw-bolder text-gray-700 text-xxl-end'>
                  {getRole(user.data.user.role)}
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {role === USER_ROLE.SUPER_USER && (
          <DashboardCard
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
            title='Total Vehicles'
            quantity={userDashboardData?.vehiclesCount}
          />
        )}
        {role === USER_ROLE.VEHICLE_OWNER && (
          <DashboardCard
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
            title='Total Vehicles'
            quantity={userDashboardData?.vehiclesCount}
          />
        )}
        {(role === USER_ROLE.VEHICLE_OWNER || role === USER_ROLE.WORKSHOP_OWNER) && (
          <DashboardCard
            color='#33e0ff'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
            title='Total Workshops'
            quantity={userDashboardData?.workshopsCount}
          />
        )}
        {(role === USER_ROLE.SERVICE_MANAGER || role === USER_ROLE.WORKSHOP_OWNER) && (
          <DashboardCard
            color='#972d7d'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
            title='Total staff'
            quantity={userDashboardData?.usersCount}
          />
        )}
        {role !== USER_ROLE.VEHICLE_OWNER && (
          <>
            <DashboardCard
              color='#3386ff'
              img={toAbsoluteUrl('/media/patterns/vector-1.png')}
              title='Total Products'
              quantity={userDashboardData?.productsCount}
            />
            <DashboardCard
              color='#7330c9'
              img={toAbsoluteUrl('/media/patterns/vector-1.png')}
              title='Total Services'
              quantity={totalCount}
            />
          </>
        )}
        {role !== USER_ROLE.VEHICLE_OWNER && <CustomizedCalendar />}
      </>
    </div>
  )
}
