import React, {useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Dropdown} from 'react-bootstrap'
import * as Yup from 'yup'
import {Field, useFormik} from 'formik'
import {IUser} from '../core/_models'
import {useDataContext} from '../../../modules/data'
import useCreateUser from '../core/hooks/use-add-users'
import Swal from 'sweetalert2'
import {useAuth} from 'app/modules/auth'
import {USER_ROLE} from 'app/constants/constants'

const productDetailsSchema = Yup.object().shape({
  name: Yup.string().required('User name is required'),
  phoneNumber: Yup.number().required('Phone number is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  role: Yup.string().required('Role selection is required'),
  workshop: Yup.string().required('Workshop selection is required'),
})

type Props = {
  user?: any
  setShowModal: any
}
const phoneNumberRegex = /^\+?\d+$/

const phoneNumberValidation = (phoneNumber) => {
  if (!phoneNumber) return true // Accept empty value if not required
  return phoneNumberRegex.test(phoneNumber) // Validate against regex
}

const phoneNumberErrorMessage = 'Please enter a valid phone number'
const UserForm: React.FC<Props> = ({setShowModal}) => {
  const {user, setUser} = useDataContext()
  const {workshops} = useDataContext()
  const [selectedWorkshop, setSelectedWorkshop] = useState('Select Workshop')
  //console.log('Workshops are ', workshops)
  const {
    createUser,
    errorCreatingUser,
    isSuccessCreateUser,
    isLoadingCreateUser,
    isErrorCreateUser,
  } = useCreateUser()
  const {currentUser} = useAuth()

  const isServiceManager = currentUser?.data.user.role === USER_ROLE.SERVICE_MANAGER

  const productDetailsSchema = Yup.object().shape({
    name: Yup.string()
      .matches(/^[a-zA-Z\s]*$/, 'Name must only contain letters and spaces')
      .min(3, 'Minimum 3 characters are required')
      .max(50, 'Maximum 50 characters are required')
      .required('Name is required'),
    email: Yup.string()
      .matches(
        /^[a-zA-Z0-9]([a-zA-Z0-9_.-]*[a-zA-Z0-9])?@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
        'Wrong email format'
      )
      .email('Wrong email format')
      .required('Email is required'),

    phoneNumber: Yup.string()
      .test('phoneNumber', phoneNumberErrorMessage, phoneNumberValidation)
      .required('Phone number is required'),
    role: Yup.string().required('Role selection is required'),
    workshop: Yup.string().required('Workshop selection is required'),
  })
  const [data, setData] = useState<IUser>({
    name: '',
    email: '',
    phoneNumber: '',
    role: '',
    workshop: isServiceManager ? currentUser?.data.user.workshop : '',
  })

  const [loading, setLoading] = useState(false)
  const formik = useFormik<IUser>({
    enableReinitialize: true,
    initialValues: {
      name: '',
      email: '',
      phoneNumber: '',
      role: 'SENIOR_MECHANIC',
      workshop: isServiceManager ? currentUser?.data.user.workshop : '',
    },
    validationSchema: productDetailsSchema,
    onSubmit: (values) => {
      // console.log(' USERS Adding data ', values)
      createUser(values)

      // Swal.fire({
      //   title: 'Success',
      //   text: 'Created Successfully!',
      //   icon: 'success',
      //   confirmButtonText: 'Cool',
      // }).then((result) => {
      //   handleInitialDataSetting()
      //   setShowModal(false)
      // })
    },
  })
  //console.log('current user in form ', isServiceManager, currentUser)
  const handleWorkshopSelect = (selectedWorkshop) => {
    formik.setFieldValue('workshop', selectedWorkshop._id)
    setSelectedWorkshop(selectedWorkshop.name)
  }
  const handleInitialDataSetting = () => {
    setSelectedWorkshop('Select Workshop')
    formik.resetForm()
  }
  useEffect(() => {
    if (isSuccessCreateUser) {
      Swal.fire({
        title: 'Success',
        text: user ? 'Updated Successfully!' : 'Created Successfully!',
        icon: 'success',
        confirmButtonText: 'Ok',
      }).then((result) => {
        handleInitialDataSetting()
      })
    } else if (isErrorCreateUser) {
      Swal.fire({
        title: 'Error',
        text: user ? 'Error Updating! ' : 'Error Creating! ' + errorCreatingUser,
        icon: 'error',
        confirmButtonText: 'Ok',
      }).then((result) => {
        handleInitialDataSetting()
      })
    }
  }, [isSuccessCreateUser, isErrorCreateUser])
  return (
    <div className='modal fade' id='kt_modal_user_add' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-space-between'>
            <h3 className='text-center'>{user ? 'Update User' : 'Add User'}</h3>
            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              data-bs-dismiss='modal'
              style={{borderRadius: '50%', border: '1px solid'}}
              onClick={(e) => {
                e.preventDefault()
                setUser(undefined)
                setShowModal(false)
                handleInitialDataSetting()
              }}
            >
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <form onSubmit={formik.handleSubmit} noValidate className='form'>
              <div className='card-body border-top p-9'>
                {!user && (
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Name</label>

                    <div className='col-lg-8'>
                      <div className='row'>
                        <div className='col-lg-12 fv-row'>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder='Name'
                            {...formik.getFieldProps('name')}
                          />
                          {formik.touched.name && formik.errors.name && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{formik.errors.name}</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {!user && (
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email</label>

                    <div className='col-lg-8'>
                      <div className='row'>
                        <div className='col-lg-12 fv-row'>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder='Email'
                            {...formik.getFieldProps('email')}
                          />
                          {formik.touched.email && formik.errors.email && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{formik.errors.email}</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {!user && (
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                      Phone Number
                    </label>

                    <div className='col-lg-8'>
                      <div className='row'>
                        <div className='col-lg-12 fv-row'>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder='Phone Number'
                            {...formik.getFieldProps('phoneNumber')}
                          />
                          {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{formik.errors.phoneNumber}</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {!user && (
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Role</label>

                    <div className='col-lg-8'>
                      <div className='row'>
                        <div className='col-lg-12 fv-row'>
                          <select
                            {...formik.getFieldProps('role')}
                            aria-label='Select Role'
                            data-control='select2'
                            className='form-select form-select-solid'
                          >
                            {!isServiceManager && (
                              <option value='SERVICE_MANAGER'>Service Manager</option>
                            )}
                            <option value='SENIOR_MECHANIC'>Senior Mechanic</option>
                            <option value='JUNIOR_MECHANIC'>Junior Mechanic</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {!isServiceManager && (
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                      workshop
                    </label>

                    <div className='col-lg-8'>
                      <div className='row'>
                        <div className='col-lg-12 fv-row'>
                          <Dropdown className='w-100'>
                            <Dropdown.Toggle
                              variant='primary'
                              id='dropdown-basic'
                              className='w-100'
                            >
                              {selectedWorkshop}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className='w-100'>
                              {workshops?.map((workshop, index) => (
                                <Dropdown.Item
                                  key={index}
                                  onClick={() => handleWorkshopSelect(workshop)}
                                >
                                  {workshop.name}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <p style={{marginLeft: '5%', marginRight: '5%'}}>
                  Once you click submit, your new user will be sent an email confirming their
                  account, along with their initial password
                </p>
              </div>

              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button
                  type='submit'
                  className='btn btn-primary'
                  disabled={!formik.isValid || !formik.dirty}
                  // {...(isSuccessCreateUser && {'data-bs-dismiss': 'modal'})}
                  data-bs-dismiss='modal'
                >
                  {!loading && 'Submit'}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export {UserForm}
