import {ServiceActionDropdown} from './ServiceActionDropdown'

type Props = {
  className: string
  description: string
  color: string
  img: string
  vehicle: any
  status: number | undefined
  workshop: any
  role: any
  service: any
}

const VehicleInfo = ({
  service,
  className,
  description,
  color,
  img,
  role,
  vehicle,
  status,
  workshop,
}: Props) => (
  <div
    className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end ${className}`}
    style={{
      backgroundColor: color,
      backgroundImage: `url('${img}')`,
    }}
  >
    <div className='card-header pt-5 d-flex flex-row justify-content-between'>
      <div className='card-title d-flex flex-column'>
        <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2'>
          {!service?.partialVehicleDetails
            ? vehicle?.model || 'Vehicle model'
            : service?.partialVehicleDetails.model}
        </span>

        <span className='d-flex w-100 justify-content-between align-items-end text-white opacity-75 pt-1 fw-semibold fs-6'>
          {!service?.partialVehicleDetails
            ? vehicle?.year + '-' + vehicle?.make || 'Make model'
            : service?.partialVehicleDetails.year + '-' + service?.partialVehicleDetails.make}
        </span>
      </div>
      <div className='card-title d-flex flex-column'>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <ServiceActionDropdown role={role} serviceProp={service} />
        </div>
      </div>
    </div>
    <div className='card-body d-flex align-items-end pt-0'>
      <div className='d-flex align-items-center flex-column mt-3 w-100'>
        <div className='d-flex justify-content-between fw-bold fs-6 text-white opacity-75 w-100 mt-auto mb-2'>
          <span>{workshop?.name}</span>
          <span>{workshop?.email}</span>
        </div>
      </div>
    </div>
  </div>
)
export {VehicleInfo}
