import {useQuery} from 'react-query'

import {QUERIES_KEYS} from '../../../helpers/crud-helpers/consts'
import {getServicesByVehicle} from '../core/_requests'

function useServiceByVehicleId(vehicleId) {
  const {data, isLoading, isFetching, isSuccess} = useQuery(
    QUERIES_KEYS.SERVICE_LIST,
    () => getServicesByVehicle(vehicleId),
    {
      select: (res) => res.data,
      refetchOnWindowFocus: false,
    }
  )

  return {
    servicesData: data,
    isLoadingServices: isLoading || isFetching,
    isSuccessgetServices: isSuccess,
  }
}

export default useServiceByVehicleId
