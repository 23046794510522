import {useQuery} from 'react-query'

import {QUERIES_KEYS} from './../../../helpers/crud-helpers/consts'
import {getWorkshops} from '../core/_requests'

function useWorkshop() {
  const {data, isSuccess, isLoading, isFetching} = useQuery(
    QUERIES_KEYS.WORKSHOP_LIST,
    getWorkshops,
    {
      select: (res) => res.data,
      refetchOnWindowFocus: false,
    }
  )
  //console.log('workshop got are ', data)
  return {
    workshopsData: data,
    isLoadingWorkshops: isLoading || isFetching,
    isSuccess: isSuccess,
  }
}

export default useWorkshop
