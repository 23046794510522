import {useMutation, useQueryClient} from 'react-query'
import {deleteServiceCheckList} from '../_requests'
import {QUERIES_KEYS} from 'app/helpers/crud-helpers/consts'

function useDeleteServiceCheckList() {
  const queryClient = useQueryClient()

  const {mutate, isLoading, isSuccess} = useMutation((id: string) => deleteServiceCheckList(id), {
    // ✅ Show success message
    onSuccess: () => {
      queryClient.invalidateQueries(QUERIES_KEYS.SERVICE_CHECK_LIST)
    },
  })

  return {
    deleteServiceCheckList: mutate,
    isLoadingServiceCheckList: isLoading,
    isSuccessServiceCheckList: isSuccess,
  }
}

export default useDeleteServiceCheckList
