import {
  FC,
  useState,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
// Adjust these imports based on your actual implementation
import {WithChildren} from '../../../../_metronic/helpers'
import {IWorkshop} from '../../../pages/workshop/core/_models'
import {IProduct} from '../../../pages/product/core/_models'
import {IUser} from '../../../pages/users/core/_models'
import {IVehicle} from '../../../pages/vehicles/core/_models'
import {IInspection} from 'app/pages/service/core/inspection/_inspectionModels'
import {IService} from 'app/pages/service/core/_models'

type DataContextProps = {
  workshops: IWorkshop[] | undefined
  setWorkshops: Dispatch<SetStateAction<IWorkshop[] | undefined>>
  setWorkshop: Dispatch<SetStateAction<IWorkshop | undefined>>
  workshop: IWorkshop | undefined
  products: IProduct[] | undefined
  setProducts: Dispatch<SetStateAction<IProduct[] | undefined>>
  product: IProduct | undefined
  setProduct: Dispatch<SetStateAction<IProduct | undefined>>
  users: IUser[] | undefined
  setUsers: Dispatch<SetStateAction<IUser[] | undefined>>
  vehicles: IVehicle[] | undefined
  setVehicles: Dispatch<SetStateAction<IVehicle[] | undefined>>
  vehicle: IVehicle | undefined
  setVehicle: Dispatch<SetStateAction<IVehicle | undefined>>
  user: IUser | undefined
  setUser: Dispatch<SetStateAction<IUser | undefined>>
  modal: boolean
  setModal: Dispatch<SetStateAction<boolean>>
  isCompletedCalled: Boolean
  setIsCompletedcalled: Dispatch<SetStateAction<Boolean>>
  notes: string | undefined
  setNotes: Dispatch<SetStateAction<string | undefined>>
  checks: string[]
  callCreate: Boolean
  setCallCreate: Dispatch<SetStateAction<Boolean>>
  setChecks: Dispatch<SetStateAction<string[]>>
  inspection: IInspection | undefined
  setInspection: Dispatch<SetStateAction<IInspection | undefined>>
  service: IService | undefined
  setService: Dispatch<SetStateAction<IService | undefined>>
  serviceCheckList: any | undefined
  setServiceCheckList: Dispatch<SetStateAction<any>>
  serviceCheckListId: any | undefined
  setServiceCheckListId: Dispatch<SetStateAction<string | undefined>>
  invoiceUrl: string | undefined
  setInvoiceUrl: Dispatch<SetStateAction<string | undefined>>
  vehicleByServiceManager: any | undefined
  setVehicleByServiceManager: Dispatch<SetStateAction<any | undefined>>
  serviceId: string | undefined
  setServiceId: Dispatch<SetStateAction<string | undefined>>
}

const initDataContextPropsState = {
  workshops: undefined,
  workshop: undefined,
  serviceCheckListId: undefined,
  products: undefined,
  vehicles: undefined,
  vehicle: undefined,
  product: undefined,
  users: undefined,
  user: undefined,
  notes: undefined,
  service: undefined,
  serviceCheckList: undefined,
  inspection: undefined,
  serviceId: undefined,
  vehicleByServiceManager: undefined,
  callCreate: true,
  checks: [''],
  isCompletedCalled: false,
  modal: false,
  invoiceUrl: undefined,
  setWorkshops: () => {},
  setServiceCheckList: () => {},
  setServiceCheckListId: () => {},
  setWorkshop: () => {},
  setProducts: () => {},
  setProduct: () => {},
  setUsers: () => {},
  setUser: () => {},
  setVehicles: () => {},
  setVehicle: () => {},
  setModal: () => {},
  setNotes: () => {},
  setInspection: () => {},
  setService: () => {},
  setInvoiceUrl: () => {},
  setChecks: () => {},
  setIsCompletedcalled: () => {},
  setCallCreate: () => {},
  setVehicleByServiceManager: () => {},
  setServiceId: () => {},
}

const DataContext = createContext<DataContextProps>(initDataContextPropsState)

const useDataContext = () => {
  return useContext(DataContext)
}

const DataProvider: FC<WithChildren> = ({children}) => {
  const [workshops, setWorkshops] = useState<IWorkshop[] | undefined>(undefined)
  const [products, setProducts] = useState<IProduct[] | undefined>(undefined)
  const [vehicles, setVehicles] = useState<IVehicle[] | undefined>(undefined)
  const [users, setUsers] = useState<IUser[] | undefined>(undefined)
  const [workshop, setWorkshop] = useState<IWorkshop | undefined>(undefined)
  const [product, setProduct] = useState<IProduct | undefined>(undefined)
  const [vehicle, setVehicle] = useState<IVehicle | undefined>(undefined)

  const [vehicleByServiceManager, setVehicleByServiceManager] = useState<any | undefined>(undefined)
  const [user, setUser] = useState<IUser | undefined>(undefined)
  const [modal, setModal] = useState<boolean>(false)
  const [isCompletedCalled, setIsCompletedcalled] = useState<Boolean>(false)
  const [notes, setNotes] = useState<string | undefined>(undefined)
  const [checks, setChecks] = useState<string[]>([''])
  const [callCreate, setCallCreate] = useState<Boolean>(true)
  const [service, setService] = useState<IService | undefined>(undefined)
  const [serviceCheckList, setServiceCheckList] = useState<any>(undefined)
  const [serviceCheckListId, setServiceCheckListId] = useState<any>(undefined)
  const [inspection, setInspection] = useState<IInspection | undefined>(undefined)
  const [invoiceUrl, setInvoiceUrl] = useState<string | undefined>(undefined)
  const [serviceId, setServiceId] = useState<string | undefined>(undefined)
  useEffect(() => {}, [])

  return (
    <DataContext.Provider
      value={{
        workshops,
        workshop,
        setWorkshop,
        setWorkshops,
        products,
        setProducts,
        product,
        setProduct,
        setUser,
        user,
        users,
        setUsers,
        vehicles,
        setVehicles,
        vehicle,
        setVehicle,
        modal,
        setModal,
        notes,
        setNotes,
        inspection,
        setInspection,
        service,
        setService,
        serviceCheckList,
        setServiceCheckList,
        serviceCheckListId,
        setServiceCheckListId,
        invoiceUrl,
        setInvoiceUrl,
        checks,
        setChecks,
        isCompletedCalled,
        setIsCompletedcalled,
        setCallCreate,
        callCreate,
        serviceId,
        setServiceId,
        vehicleByServiceManager,
        setVehicleByServiceManager,
      }}
    >
      {children}
    </DataContext.Provider>
  )
}

const DataInit: FC<WithChildren> = ({children}) => {
  const {} = useDataContext()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  useEffect(() => {}, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {DataProvider, DataInit, useDataContext}
