import {useMutation, useQueryClient} from 'react-query'
import {deleteVehicle} from '../core/_requests'
import {QUERIES_KEYS} from '../../../helpers/crud-helpers/consts'

function useDeleteVehicle() {
  const queryClient = useQueryClient()

  const {mutate, isLoading, isSuccess} = useMutation((id: string) => deleteVehicle(id), {
    // ✅ Show success message
    onSuccess: () => {
      queryClient.invalidateQueries(QUERIES_KEYS.VEHICLE_LIST)
    },
  })

  return {deleteVehicle: mutate, isLoadingVehicle: isLoading, isSuccessVehicle: isSuccess}
}

export default useDeleteVehicle
