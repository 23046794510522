import axios, {AxiosRequestConfig} from 'axios'
import {RcFile} from 'antd/es/upload'
const IMAGE_REPOSITORY = 'signed-url'
export function createRepository(body: any, url: RcFile): Promise<any | undefined> {
  return axios.post(IMAGE_REPOSITORY, body).then((response) => {
    const customAxios = axios.create()

    const config: AxiosRequestConfig = {
      headers: {
        'x-amz-acl': 'public-read',
      },
    }

    return customAxios.put(response?.data?.signedUrl, url, config).then(() => response?.data)
  })
}
