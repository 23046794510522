/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {useAuth} from '../../modules/auth'
import {VehicleOwnerDashboard} from './VehicleOwnerDashboard'
import useDashboard from './core/hooks/use-dashboard'
import {CustomPageLoader} from 'app/modules/auth/components/CustomPageLoader'
import {useNavigate} from 'react-router-dom'

const DashboardPage: FC<any> = ({user}) => {
  //console.log('user in dashboard', user)
  const {userDashboardData, isLoadingUserDashboardData} = useDashboard()
  const navigate = useNavigate()
  return (
    <>
      {!user.data.user.emailVerified && (
        <div className='alert alert-warning d-flex align-items-center p-5'>
          <span
            className='btn btn-primary me-5 path1 '
            onClick={() => navigate(`/emailverification`)}
          >
            Verify
          </span>
          <span className='path2'></span>

          <div className='d-flex flex-column'>
            <h4 className='mb-1 text-danger'>
              Please check your email for the verification code and click “Verify” to enter it
            </h4>

            <span className='mb-1 text-dark'>Please Check Your email to verify</span>
          </div>
        </div>
      )}
      <div className=''>
        {userDashboardData && !isLoadingUserDashboardData && (
          <VehicleOwnerDashboard userDashboardData={userDashboardData} user={user} />
        )}
        {isLoadingUserDashboardData && <CustomPageLoader />}
      </div>
    </>
  )
}

const DashboardWrapper: FC = () => {
  const {currentUser} = useAuth()
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage user={currentUser} />
    </>
  )
}

export {DashboardWrapper}
