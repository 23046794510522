import {useQuery} from 'react-query'
import {QUERIES_KEYS} from '../../../helpers/crud-helpers/consts'
import {getServicesById} from '../core/_requests'

function useServiceById(serviceId: string) {
  const {data, refetch, isLoading, isFetching, isSuccess} = useQuery(
    [QUERIES_KEYS.SERVICE_LIST, serviceId],
    () => getServicesById(serviceId),
    {
      refetchOnWindowFocus: false,
    }
  )

  return {
    getServiceDataById: refetch,
    serviceData: data,
    isLoadingService: isLoading || isFetching,
    isSuccessgetService: isSuccess,
  }
}

export default useServiceById
