import {MediaType} from '../_model'
import {FileType, getBase64} from '../get-base64'
import useCreateSignedUrl from './use-upload-image'

function useHandleImageUpload() {
  const {createRepo, repoData, isLoadingRepo, isSuccessRepo} = useCreateSignedUrl()

  const handleChange = (
    event: any,
    setLoading: any,
    setImageUrl: any,
    mediaType: any,
    serviceid?: string
  ) => {
    const fileList = event.target.files
    // if (mediaType === `${MediaType.SERVICE}`) {
    //   Object.entries(fileList).forEach((fileObject, index) => {
    //     const file = fileList[index]
    //     getBase64(file as FileType, (url) => {
    //       setLoading(false)
    //       // setImageUrl(url);
    //       createRepo({
    //         name: `${serviceid}-${file.name}`,
    //         type: mediaType,
    //         url: file,
    //       })
    //     })
    //   })
    // } else {
    if (fileList && fileList.length > 0) {
      const file = fileList[0]
      getBase64(file as FileType, (url) => {
        setLoading(false)
        // setImageUrl(url)
        createRepo({
          name: mediaType === `${MediaType.SERVICE}` ? `${serviceid}-${file.name}` : file.name,
          type: mediaType,
          url: file,
        })
      })
    }
  }
  // }

  return {handleChange, repoData, isLoadingRepo, isSuccessRepo}
}

export default useHandleImageUpload
