/* eslint-disable jsx-a11y/anchor-is-valid */
import {Fragment, useEffect} from 'react'
import {useDataContext} from 'app/modules/data'
import {useAuth} from 'app/modules/auth'
import Swal from 'sweetalert2'
import {USER_ROLE} from 'app/constants/constants'
import {Button} from 'react-bootstrap'
import useGenerateServiceHistory from '../../hooks/use-generate-history'
type Props = {
  className: string
  workshop?: any
  vehicleOwnerNotes: string | undefined
  internalNotes: string | undefined
  externalNotes: string | undefined
  invoiceNotes: string | undefined
  serviceStatus: number | undefined
  invoiceUrl: string | undefined
  serviceId: string | undefined
  serviceDetailsGenerated: boolean | undefined
}

export const WorkshopInfo = ({
  className,
  vehicleOwnerNotes,
  serviceDetailsGenerated,
  internalNotes,
  serviceId,
  externalNotes,
  serviceStatus,
  invoiceUrl,
  invoiceNotes,
}: Props) => {
  const {modal, setModal} = useDataContext()
  const {notes, setNotes} = useDataContext()
  const {service, setService} = useDataContext()
  const {setInvoiceUrl} = useDataContext()
  const {currentUser} = useAuth()
  const {
    generateHistory,
    generatedHistory,
    isErrorgenerateHistory,
    isSuccessgenerateHistory,
    isLoadinggenerateHistory,
  } = useGenerateServiceHistory()
  let rows: Array<{description: string; notes: string | undefined}>
  if (currentUser?.data.user.role === USER_ROLE.VEHICLE_OWNER) {
    rows = [
      {description: 'Owner', notes: vehicleOwnerNotes},
      // {description: 'Internal', notes: internalNotes},
      {description: 'Invoice', notes: 'invoiceNote'},
    ]
  } else {
    rows = [
      {description: 'Owner', notes: vehicleOwnerNotes},
      {description: 'Internal', notes: internalNotes},
      {description: 'External', notes: externalNotes},
      {description: 'Invoice', notes: 'invoiceNote'},
    ]
  }

  const handleModal = (notesBy: string) => {
    setModal(true)
    // console.log('Notes by are ', notesBy)
    if (notesBy === 'Invoice') {
      // console.log('url inside invoice ', service?.invoiceUrl, notesBy)
      setInvoiceUrl(service?.invoiceUrl)
    } else {
      //console.log('notes inside non-invoice ', service?.invoiceUrl)
      setInvoiceUrl(undefined)
    }
    setNotes(rows.find((row) => row.description === notesBy)?.notes)
  }
  const handleHistory = () => {
    if (!serviceDetailsGenerated) {
      generateHistory({serviceId: serviceId || ''})
    }
  }
  useEffect(() => {
    if (generatedHistory?.data) {
      setService(generatedHistory?.data)
    }
  }, [generatedHistory])
  useEffect(() => {
    if (isErrorgenerateHistory) {
      Swal.fire({
        title: 'Error',
        text: 'Error Generating Service History. Try again',
        icon: 'error',
        confirmButtonText: 'Ok',
      }).then((result) => {
        //  console.log('Fire erro result ', result)
      })
    } else if (isSuccessgenerateHistory) {
      Swal.fire({
        title: 'Success',
        text: 'Generated Successfully!',
        icon: 'success',
        confirmButtonText: 'Done',
      }).then((result) => {
        //  console.log('Fire result ', result)
      })
    }
  }, [isErrorgenerateHistory, isSuccessgenerateHistory])

  return (
    <div className={`card card-flush ${className}`} title='Click to view notes'>
      <div className='card-header pt-5'>
        <h3 className='card-title text-gray-800 fw-bold'>Notes</h3>
        <div className='card-toolbar'></div>
      </div>
      <div className='card-body pt-5'>
        {rows.map((row, index) => (
          <Fragment key={`lw26-rows-${index}`}>
            <div
              className='d-flex flex-stack cursor-pointer'
              onClick={() => handleModal(row.description)}
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_Service_User_Notes'
            >
              <a href='#' className='text-primary fw-semibold fs-6 me-2'>
                {row.description}
              </a>
            </div>
            {rows.length - 1 > index && <div className='separator separator-dashed my-3' />}
          </Fragment>
        ))}
        {serviceStatus == 10 && (
          <>
            {!serviceDetailsGenerated ? (
              <Button className='mt-5' onClick={handleHistory}>
                {isLoadinggenerateHistory ? 'Generating...' : 'Generate History'}
              </Button>
            ) : (
              <>
                <div className='separator separator-dashed my-3' />
                <a
                  className='text-primary fw-semibold fs-6 me-2'
                  href={`https://viribus-bucket.sfo3.digitaloceanspaces.com/viribus-bucket/service-templates/${invoiceUrl}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  View History
                </a>
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}
