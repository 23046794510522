import {useQuery} from 'react-query'

import {QUERIES_KEYS} from './../../../helpers/crud-helpers/consts'
import {getServicesCheckList} from '../core/_requests'

function useGetServiceCheckList() {
  const {data, isSuccess, isLoading, isFetching} = useQuery(
    QUERIES_KEYS.SERVICE_CHECK_LIST,
    getServicesCheckList,
    {
      select: (res) => res.data,
      refetchOnWindowFocus: false,
    }
  )
  //console.log('workshop got are ', data)
  return {
    servicesCheckListData: data,
    isLoadingServicesCheckList: isLoading || isFetching,
    isSuccess: isSuccess,
  }
}

export default useGetServiceCheckList
