import {useMutation, useQueryClient} from 'react-query'
import {updateUser} from '../_request'
import {QUERIES_KEYS} from 'app/helpers/crud-helpers/consts'

function useUpdateCustomer() {
  const queryClient = useQueryClient()
  const {mutate, isLoading, isError, isSuccess} = useMutation(
    ({id, body}: any) => updateUser(id, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERIES_KEYS.USERS_LIST)
        queryClient.invalidateQueries(QUERIES_KEYS.SERVICE_LIST)
      },
      onError: (error) => {
        console.error('Error updating customer:', error)
      },
    }
  )
  return {blockUser: mutate, isLoadingUpdateUser: isLoading, isError, isSuccess}
}

export default useUpdateCustomer
