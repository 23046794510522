import {useMutation, useQueryClient} from 'react-query'
import {QUERIES_KEYS} from 'app/helpers/crud-helpers/consts'
import {createInvoice} from '../../core/_requests'

function useCreateInvoiceService() {
  const queryClient = useQueryClient()

  const {mutate, isLoading, isError, error, isSuccess} = useMutation(
    (body: {
      serviceId: string
      productsToBeInvoiced: any
      Services?: any
      includeServiceHistory?: boolean
    }) => createInvoice(body),
    {
      onSuccess: () => {
        // console.log('service invoice generated succesfully')
        queryClient.invalidateQueries(QUERIES_KEYS.SERVICE_LIST)
      },
    }
  )

  return {
    generateInvoice: mutate,
    isLoadinggenerateInvoice: isLoading,
    isErrorgenerateInvoice: isError,
    errorgenerateInvoice: error,
    isSuccessgenerateInvoice: isSuccess,
  }
}

export default useCreateInvoiceService
