import {useQuery} from 'react-query'

import {QUERIES_KEYS} from 'app/helpers/crud-helpers/consts'
import {getInspectionByServiceId} from '../../core/inspection/_inspectionRequests'
import {IServiceQueryResponse} from '../../core/inspection/_inspectionModels'
import {useQueryClient} from 'react-query'
function useInspectionByServiceId(serviceId) {
  // console.log('got service id in hooklp ', serviceId)
  const queryClient = useQueryClient()
  const {data, isLoading, isFetching, isSuccess, isError} = useQuery(
    QUERIES_KEYS.INSPECTION_LIST,
    () => getInspectionByServiceId(serviceId),
    {
      select: (res) => {
        return res.data
      },
      onSuccess: () => {
        // queryClient.invalidateQueries(QUERIES_KEYS.SERVICE_LIST)
      },
      refetchOnWindowFocus: false,
    }
  )

  return {
    // getInspections: refetch,
    inspectionData: data,
    isLoadingInspection: isLoading || isFetching,
    isGetInspectionSuccess: isSuccess,
    isGetInspectionError: isError,
  }
}

export default useInspectionByServiceId
