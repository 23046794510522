import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useDataContext} from '../../../modules/data'
import {deleteUser} from '../core/_requests'
import Swal from 'sweetalert2'
import useDeleteUser from '../core/hooks/use-delete-user'
import {useEffect} from 'react'
function UserRow({user}) {
  const {setUser} = useDataContext()
  const {isSuccessUserDelete, deleteUserById, isLoadingUserDelete} = useDeleteUser()
  useEffect(() => {}, [])
  return (
    <>
      <tr>
        <td className='text-start'>
          <div className=''>
            {/* <div className='symbol symbol-50px me-5'>
              <span className='symbol-label bg-light'>
                <img
                  src={toAbsoluteUrl('/media/svg/avatars/001-boy.svg')}
                  className='h-75 align-self-end'
                  alt=''
                />
              </span>
            </div> */}
            <div className='ms-3 d-flex justify-content-start flex-column'>
              <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                {user?.name}
              </a>
              <span className='text-muted fw-semibold text-muted d-block fs-7'>{user?.role}</span>
            </div>
          </div>
        </td>
        <td>
          <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
            {user?.email}
          </a>
          {user?.emailVerified ? (
            <span className='badge badge-light-success'>Verified</span>
          ) : (
            <span className='badge badge-light-danger'>Unverified</span>
          )}
        </td>
        <td>
          <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
            {user?.workshop?.name}
          </a>
          <span className='text-muted fw-semibold text-muted d-block fs-7'>Paid</span>
        </td>
        <td
          className='text-start'
          onClick={() => {
            Swal.fire({
              title: 'Do you want to delete the user',
              showDenyButton: true,
              confirmButtonText: 'Yes',
              icon: 'warning',
            }).then((result) => {
              if (result.isConfirmed) {
                deleteUserById(user._id)
                if (isSuccessUserDelete) {
                  Swal.fire({
                    title: 'Success',
                    text: 'Deleted Successfully',
                    icon: 'success',
                  })
                }
              } else if (result.isDenied) {
              }
            })
          }}
        >
          <a
            href='#'
            className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
          >
            Delete
          </a>
        </td>
      </tr>
    </>
  )
}

export default UserRow
