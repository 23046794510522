import dayjs, {Dayjs} from 'dayjs'
import {Badge, Tooltip} from 'antd'
interface Props {
  value: Dayjs
  serviceData: any
}
function CustomCellRender({value, serviceData}: Props) {
  const dataFromApi =
    serviceData?.map(({date, scheduled, completed}) => ({
      date,
      scheduled,
      completed,
    })) || []
  const object = dataFromApi.find(
    (item) => dayjs(item.date).format('YYYY-MM-DD') === dayjs(value).format('YYYY-MM-DD')
  )
  if (!object) return null

  return (
    <>
      <span style={{position: 'absolute', top: '-13px', right: '-13px', color: 'black'}}>
        {/*Scheduled: {object?.scheduled} Completed :{object?.completed} */}
        <Tooltip title='Scheduled'>
          <Badge
            showZero={true}
            style={{backgroundColor: '#52c41a'}}
            count={object?.scheduled}
            size='small'
          />
        </Tooltip>
      </span>
      <Tooltip title='Completed'>
        <span style={{position: 'absolute', top: '-13px', right: '-25px', color: 'black'}}>
          <Badge showZero={true} count={object?.completed} size='small'></Badge>
        </span>
      </Tooltip>
    </>
  )
}
export default CustomCellRender
