import axios from 'axios'

const GET_DASHBOARD_DATA = '/dashboard'
const CALENDAR = 'vehicle-service/calender'
export async function getDashboardData(): Promise<any> {
  let result = await axios.get(GET_DASHBOARD_DATA).then((response) => response.data)
  // console.log('result got by dashboard data ', result)
  return result
}
export async function getCalendarData(): Promise<any> {
  let result = await axios.get(CALENDAR).then((response) => response.data)

  return result
}
