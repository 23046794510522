import {useQuery} from 'react-query'

import {QUERIES_KEYS} from './../../../helpers/crud-helpers/consts'
import {getVehicles} from '../core/_requests'

function useVehicle() {
  const {data, isLoading, isFetching} = useQuery(QUERIES_KEYS.VEHICLE_LIST, getVehicles, {
    select: (res) => res.data,
    refetchOnWindowFocus: false,
  })

  return {
    vehicleData: data,
    isLoadingVehicle: isLoading || isFetching,
  }
}

export default useVehicle
