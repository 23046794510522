import {CustomPageLoader} from 'app/modules/auth/components/CustomPageLoader'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import UserTable from './components/UserTable'
import useUser from './core/hooks/use-users'
import {USER_ROLE} from 'app/constants/constants'

function Users() {
  const {usersData, isLoadingUsers} = useUser()
  //console.log('USERS IN staff ', usersData)
  return isLoadingUsers ? (
    <CustomPageLoader />
  ) : (
    <UserTable users={usersData?.filter((user) => user?.role !== USER_ROLE.SUPER_USER)} />
  )
}

export default Users
