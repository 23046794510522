import clsx from 'clsx'
import {useFormik} from 'formik'
import React, {useEffect} from 'react'
import * as Yup from 'yup'
import useUpdateUser from './hooks/use-update-user'
import {useAuth} from 'app/modules/auth'
import Swal from 'sweetalert2'
import {useNavigate} from 'react-router-dom'
const initialValues = {
  currentPassword: '',
  newPassword: '',
  retryNewPassword: '',
}
const registrationSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .min(8, 'Minimum 8 characters are required')
    .max(50, 'Maximum 50 characters are required')
    .required('Current password is required'),
  newPassword: Yup.string()
    .min(8, 'Minimum 8 characters are required')
    .max(50, 'Maximum 50 characters are required')
    .required('New Password is required'),
  retryNewPassword: Yup.string()
    .min(8, 'Minimum 8 characters are required')
    .max(50, 'Maximum 50 characters are required')
    .required('New Password confirmation is required')
    .oneOf([Yup.ref('newPassword')], "Password and Confirm Password didn't match"),
})
export const Profile = () => {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const {
    updateUserById,
    isLoadingUpdateUser,
    isErrorUpdateUser,
    isSuccessUpdateUser,
    errorInPasswordUpdate,
  } = useUpdateUser()
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (value) => {
      await updateUserById({
        currentPassword: value.currentPassword,
        newPassword: value.newPassword,
        confirmNewPassword: value.retryNewPassword,
        _id: currentUser?.data.user._id,
      })
      // console.log('signup values ', values)
      //  setLoading(true)
      //  try {
      //    const {data: auth} = await register(
      //      values.email,
      //      values.name,
      //      values.password,
      //      values.role
      //    )
      //    if (auth.message === 'Signup Successful') {
      //      Swal.fire({
      //        title: 'Success',
      //        text: auth.message,
      //        icon: 'success',
      //        confirmButtonText: 'ok',
      //      }).then((result) => {
      //        navigate(`/auth/login`)
      //      })
      //    }
      //  } catch (error) {
      //    console.error(error)
      //    saveAuth(undefined)
      //    setStatus('The registration details are incorrect')
      //    setSubmitting(false)
      //    setLoading(false)
      //  }
    },
  })
  useEffect(() => {
    if (isErrorUpdateUser) {
      Swal.fire({
        title: 'Oops ',
        text: `Error : ${errorInPasswordUpdate}`,
        icon: 'error',
        confirmButtonText: 'ok',
      }).then((result) => {
        handleInitialDataCall()
      })
    } else if (isSuccessUpdateUser) {
      Swal.fire({
        title: 'Success',
        text: 'Updated Successfully!',
        icon: 'success',
        confirmButtonText: 'Ok',
      }).then((result) => {
        handleInitialDataCall()
        navigate(`/dashboard`)
      })
    }
  }, [isSuccessUpdateUser, isErrorUpdateUser])
  const handleInitialDataCall = () => {
    formik.resetForm()
  }
  return (
    <div className=''>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework d-flex justify-content-center align-items-center flex-column'
        noValidate
        id='kt_login_signup_form'
        onSubmit={(e) => {
          e.preventDefault()
          formik.handleSubmit()
        }}
      >
        {/* begin::Form group Password */}
        <div className='fv-row mb-8 w-50' data-kt-password-meter='true'>
          <div className='mb-1'>
            <label className='form-label fw-bolder text-dark fs-6'>Current Pasword</label>
            <div className='position-relative mb-3'>
              <input
                type='password'
                placeholder='Enter your current password'
                autoComplete='off'
                {...formik.getFieldProps('currentPassword')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.currentPassword && formik.errors.currentPassword,
                  },
                  {
                    'is-valid': formik.touched.currentPassword && !formik.errors.currentPassword,
                  }
                )}
              />
              {formik.touched.currentPassword && formik.errors.currentPassword && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.currentPassword}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* end::Form group */}

        {/* begin::Form group Password */}
        <div className='fv-row mb-8 w-50' data-kt-password-meter='true'>
          <div className='mb-1'>
            <label className='form-label fw-bolder text-dark fs-6'>New Password</label>
            <div className='position-relative mb-3'>
              <input
                type='password'
                placeholder='Enter your new password'
                autoComplete='off'
                {...formik.getFieldProps('newPassword')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.newPassword && formik.errors.newPassword,
                  },
                  {
                    'is-valid': formik.touched.newPassword && !formik.errors.newPassword,
                  }
                )}
              />
              {formik.touched.newPassword && formik.errors.newPassword && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.newPassword}</span>
                  </div>
                </div>
              )}
            </div>
            {/* begin::Meter */}
            <div
              className='d-flex align-items-center mb-3'
              data-kt-password-meter-control='highlight'
            >
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
            </div>
            {/* end::Meter */}
          </div>
          <div className='text-muted'>
            Use 8 or more characters with a mix of letters, numbers & symbols.
          </div>
        </div>
        {/* end::Form group */}

        {/* begin::Form group Confirm password */}
        <div className='fv-row mb-5 w-50'>
          <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
          <input
            type='password'
            placeholder='Password confirmation'
            autoComplete='off'
            {...formik.getFieldProps('retryNewPassword')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.retryNewPassword && formik.errors.retryNewPassword,
              },
              {
                'is-valid': formik.touched.retryNewPassword && !formik.errors.retryNewPassword,
              }
            )}
          />
          {formik.touched.retryNewPassword && formik.errors.retryNewPassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.retryNewPassword}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='text-center w-50'>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-50 mb-5'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!isLoadingUpdateUser && <span className='indicator-label'>Submit</span>}
            {isLoadingUpdateUser && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Form group */}
      </form>
    </div>
  )
}
