import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, verifyEmail, verifyOTP} from '../core/_requests'
import OtpBoxes from './OTP-Form'
import {useAuth} from '../core/Auth'
import {resendEmailOtp} from '../core/_requests'
const verifyOtpSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  otp: Yup.string().min(6, 'Minimum 6 Digits'),
})

export function VerifyUserEmail() {
  const {currentUser, setCurrentUser} = useAuth()
  //currentUser?.data.user.email
  // const {email} = useParams()
  const initialValues = {
    email: currentUser?.data.user.email,
    otp: '',
  }

  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [otp, setOtp] = useState('')
  const {state} = useLocation()
  const {auth, logout} = useAuth()
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues: {...initialValues},
    validationSchema: verifyOtpSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        verifyEmail(values.email as string, values.otp)
          .then(async ({data: {result}}) => {
            setHasErrors(false)
            setLoading(false)
            const apiToken = auth?.data.api_token as string
            const {data: user} = await getUserByToken(apiToken)

            setCurrentUser(user)
            navigate(`/dashboard`)
          })
          .catch((error: any) => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus(error.response.data.message)
          })
      }, 1000)
    },
  })
  const resendOtpHandling = () => {
    resendEmailOtp(formik.values.email as any)
  }
  return (
    <div className='d-flex justify-content-center align-items-center vh-60'>
      <form
        className='form w-50 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark fw-bolder mb-3'>Verify Email</h1>
          {/* end::Title */}

          {/* begin::Link */}
          {/* <div className='text-gray-500 fw-semibold fs-6'>Enter your email to verify .</div> */}
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}

        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
          <input
            type='email'
            readOnly
            placeholder='Enter your email here'
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email as any}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>
            Enter your email verification code here :
          </label>
          <OtpBoxes formik={formik} />
          {formik.touched.otp && formik.errors.otp && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.otp as any}</span>
              </div>
            </div>
          )}
        </div>

        {/* begin::Form group */}
        <div className='d-flex align-items-center justify-content-end mb-5'>
          <p className='my-auto  bg-transparent'> Didn't receive a code? Send again.</p>
          <button
            onClick={() => resendOtpHandling()}
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light ms-2'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            <i className='fas fa-sync-alt text-primary'></i>
          </button>
        </div>

        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
            <span className='indicator-label'>Submit</span>
            {loading && otp.length === 6 && (
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/dashboard'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-light'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Cancel
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </form>
    </div>
  )
}
