import React from 'react'
import {VehicleServiceRow} from './VehicleServiceRow'
type props = {
  services: any
}
export const VehicleServiceTable: React.FC<props> = ({services}) => {
  return (
    <div className={`card mt-10`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Services</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 new products</span> */}
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      {services?.length > 0 ? (
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-125px rounded-start'>VIN</th>
                  <th className='min-w-125px'>Assigned To</th>
                  <th className='min-w-125px'>Start Date</th>
                  <th className='min-w-200px'>Vehicle Owner</th>
                  <th className='min-w-150px'>Workshop</th>
                  <th className='min-w-150px'>Status</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}

              <tbody>
                {services?.map((product, index) => (
                  <VehicleServiceRow key={index} serviceProp={product} />
                ))}
              </tbody>

              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
      ) : (
        <p className='ms-10'>No services found</p>
      )}
      {/* begin::Body */}
    </div>
  )
}
