import {useQuery} from 'react-query'

import {QUERIES_KEYS} from './../../../../helpers/crud-helpers/consts'
import {getUsers} from '../_requests'

function useUser() {
  const {data, isLoading, isFetching} = useQuery(QUERIES_KEYS.USERS_LIST, getUsers, {
    select: (res) => res.data,
    refetchOnWindowFocus: false,
  })

  return {
    usersData: data,
    isLoadingUsers: isLoading || isFetching,
  }
}

export default useUser
