import React, {useEffect, useRef, useState} from 'react'
import {Dropdown} from 'react-bootstrap'
import {useLocation, useNavigate} from 'react-router-dom'
import {IService} from '../../core/_models'
import {useDataContext} from 'app/modules/data'
import {USER_ROLE} from 'app/constants/constants'
import useUpdateService from '../../hooks/use-update-service'
import useCreateInvoiceService from '../../hooks/inspection-hooks/use-service-invoice-generation'
import {useAuth} from 'app/modules/auth'
import usePauseResumeService from '../../hooks/use-pause-resume-service'
interface ServiceActionDropdownProps {
  role: string

  serviceProp: IService
}
export const ServiceActionDropdown: React.FC<ServiceActionDropdownProps> = ({
  role,

  serviceProp,
}) => {
  const editorRef = useRef(null)
  const {setService, setIsCompletedcalled, setServiceId} = useDataContext()
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const {generateInvoice} = useCreateInvoiceService()
  const {pauseResumeService} = usePauseResumeService()
  const state = useLocation()
  const serviceId = state.pathname.split('/')[2]
  const {
    updateService,
    isLoadingupdateService,
    errorupdateService,
    isErrorupdateService,
    isSuccessupdateService,
  } = useUpdateService()
  const handleServiceSchedule = () => {
    setService(serviceProp)
  }
  const handleServicePlay = () => {
    //api call to set status to 4-work in progress
    updateService({
      id: serviceProp._id,
      body: {status: 4, fixedBy: currentUser?.data.user._id},
    })
  }
  const handleServiceAwaitingApproval = () => {
    //api call to set status to 7-awaiting approval
    updateService({
      id: serviceProp._id,
      body: {status: 7, fixedBy: currentUser?.data.user._id},
    })
  }
  const handleServiceProducts = () => {
    //set workshop products and service products in context
    setService(serviceProp)
  }
  const handleServicePause = () => {
    setServiceId(serviceProp._id)
  }
  const handleServiceResume = () => {
    pauseResumeService({
      id: serviceProp._id,
    })
  }

  return (
    <div>
      <Dropdown>
        <Dropdown.Toggle variant='primary' id='dropdown-basic'>
          Select Action
        </Dropdown.Toggle>
        <Dropdown.Menu className='min-w-100 w-auto '>
          {!serviceId && (
            <Dropdown.Item
              onClick={() => {
                navigate(`/service/${serviceProp?._id}`, {
                  state: serviceProp,
                })
                setService(serviceProp)
              }}
            >
              <a
                className='btn w-100'
                data-toggle='tooltip'
                data-placement='top'
                title='Veiw Service Detail'
                style={{
                  backgroundColor: 'white',
                  cursor: 'pointer', // Ensure cursor changes on hover
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.color = '#007fe2' // Use currentTarget to access the div
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.color = 'black'
                }}
              >
                View
              </a>
            </Dropdown.Item>
          )}
          {role !== USER_ROLE.SENIOR_MECHANIC && role !== USER_ROLE.JUNIOR_MECHANIC ? (
            <>
              {serviceProp?.status == 0 &&
                (role == USER_ROLE.SERVICE_MANAGER || role == USER_ROLE.WORKSHOP_OWNER) && (
                  <Dropdown.Item
                    className='card-toolbar ms-1'
                    onClick={() => {
                      handleServiceSchedule()
                    }}
                  >
                    <a
                      className='btn  w-100'
                      data-toggle='tooltip'
                      data-placement='top'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_serviceDate_selection'
                      title='Schedule Service'
                      style={{
                        backgroundColor: 'white',
                        cursor: 'pointer', // Ensure cursor changes on hover
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.color = '#007fe2' // Use currentTarget to access the div
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.color = 'black'
                      }}
                    >
                      Schedule
                    </a>
                  </Dropdown.Item>
                )}
            </>
          ) : null}
          {(role == USER_ROLE.WORKSHOP_OWNER || role == USER_ROLE.SERVICE_MANAGER) &&
            serviceProp?.status == 6 && (
              <Dropdown.Item
                className='card-toolbar ms-1'
                onClick={() =>
                  updateService({
                    id: serviceProp._id,
                    body: {status: 3},
                  })
                }
              >
                <a
                  className='btn  w-auto'
                  data-toggle='tooltip'
                  data-placement='top'
                  title='Required resources acquired'
                  style={{
                    backgroundColor: 'white',
                    cursor: 'pointer', // Ensure cursor changes on hover
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.color = '#007fe2' // Use currentTarget to access the div
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.color = 'black'
                  }}
                >
                  Resource acquired
                </a>
              </Dropdown.Item>
            )}
          {(role == USER_ROLE.WORKSHOP_OWNER || role == USER_ROLE.SERVICE_MANAGER) &&
            serviceProp?.status == 8 && (
              <Dropdown.Item
                className='card-toolbar ms-1'
                title='Add Invoice details'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_service_Invoice_Details'
                onClick={() => {
                  handleServiceSchedule()
                }}
                // onClick={() => {
                //   generateInvoice({
                //     serviceId: serviceProp._id,
                //   })
                // }}
              >
                <a
                  className='btn w-100'
                  data-toggle='tooltip'
                  data-placement='top'
                  title='Generate Invoice'
                  style={{
                    backgroundColor: 'white',
                    cursor: 'pointer', // Ensure cursor changes on hover
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.color = '#007fe2' // Use currentTarget to access the div
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.color = 'black'
                  }}
                >
                  Invoice
                </a>
              </Dropdown.Item>
            )}
          {(role == USER_ROLE.WORKSHOP_OWNER || role == USER_ROLE.SERVICE_MANAGER) &&
            serviceProp?.status == 9 && (
              <Dropdown.Item
                className='card-toolbar ms-1'
                onClick={() => {
                  handleServiceSchedule()
                }}
              >
                <a
                  className='btn w-100'
                  data-toggle='tooltip'
                  data-placement='top'
                  title='Add payment details'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_service_Payment_Details'
                  style={{
                    backgroundColor: 'white',
                    cursor: 'pointer', // Ensure cursor changes on hover
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.color = '#007fe2' // Use currentTarget to access the div
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.color = 'black'
                  }}
                >
                  Payment
                </a>
              </Dropdown.Item>
            )}
          {(role == USER_ROLE.WORKSHOP_OWNER || role == USER_ROLE.SERVICE_MANAGER) &&
            serviceProp?.status == 1 && (
              <Dropdown.Item
                className='card-toolbar ms-1'
                onClick={() => {
                  updateService({
                    id: serviceProp._id,
                    body: {status: 2},
                  })
                }}
              >
                <a
                  className='btn  w-100'
                  data-toggle='tooltip'
                  data-placement='top'
                  title='Service pending'
                  style={{
                    backgroundColor: 'white',
                    cursor: 'pointer', // Ensure cursor changes on hover
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.color = '#007fe2' // Use currentTarget to access the div
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.color = 'black'
                  }}
                >
                  Pending
                </a>
              </Dropdown.Item>
            )}
          {role === USER_ROLE.JUNIOR_MECHANIC || role === USER_ROLE.SENIOR_MECHANIC ? (
            <>
              {serviceProp?.status === 2 && (
                <Dropdown.Item
                  className='card-toolbar ms-1'
                  onClick={() =>
                    updateService({
                      id: serviceProp._id,
                      body: {status: 3, fixedBy: currentUser?.data.user._id},
                    })
                  }
                >
                  <a
                    className='btn  w-100'
                    title='Assign service to self'
                    style={{
                      backgroundColor: 'white',
                      cursor: 'pointer', // Ensure cursor changes on hover
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.color = '#007fe2' // Use currentTarget to access the div
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.color = 'black'
                    }}
                  >
                    Assign
                  </a>
                </Dropdown.Item>
              )}

              {serviceProp?.fixedBy?._id === currentUser?.data.user._id && ( //assigned to thic mechanic check
                <>
                  {serviceProp?.status == 3 && (
                    <Dropdown.Item
                      className='card-toolbar ms-1'
                      onClick={() => handleServicePlay()}
                    >
                      <a
                        className='btn  w-100'
                        data-toggle='tooltip'
                        data-placement='top'
                        title='Start Service'
                        style={{
                          backgroundColor: 'white',
                          cursor: 'pointer', // Ensure cursor changes on hover
                        }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.color = '#007fe2' // Use currentTarget to access the div
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.color = 'black'
                        }}
                      >
                        Start
                      </a>
                    </Dropdown.Item>
                  )}
                  {/* 4-- work in progress  */}

                  {serviceProp?.status == 4 && (
                    <>
                      {serviceProp?.currentTimeLapse !== null ? (
                        <>
                          <Dropdown.Item
                            className='card-toolbar ms-1'
                            onClick={() => {
                              handleServicePause()
                            }}
                          >
                            <a
                              className='btn  w-100'
                              data-toggle='tooltip'
                              data-placement='top'
                              title='Pause'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_pause_service'
                              style={{
                                backgroundColor: 'white',
                                cursor: 'pointer', // Ensure cursor changes on hover
                              }}
                              onMouseEnter={(e) => {
                                e.currentTarget.style.color = '#007fe2' // Use currentTarget to access the div
                              }}
                              onMouseLeave={(e) => {
                                e.currentTarget.style.color = 'black'
                              }}
                            >
                              Pause
                            </a>
                          </Dropdown.Item>
                          <Dropdown.Item
                            className='card-toolbar ms-1'
                            onClick={() => {
                              setIsCompletedcalled(true)
                              handleServiceProducts()
                              // handleServiceAwaitingApproval()
                            }}
                          >
                            <a
                              className='btn  w-100'
                              data-toggle='tooltip'
                              data-placement='top'
                              title='Work completed'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_product_manage'
                              style={{
                                backgroundColor: 'white',
                                cursor: 'pointer', // Ensure cursor changes on hover
                              }}
                              onMouseEnter={(e) => {
                                e.currentTarget.style.color = '#007fe2' // Use currentTarget to access the div
                              }}
                              onMouseLeave={(e) => {
                                e.currentTarget.style.color = 'black'
                              }}
                            >
                              Completed
                            </a>
                          </Dropdown.Item>
                          <Dropdown.Item
                            className='card-toolbar ms-1'
                            onClick={handleServiceProducts}
                          >
                            <a
                              className='btn  w-100'
                              data-toggle='tooltip'
                              data-placement='top'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_product_manage'
                              title='Manage Products'
                              style={{
                                backgroundColor: 'white',
                                cursor: 'pointer', // Ensure cursor changes on hover
                              }}
                              onMouseEnter={(e) => {
                                e.currentTarget.style.color = '#007fe2' // Use currentTarget to access the div
                              }}
                              onMouseLeave={(e) => {
                                e.currentTarget.style.color = 'black'
                              }}
                            >
                              Products
                            </a>
                          </Dropdown.Item>
                        </>
                      ) : (
                        <Dropdown.Item
                          className='card-toolbar ms-1'
                          onClick={() => {
                            handleServiceResume()
                          }}
                        >
                          <a
                            className='btn  w-100'
                            data-toggle='tooltip'
                            data-placement='top'
                            title='Resume'
                            style={{
                              backgroundColor: 'white',
                              cursor: 'pointer', // Ensure cursor changes on hover
                            }}
                            onMouseEnter={(e) => {
                              e.currentTarget.style.color = '#007fe2' // Use currentTarget to access the div
                            }}
                            onMouseLeave={(e) => {
                              e.currentTarget.style.color = 'black'
                            }}
                          >
                            Resume
                          </a>
                        </Dropdown.Item>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          ) : null}

          {/* <Dropdown.Item>{action}</Dropdown.Item>
          <Dropdown.Item>{action}</Dropdown.Item>
          <Dropdown.Item>{action}</Dropdown.Item> */}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}
