import React, {useEffect, useRef, useState} from 'react'
import {KTIcon} from '_metronic/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {IProduct} from 'app/pages/product/core/_models'
import {useDataContext} from 'app/modules/data'
import Swal from 'sweetalert2'
import {Dropdown} from 'react-bootstrap'
import useWorkshop from 'app/pages/workshop/hooks/use-workshop'
import useProduct from 'app/pages/product/core/hooks/use-products'
import useUpdateService from '../../hooks/use-update-service'
import useProductByWorkshopId from 'app/pages/product/core/hooks/use-products-get-by-id'
import {useAuth} from 'app/modules/auth'
import JoditEditor from 'jodit-react'
import {Select} from 'antd'

const ServiceProductsForm: React.FC = () => {
  const {currentUser} = useAuth()
  const {
    updateService,
    isLoadingupdateService,
    errorupdateService,
    isErrorupdateService,
    isSuccessupdateService,
  } = useUpdateService()
  const [editorData, setEditorData] = useState(' ')
  const editorRef = useRef(null)
  const [externalNotesEditorData, setexternalNotesEditorData] = useState(' ')
  const externalNotesEditorRef = useRef(null)
  const {productsData} = useProductByWorkshopId(currentUser?.data?.user?.workshop)

  // const {createProduct, isSuccessCreateProduct, isLoadingCreateProduct} = useCreateProduct()
  // const {product, setProduct} = useDataContext()
  const {service, isCompletedCalled, setIsCompletedcalled} = useDataContext()
  const {Option} = Select
  const [selectedProducts, setSelectedProducts] = useState<IProduct | any>(
    service?.products && service?.products?.length > 0
      ? service?.products
      : [{product: '', cost: '', retailPrice: '', name: '', quantity: 1}]
  )
  //   const [selectedProducts, setSelectedProducts] = useState<IProduct[] | []>([
  //     {
  //       _id: '65b0dc5e4dacbc246b66f1ca',
  //       workshop: '65ae6dab7f4ed881198c9068',
  //       name: 'Dane Lawson',
  //       type: 'Qui vero in dolore e',
  //       brand: 'Et hic qui aute quod',
  //       units: 150,
  //       cost: 21200,
  //       retailPrice: 319,
  //       isDeleted: false,
  //       createdAt: '2024-01-24T09:46:06.890Z',
  //       updatedAt: '2024-02-01T05:12:39.554Z',
  //     },
  //     {
  //       _id: '65af3b4e6b7420d74e442aab',
  //       workshop: '65ae6dab7f4ed881198c9068',
  //       name: 'Caesar Tillman56',
  //       type: 'Ut ullamco perferend',
  //       brand: 'Voluptates recusanda',
  //       units: 50,
  //       cost: 50,
  //       retailPrice: 55,
  //       isDeleted: false,
  //       createdAt: '2024-01-23T04:06:38.736Z',
  //       updatedAt: '2024-01-23T04:28:43.287Z',
  //     },
  //   ])
  const [isProblemPending, setIsProblemPending] = useState(false)
  //console.log('GOT SERVICE ', service)
  const productDetailsSchema = Yup.object().shape({
    // ...(isProblemPending === true && {reason: Yup.string().required('reason is required')}),
    internalNotes: Yup.string().required('Internal Notes are required'),
    // externalNotes: Yup.string().required('External Notes are required'),
  })

  let initialValues = {
    //...(isProblemPending === true && {reason: ''}),
    internalNotes: editorData,
    externalNotes: externalNotesEditorData,
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    // validationSchema: productDetailsSchema,
    onSubmit: (values) => {
      //product ? updateProduct(values) : createProduct(values)
      // setProduct(undefined)
      //start
      if (isProblemPending == true) {
        updateService({
          id: service?._id,
          body: {
            status: 6,
            products: selectedProducts,
            internalNotes: values.internalNotes,
            externalNotes: values.externalNotes,
          },
        })
        setSelectedProducts(
          service?.products && service?.products?.length > 0
            ? service?.products
            : [{product: '', cost: '', retailPrice: '', name: '', quantity: 1}]
        )
        setIsProblemPending(false)
        setIsCompletedcalled(false)
        setEditorData(service?.internalNotes || '')
        setexternalNotesEditorData(service?.externalNotes || '')
        formik.resetForm()
      } else {
        updateService({
          id: service?._id,
          body: {
            products: selectedProducts,
            internalNotes: values.internalNotes,
            externalNotes: values.externalNotes,
          },
        })
        setSelectedProducts(
          service?.products && service?.products?.length > 0
            ? service?.products
            : [{product: '', cost: '', retailPrice: '', name: '', quantity: 1}]
        )
        setIsProblemPending(false)
        setIsCompletedcalled(false)
        setEditorData(service?.internalNotes || '')
        setexternalNotesEditorData(service?.externalNotes || '')
        formik.resetForm()
      }
      if (isCompletedCalled == true) {
        updateService({
          id: service?._id,
          body: {status: 7, fixedBy: currentUser?.data.user._id},
        })
        setIsCompletedcalled(false)
        setSelectedProducts(
          service?.products && service?.products?.length > 0
            ? service?.products
            : [{product: '', cost: '', retailPrice: '', name: '', quantity: 1}]
        )
        setIsProblemPending(false)
        setEditorData(service?.internalNotes || '')
        setexternalNotesEditorData(service?.externalNotes || '')
        formik.resetForm()
      }
      //end
    },
  })

  const handleSelectInputChange = (index: number, productId: string) => {
    const productFound = productsData.find((product) => product?._id === productId)
    setSelectedProducts((prevChecks) => {
      const newChecks = [...prevChecks]
      newChecks[index] = {
        ...newChecks[index],
        product: productId,
        name: productFound?.name,
        retailPrice: productFound?.retailPrice,
        cost: productFound?.cost,
      }

      return newChecks
    })
  }

  const handleAddCheck = () => {
    setSelectedProducts((prevChecks) => [
      ...prevChecks,
      {product: '', cost: '', retailPrice: '', name: '', quantity: 1},
    ])
  }

  const handleRemoveCheck = (index: number) => {
    setSelectedProducts((prevChecks) => {
      const newChecks = [...prevChecks]
      newChecks.splice(index, 1)
      return newChecks
    })
  }

  const handleInputChange = (index: number, value: string, field: string) => {
    setSelectedProducts((prevChecks) => {
      const newChecks = [...prevChecks]
      newChecks[index][field] = Math.abs(parseFloat(value)).toString()
      return newChecks
    })
  }

  //const handleSelectInputChange = (index: number, productId: string) => {
  // const [productId, name, retailPrice, cost] = value.split('-')

  // setSelectedProducts((prevChecks) => {
  //   const newChecks = [...prevChecks]
  //   newChecks[index] = {
  //     ...newChecks[index],
  //     product: productId,
  //     name,
  //     retailPrice,
  //     cost,
  //     value, // Store the concatenated string in the value property
  //   }

  //   return newChecks
  // })

  //}

  // useEffect(() => {
  //   console.log('Selected products are', selectedProducts, 'normal products are ', productsData)
  // }, [selectedProducts])
  useEffect(() => {
    setSelectedProducts(
      service?.products && service?.products?.length > 0
        ? service?.products
        : [{product: {}, quantity: 1}]
    )
    setEditorData(service?.internalNotes || '')
    setexternalNotesEditorData(service?.externalNotes || '')
    setIsProblemPending(false)
  }, [service])
  useEffect(() => {
    service?.internalNotes && setEditorData(service?.internalNotes)
    service?.externalNotes && setexternalNotesEditorData(service?.externalNotes)
  }, [service])
  useEffect(() => {
    formik.setFieldValue('internalNotes', editorData)
  }, [editorData])
  useEffect(() => {
    formik.setFieldValue('externalNotes', externalNotesEditorData)
  }, [externalNotesEditorData])
  // useEffect(() => {
  //   if (isSuccessupdateService) {
  //     Swal.fire({
  //       title: 'Success',
  //       text: 'updated Successfully!',
  //       icon: 'success',
  //       confirmButtonText: 'Ok',
  //     }).then((result) => {
  //       isCompletedCalled &&
  //         updateService({
  //           id: service?._id,
  //           body: {status: 7, fixedBy: currentUser?.data.user._id},
  //         })
  // setSelectedProducts(service?.products || [])
  // setIsProblemPending(false)
  // setEditorData(service?.internalNotes || '')
  // formik.resetForm()
  //     })
  //   } else if (isErrorupdateService) {
  //     Swal.fire({
  //       title: 'oops...',
  //       text: 'Error updating',
  //       icon: 'error',
  //       confirmButtonText: 'OK',
  //     }).then((result) => {
  //       setSelectedProducts(service?.products || [])
  //       setIsProblemPending(false)
  //       setEditorData(service?.internalNotes || '')
  //       formik.resetForm()
  //     })
  //   }
  // }, [isSuccessupdateService, isErrorupdateService])

  return (
    <div className='modal fade' id='kt_modal_product_manage' aria-hidden='true'>
      <div className='modal-dialog mw-850px '>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-space-between'>
            <h3 className='text-center'>Manage Products</h3>
            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              data-bs-dismiss='modal'
              style={{borderRadius: '50%', border: '1px solid'}}
              onClick={(e) => {
                e.preventDefault()
                setSelectedProducts(
                  service?.products && service?.products?.length > 0
                    ? service?.products
                    : [{product: {}, quantity: 1}]
                )
                setIsProblemPending(false)
                setIsCompletedcalled(false)
                setEditorData(service?.internalNotes || '')
                formik.resetForm()
              }}
            >
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <form onSubmit={formik.handleSubmit} noValidate className='form'>
              <div className='card-body border-top p-5 py-9'>
                {!isCompletedCalled && (
                  <div className='row mb-6'>
                    {/* {service?.products?.length} */}
                    <div className='col-lg-2 form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input widget-13-check mx-10'
                        type='checkbox'
                        checked={isProblemPending}
                        data-bs-trigger='hover'
                        title='Pending resource'
                        onChange={(e) => {
                          setIsProblemPending(!isProblemPending)
                        }}
                      />
                      {/* <a className='text-gray-900  text-hover-primary fs-9 '>Pending resource</a> */}
                    </div>

                    <div className='col-lg-10'>
                      <div className='row'>
                        <div className='col-lg-12 fv-row'>
                          <div className='form-check form-check-sm form-check-custom form-check-solid ms-3'>
                            {/* {isProblemPending && (
                            <input
                              type='text'
                              className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                              placeholder='Enter reason'
                              {...formik.getFieldProps('reason')}
                            />
                          )} */}
                            Mark as awaiting resources
                          </div>
                          {/* {formik.touched.reason && formik.errors.reason && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.reason}</div>
                          </div>
                        )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className='row mb-6 d-flex flex-wrap'>
                  <label className='col-lg-2 col-form-label fw-bold fs-6'>Products</label>
                  {selectedProducts?.map((selectedProduct, index) => (
                    <React.Fragment key={index}>
                      <div className={`${index !== 0 ? 'offset-lg-2 mt-5 col-lg-5' : 'col-lg-5'}`}>
                        <select
                          aria-label='Select Product'
                          data-control='select3'
                          value={selectedProduct.product}
                          onChange={(e) => handleSelectInputChange(index, e.target.value)}
                          className='form-select form-select-solid py-4'
                        >
                          <option value=''>Select product</option>
                          {productsData?.length > 0
                            ? productsData?.map((product) => (
                                <option key={product._id} value={product._id}>
                                  {product.name}
                                </option>
                              ))
                            : 'No products to select'}
                        </select>
                      </div>

                      <div className={`${index !== 0 ? 'col-lg-3 mt-5' : 'col-lg-4'}`}>
                        <input
                          type='number'
                          min={1}
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 py-4'
                          placeholder='Quantity'
                          value={selectedProduct?.quantity}
                          onChange={(e) => handleInputChange(index, e.target.value, 'quantity')}
                        />
                      </div>

                      <div className={`${index !== 0 ? 'col-lg-1 mt-5' : 'col-lg-1'}`}>
                        <button
                          type='button'
                          className='btn btn-sm fw-bold btn-primary py-4 mt-1'
                          onClick={handleAddCheck}
                        >
                          +
                        </button>
                      </div>
                      {index !== 0 && (
                        <div className='col-lg-1 mt-5'>
                          <button
                            type='button'
                            className='btn btn-sm fw-bold btn-primary py-4 mt-1'
                            onClick={() => handleRemoveCheck(index)}
                          >
                            -
                          </button>
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                </div>
                {/* <div className='row mb-6 d-flex flex-wrap'>
                  <label className='col-lg-2 col-form-label fw-bold fs-6'>Products</label>

                  {selectedProducts?.map((selectedProduct, index) => (
                    <>
                      <div className={`${index !== 0 ? 'offset-lg-2 mt-5 col-lg-5' : 'col-lg-5'}`}>
                        
                        <select
                          aria-label='Select Product'
                          data-control='select3'
                          // defaultValue={selectedProduct?.name}
                          // value={selectedProduct.name}
                          onChange={(e) => handleSelectInputChange(index, e.target.value)}
                          className='form-select form-select-solid py-4'
                        >
                          <option value=''>Select product</option>
                          {productsData?.length > 0
                            ? productsData.map((product) => (
                                <option
                                  // key={product._id}
                                  // value={`${product._id}-${product.name}-${product.retailPrice}-${product.cost}`}
                                  value={product._id}
                                >
                                  {product.name}
                                </option>
                              ))
                            : 'No products to select'}
                        </select>
                        
                      </div>

                      <div className={`${index !== 0 ? 'col-lg-3 mt-5' : 'col-lg-4'}`}>
                        <input
                          type='number'
                          min={1}
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 py-4'
                          placeholder='Quantity'
                          value={selectedProduct?.quantity}
                          onChange={(e) => handleInputChange(index, e.target.value, 'quantity')}
                        />
                      </div>
                      <div className={`${index !== 0 ? 'col-lg-1  mt-5' : 'col-lg-1'}`}>
                        <button
                          type='button'
                          className='btn btn-sm fw-bold btn-primary  py-4 mt-1'
                          onClick={handleAddCheck}
                        >
                          +
                        </button>
                      </div>
                      {index !== 0 && (
                        <div className='col-lg-1 mt-5'>
                          <button
                            type='button'
                            className='btn btn-sm fw-bold btn-primary  py-4 mt-1'
                            onClick={() => handleRemoveCheck(index)}
                          >
                            -
                          </button>
                        </div>
                      )}
                    </>
                  ))}
                </div> */}
                <div className='row mb-6'>
                  <label className='col-lg-2 col-form-label required fw-bold fs-6'>
                    Internal Notes
                  </label>

                  <div className='col-lg-10'>
                    <div className='row' style={{maxHeight: '300px', overflowY: 'auto'}}>
                      <div className='col-lg-12 fv-row'>
                        <JoditEditor
                          ref={editorRef}
                          value={editorData}
                          onBlur={(newContent) => setEditorData(newContent)}
                          //onChange={(newContent) => setEditorData(`${editorData} ${newContent}`)}  setEditorData(newContent.replace(/<[^>]+>/g, '').trim())
                        />
                      </div>
                    </div>
                    {formik.touched.internalNotes && formik.errors.internalNotes && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.internalNotes}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-2 col-form-label required fw-bold fs-6'>
                    External Notes
                  </label>

                  <div className='col-lg-10'>
                    <div className='row' style={{maxHeight: '300px', overflowY: 'auto'}}>
                      <div className='col-lg-12 fv-row'>
                        <JoditEditor
                          ref={externalNotesEditorRef}
                          value={externalNotesEditorData}
                          onBlur={(newContent) => setexternalNotesEditorData(newContent)}
                          //onChange={(newContent) => setEditorData(`${editorData} ${newContent}`)}  setEditorData(newContent.replace(/<[^>]+>/g, '').trim())
                        />
                      </div>
                    </div>
                    {formik.touched.externalNotes && formik.errors.externalNotes && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.externalNotes}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='card-footer d-flex justify-content-end py-6 px-9 '>
                <button
                  type='submit'
                  className='btn btn-primary'
                  data-bs-dismiss='modal'
                  disabled={
                    // selectedProducts.some(
                    //   (someproduct) =>
                    //     someproduct?.name === undefined ||
                    //     someproduct?.quantity === 'NaN' ||
                    //     someproduct?.quantity?.length < 0
                    // ) ||
                    !formik.isValid ||
                    formik.values.internalNotes.length === 0 ||
                    formik.values.externalNotes.length === 0
                  }
                  //   disabled={isLoadingCreateProduct || isLoadingUpdateProduct}
                  // {...(isSuccessupdateService && {'data-bs-dismiss': 'modal'})}
                >
                  Submit
                  {/* {!isLoadingCreateProduct &&
                    !isLoadingUpdateProduct &&
                    (product ? 'Update' : 'Add')}
                  {(isLoadingCreateProduct || isLoadingUpdateProduct) && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )} */}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export {ServiceProductsForm}
