/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {IconUserModel} from '../../../../app/modules/profile/ProfileModels'
import {KTIcon} from '../../../../_metronic/helpers/'
import {useDataContext} from '../../../modules/data'
import useDeleteWorkshop from '../hooks/use-delete-workshop'
import Swal from 'sweetalert2'
import {useAuth} from 'app/modules/auth'
import {USER_ROLE} from 'app/constants/constants'

type Props = {
  workshop: any
  icon: string
  badgeColor: string
  users?: Array<IconUserModel>
}

const WorkshopCard: FC<Props> = ({workshop, icon, badgeColor, users = undefined}) => {
  const {currentUser} = useAuth()
  const {setWorkshop, setChecks} = useDataContext()
  const {deleteWorkshop, isLoadingWorkshop} = useDeleteWorkshop()

  const handleWorkshopDelete = () => {
    Swal.fire({
      title: 'Delete Workshop',
      showDenyButton: true,
      confirmButtonText: 'Yes',
      icon: 'warning',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteWorkshop(workshop?._id ?? '', {
          onSuccess: () => {
            Swal.fire({
              title: 'Success',
              text: 'Workshop Deleted SuccessFully',
              icon: 'success',
            })
          },
          onError: () => {
            Swal.fire({
              title: 'Error',
              text: 'Workshop Deleted SuccessFully',
              icon: 'error',
            })
          },
        })
      }
    })
  }
  return (
    <div className='card border border-2 border-gray-300 border-hover'>
      <div className='card-header border-0 pt-9'>
        <div className='card-title m-0'>
          <div className='symbol symbol-50px w-50px bg-light'>
            <img src={workshop?.image} alt='card2' className='p-3' />
          </div>
        </div>

        <div className='card-toolbar'>
          <span className={`badge badge-light-${badgeColor} fw-bolder me-auto px-4 py-3`}>
            {workshop.website.length > 15
              ? workshop.website.substring(0, 15) + '...'
              : workshop.website}
          </span>
        </div>
      </div>

      <div className='card-body p-9'>
        <div
          // data-bs-toggle='modal'
          // data-bs-target='#kt_modal_workshop_add'
          // onClick={() => {
          //   setWorkshop(workshop)
          //   setChecks(workshop.checks)
          // }}
          className='fs-3 fw-bolder text-dark'
        >
          {workshop.name}
        </div>

        <p className='text-gray-400 fw-bold fs-5 mt-1 mb-7'>{workshop.address}</p>

        <div className='d-flex flex-wrap mb-5'>
          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-6 me-7 mb-6'>
            <div className='fs-6 text-gray-800 fw-bolder'>{workshop.phoneNumber}</div>
            <div className='fw-bold text-gray-400'>Phone Number</div>
          </div>

          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-6 mb-6 me-7 mb-6'>
            <div className='fs-6 text-gray-800 fw-bolder'>{workshop.email}</div>
            <div className='fw-bold text-gray-400'>Email</div>
          </div>
          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-6 mb-6'>
            <div className='fs-6 text-gray-800 fw-bolder'>
              {workshop?.checks ? workshop?.checks.join(',') : null}
            </div>
            <div className='fw-bold text-gray-400'>Workshop Services</div>
          </div>
        </div>
        {/* <div className='float-end '>
          <button
            className='btn btn-icon btn-circle btn-success'
            onClick={() => {
              setWorkshop(workshop)
              setChecks(workshop.checks)
              navigate(`/workshop/${workshop._id}`)
            }}
            // onClick={(e) => {
            //   e.preventDefault()
            //   navigate(`/workshop/${workshop._id}`)
            // }}
          >
            <a className='fas fa-arrow-right text-white'></a>

            
          </button>
        </div> */}
        <div className='float-end me-5'>
          <button
            className='btn btn-icon btn-circle btn-success'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_workshop_add'
            onClick={() => {
              setWorkshop(workshop)
              setChecks(workshop.checks)
            }}
            // onClick={(e) => {
            //   e.preventDefault()
            //   navigate(`/workshop/${workshop._id}`)
            // }}
          >
            <KTIcon iconName='pencil' className='fs-3' />
            {/* <i className='fas fa-arrow-right'></i> */}
          </button>
          {currentUser &&
            currentUser?.data &&
            currentUser?.data?.user &&
            currentUser?.data?.user?.role === USER_ROLE.SUPER_USER && (
              <button
                className='ms-2 btn btn-icon btn-circle btn-danger'
                disabled={isLoadingWorkshop}
                onClick={handleWorkshopDelete}
              >
                <KTIcon iconName='trash' className='fs-3' />
                {/* <i className='fas fa-arrow-right'></i> */}
              </button>
            )}
        </div>
      </div>
    </div>
  )
}

export {WorkshopCard}
