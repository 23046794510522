import {useQuery} from 'react-query'

import {QUERIES_KEYS} from './../../../../helpers/crud-helpers/consts'
import {getProducts, getProductsByWorkshopId} from '../_request'
import useRequestQuery from '../../../../helpers/crud-helpers/hooks/use-request-query'

function useProductByWorkshopId(id) {
  const {data, isLoading, isFetching} = useQuery(
    QUERIES_KEYS.PRODUCT_LIST,
    () => getProductsByWorkshopId(id),
    {
      select: (res) => res.data,
      refetchOnWindowFocus: false,
    }
  )

  return {
    productsData: data,
    isLoadingUsers: isLoading || isFetching,
  }
}

export default useProductByWorkshopId
