import {useMutation, useQueryClient} from 'react-query'

import {QUERIES_KEYS} from '../../../../helpers/crud-helpers/consts'
import {IUser} from '../_models'
import {createUser} from '../_requests'
import {AxiosError} from 'axios'

function useCreateUser() {
  const queryClient = useQueryClient()

  const {mutate, error, isLoading, isError, isSuccess} = useMutation(
    (body: IUser) => createUser(body),
    {
      // ✅ Show success message
      onError: (error: unknown) => {
        return error instanceof AxiosError && error?.response?.data?.message
      },
      onSuccess: () => {
        console.log('User created successfully ')
        queryClient.invalidateQueries(QUERIES_KEYS.USERS_LIST)
      },
    }
  )

  return {
    createUser: mutate,
    isLoadingCreateUser: isLoading,
    isErrorCreateUser: isError,
    isSuccessCreateUser: isSuccess,
    errorCreatingUser: error instanceof AxiosError && error?.response?.data?.message,
  }
}

export default useCreateUser
