import {useEffect} from 'react'
import {Card2} from '../../../_metronic/partials/content/cards/Card2'
import {MixedWidget5} from '../../../_metronic/partials/widgets'
import {useDataContext} from '../../modules/data'
import {WorkshopCard} from './components/Wokshop-card'
import WorkshopsTable from './components/workshopTable'
import useWorkshop from './hooks/use-workshop'
import {CustomPageLoader} from 'app/modules/auth/components/CustomPageLoader'

function Workshops() {
  const {workshopsData, isLoadingWorkshops} = useWorkshop()

  const {setWorkshops} = useDataContext()

  useEffect(() => {
    if (workshopsData) {
      setWorkshops(workshopsData)
    }
  }, [workshopsData, setWorkshops])

  return isLoadingWorkshops ? (
    <CustomPageLoader />
  ) : (
    <>
      <WorkshopsTable workshops={workshopsData} />
    </>
  )
}

export default Workshops
