import clsx from 'clsx'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {
  HeaderUserMenu,
  //Search,
  ThemeModeSwitcher,
} from '../../../partials'
import {useLayout} from '../../core'
import {CSSProperties, useState} from 'react'
import {Dropdown, Menu, MenuProps, Space} from 'antd'
import {DownOutlined} from '@ant-design/icons'
import {useAuth} from 'app/modules/auth'
import {USER_ROLE} from 'app/constants/constants'
import {Link} from 'react-router-dom'

const itemClass = 'ms-1 ms-md-4'
// const btnClass =
//   'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px'
const userAvatarClass = 'symbol-35px'
const btnIconClass = 'fs-2'
function getRole(gotRole: string) {
  switch (gotRole) {
    case USER_ROLE.JUNIOR_MECHANIC:
      return 'Junior Mechanic'
    case USER_ROLE.WORKSHOP_OWNER:
      return 'Workshop Owner'
    case USER_ROLE.SERVICE_MANAGER:
      return 'Service Manager'
    case USER_ROLE.SENIOR_MECHANIC:
      return 'Senior Mechanic'
    case USER_ROLE.VEHICLE_OWNER:
      return 'Vehicle Owner'
    default:
      return 'Unknown'
  }
}
const menuStyles: CSSProperties = {
  width: '275px',
  backgroundColor: '#fff',
  borderRadius: '0.42rem',
  position: 'absolute',
  padding: '16px',
  fontSize: '1.25rem',
  marginTop: '250px',
  marginLeft: '-20px',
  // Add top margin
  boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)', // Add box shadow for better appearance
}

const menuItemStyles: CSSProperties = {
  marginTop: '10px',
  marginLeft: '10px',
  padding: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: '#000',
  textDecoration: 'none',
  fontSize: '1rem',
}
const menuWidth = 275
const separatorStyles = {
  margin: '8px 0',
  borderBottom: '1px solid #000',
}

const linkStyles = {
  color: '#000', // Black color
  textDecoration: 'none', // Remove underline
  transition: 'color 0.3s, transform 0.3s', // Add transition for smooth effect
}

const Navbar = () => {
  const {currentUser, logout} = useAuth()

  const menu = (
    <Menu style={{width: `${menuWidth}px`}}>
      {/* User details */}

      <div className='cursor-default ' style={menuItemStyles}>
        <div>
          <div style={{fontWeight: 'bold', fontSize: '1.25rem'}}>{currentUser?.data.user.name}</div>
          <div style={{fontSize: '1rem', color: '#6c757d'}}>{currentUser?.data.user.email}</div>
        </div>
      </div>

      <div style={menuItemStyles}>
        <span>Role:</span>
        <span>{getRole(currentUser?.data.user.role)}</span>
      </div>

      <Menu.Divider style={separatorStyles} />

      {/* Menu items */}
      <Menu.Item>
        <Link to='/profile' style={linkStyles}>
          My Profile
        </Link>
      </Menu.Item>
      <Menu.Item className='mb-3'>
        <a onClick={logout}>Sign Out</a>
      </Menu.Item>
    </Menu>
  )
  return (
    <div className='app-navbar flex-shrink-0 cursor-pointer'>
      <div className={clsx('app-navbar-item', itemClass)}>
        {/* <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          onClick={handleDropdownToggle}
        >
          <img src={toAbsoluteUrl('/media/logos/arrowdown.png')} alt='' />
        </div> */}
        <Dropdown overlay={menu} trigger={['click']}>
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              <DownOutlined style={{color: 'black', fontSize: '1.5rem'}} />
            </Space>
          </a>
        </Dropdown>
      </div>
    </div>
  )
}

export {Navbar}
