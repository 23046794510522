import axios from 'axios'
import {IProduct, IProductsQueryResponse} from './_models'

const PRODUCTS = '/product'

export async function getProducts(queryString?: string): Promise<IProductsQueryResponse | any> {
  let result = await axios.get(`${PRODUCTS}`).then((response) => response.data)
  // console.log('qss', queryString, 'result got by getproducts is ', result)
  return result
}
//getproduct by workshop id hook
export async function getProductsByWorkshopId(id?: string): Promise<IProductsQueryResponse | any> {
  let result = await axios.get(`${PRODUCTS}?workshop=${id}`).then((response) => response.data)
  //console.log('result got by getproductsbyid is ', result)
  return result
}
export async function createProduct(body: IProduct): Promise<IProductsQueryResponse | undefined> {
  let result = await axios.post(PRODUCTS, body).then((response) => response.data)
  // console.log('add prod result is =>', result)
  return result
}
export async function updateProduct(
  body: IProduct,
  id: string
): Promise<IProductsQueryResponse | undefined> {
  return await axios.patch(`${PRODUCTS}/${id}`, body)
}

export async function deleteProduct(id: string) {
  return await axios.delete(`${PRODUCTS}/${id}`)
}
