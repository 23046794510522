import React, {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {IVehicle} from '../core/_models'
import {Card5} from '_metronic/partials/content/cards/Card5'
import {useDataContext} from 'app/modules/data'
import {VehicleServiceTable} from './VehicleServiceTable'
import useServiceByVehicleId from 'app/pages/service/hooks/use-get-service-by-vehicle'
import {CustomPageLoader} from 'app/modules/auth/components/CustomPageLoader'
const dummyServices = [
  {
    _id: '65d59ecc4386028e29f9e8e2',
    vehicle: {
      _id: '65d49b35d25a5c68036b66ae',
      owner: '65d49a96d25a5c68036b6664',
      addedBy: '65d49a96d25a5c68036b6664',
      registrationNumber: 'use-89',
      make: 'VTI honda',
      model: 'st 4',
      year: 2020,
      type: 'car',
      startingOdoMeter: 0,
      vehicleIdentificationNumber: 76767676767676,
      isDeleted: false,
      createdAt: '2024-02-20T12:29:41.653Z',
      updatedAt: '2024-02-20T12:29:41.653Z',
      __v: 0,
    },
    vehicleIdentificationNumber: '76767676767676',
    vehicleOwner: {
      _id: '65d49a96d25a5c68036b6664',
      name: 'vehicle owner',
      email: 'vehicle-owner@yopmail.com',
      role: 'VEHICLE_OWNER',
      emailVerificationToken: 254359,
      emailVerificationTokenExpiresAt: '2024-02-20T12:30:48.595Z',
      emailVerified: false,
      isBlocked: false,
      isActive: true,
      isDeleted: false,
      createdAt: '2024-02-20T12:27:02.204Z',
      updatedAt: '2024-02-20T12:27:02.204Z',
      __v: 0,
    },
    otherMechanics: [],
    workshop: {
      _id: '65d49819b9a0d30c7a756793',
      owner: '65d49675b9a0d30c7a7566f6',
      name: 'assans',
      address: 'bn, isb',
      phoneNumber: '+92345345345',
      email: 'asd@gmail.com',
      website: 'https://www.google.com/',
      checks: ['check oil', 'check engine', 'check windshield'],
      services: [],
      isDeleted: false,
      createdAt: '2024-02-20T12:16:25.868Z',
      updatedAt: '2024-02-21T10:11:05.127Z',
      __v: 0,
    },
    vehicleOwnerNotes: '<p>check notes by vehicle owner added dummy</p>',
    timeLogs: [],
    serviceLogs: [],
    checks: ['check oil'],
    products: [],
    contact: '12341234123',
    status: 1,
    isDeleted: false,
    createdAt: '2024-02-21T06:57:16.763Z',
    updatedAt: '2024-02-21T07:16:43.330Z',
  },
  {
    _id: '65d59e004386028e29f9e85c',
    vehicle: {
      _id: '65d49b35d25a5c68036b66ae',
      owner: '65d49a96d25a5c68036b6664',
      addedBy: '65d49a96d25a5c68036b6664',
      registrationNumber: 'use-89',
      make: 'VTI honda',
      model: 'st 4',
      year: 2020,
      type: 'car',
      startingOdoMeter: 0,
      vehicleIdentificationNumber: 76767676767676,
      isDeleted: false,
      createdAt: '2024-02-20T12:29:41.653Z',
      updatedAt: '2024-02-20T12:29:41.653Z',
      __v: 0,
    },
    vehicleIdentificationNumber: '76767676767676',
    vehicleOwner: {
      _id: '65d49a96d25a5c68036b6664',
      name: 'vehicle owner',
      email: 'vehicle-owner@yopmail.com',
      role: 'VEHICLE_OWNER',
      emailVerificationToken: 254359,
      emailVerificationTokenExpiresAt: '2024-02-20T12:30:48.595Z',
      emailVerified: false,
      isBlocked: false,
      isActive: true,
      isDeleted: false,
      createdAt: '2024-02-20T12:27:02.204Z',
      updatedAt: '2024-02-20T12:27:02.204Z',
      __v: 0,
    },
    otherMechanics: [],
    workshop: {
      _id: '65d49819b9a0d30c7a756793',
      owner: '65d49675b9a0d30c7a7566f6',
      name: 'assans',
      address: 'bn, isb',
      phoneNumber: '+92345345345',
      email: 'asd@gmail.com',
      website: 'https://www.google.com/',
      checks: ['check oil', 'check engine', 'check windshield'],
      services: [],
      isDeleted: false,
      createdAt: '2024-02-20T12:16:25.868Z',
      updatedAt: '2024-02-21T10:11:05.127Z',
      __v: 0,
    },
    vehicleOwnerNotes: '<p>asd</p>',
    timeLogs: [],
    serviceLogs: [],
    checks: ['check oil'],
    products: [],
    contact: '23432423423',
    status: 2,
    isDeleted: false,
    createdAt: '2024-02-21T06:53:52.386Z',
    updatedAt: '2024-02-21T06:53:55.886Z',
  },
  {
    _id: '65d5941d7de979a14b2ea594',
    vehicle: {
      _id: '65d49b35d25a5c68036b66ae',
      owner: '65d49a96d25a5c68036b6664',
      addedBy: '65d49a96d25a5c68036b6664',
      registrationNumber: 'use-89',
      make: 'VTI honda',
      model: 'st 4',
      year: 2020,
      type: 'car',
      startingOdoMeter: 0,
      vehicleIdentificationNumber: 76767676767676,
      isDeleted: false,
      createdAt: '2024-02-20T12:29:41.653Z',
      updatedAt: '2024-02-20T12:29:41.653Z',
      __v: 0,
    },
    vehicleIdentificationNumber: '76767676767676',
    vehicleOwner: {
      _id: '65d49a96d25a5c68036b6664',
      name: 'vehicle owner',
      email: 'vehicle-owner@yopmail.com',
      role: 'VEHICLE_OWNER',
      emailVerificationToken: 254359,
      emailVerificationTokenExpiresAt: '2024-02-20T12:30:48.595Z',
      emailVerified: false,
      isBlocked: false,
      isActive: true,
      isDeleted: false,
      createdAt: '2024-02-20T12:27:02.204Z',
      updatedAt: '2024-02-20T12:27:02.204Z',
      __v: 0,
    },
    otherMechanics: [],
    workshop: {
      _id: '65d49819b9a0d30c7a756793',
      owner: '65d49675b9a0d30c7a7566f6',
      name: 'assans',
      address: 'bn, isb',
      phoneNumber: '+92345345345',
      email: 'asd@gmail.com',
      website: 'https://www.google.com/',
      checks: ['check oil', 'check engine', 'check windshield'],
      services: [],
      isDeleted: false,
      createdAt: '2024-02-20T12:16:25.868Z',
      updatedAt: '2024-02-21T10:11:05.127Z',
      __v: 0,
    },
    vehicleOwnerNotes: '<p>TEST NOTE ADDED</p>',
    timeLogs: [
      {
        startTime: '2024-02-21T06:17:30.254Z',
        endTime: '2024-02-21T06:17:30.254Z',
        reason: 'Service Done',
        notes: 'Work completed, awaiting approval from senior mechanic.',
      },
    ],
    serviceLogs: [],
    checks: ['check engine', 'check windshield'],
    products: [
      {
        _id: '65d58524041c034eae020581',
        workshop: '65d49819b9a0d30c7a756793',
        name: 'tire cover',
        type: 'car',
        brand: 'zxds',
        units: 10,
        cost: 20,
        retailPrice: 30,
        isDeleted: false,
        createdAt: '2024-02-21T05:07:48.585Z',
        updatedAt: '2024-02-21T05:07:48.585Z',
      },
    ],
    contact: '45674567456',
    status: 7,
    isDeleted: false,
    createdAt: '2024-02-21T06:11:41.780Z',
    updatedAt: '2024-02-21T06:17:30.255Z',
    fixedBy: {
      _id: '65d58466041c034eae0204ff',
      name: 'saif sm',
      email: 'saifsm@yopmail.com',
      workshop: '65d49819b9a0d30c7a756793',
      role: 'SENIOR_MECHANIC',
      emailVerificationTokenExpiresAt: '2024-02-21T04:47:27.018Z',
      emailVerified: false,
      isBlocked: false,
      isActive: true,
      isDeleted: false,
      createdAt: '2024-02-21T05:04:38.062Z',
      updatedAt: '2024-02-21T05:04:38.062Z',
      __v: 0,
    },
    currentTimeLapse: null,
  },
  {
    _id: '65d5872e041c034eae0206be',
    vehicle: {
      _id: '65d49b35d25a5c68036b66ae',
      owner: '65d49a96d25a5c68036b6664',
      addedBy: '65d49a96d25a5c68036b6664',
      registrationNumber: 'use-89',
      make: 'VTI honda',
      model: 'st 4',
      year: 2020,
      type: 'car',
      startingOdoMeter: 0,
      vehicleIdentificationNumber: 76767676767676,
      isDeleted: false,
      createdAt: '2024-02-20T12:29:41.653Z',
      updatedAt: '2024-02-20T12:29:41.653Z',
      __v: 0,
    },
    vehicleIdentificationNumber: '76767676767676',
    vehicleOwner: {
      _id: '65d49a96d25a5c68036b6664',
      name: 'vehicle owner',
      email: 'vehicle-owner@yopmail.com',
      role: 'VEHICLE_OWNER',
      emailVerificationToken: 254359,
      emailVerificationTokenExpiresAt: '2024-02-20T12:30:48.595Z',
      emailVerified: false,
      isBlocked: false,
      isActive: true,
      isDeleted: false,
      createdAt: '2024-02-20T12:27:02.204Z',
      updatedAt: '2024-02-20T12:27:02.204Z',
      __v: 0,
    },
    otherMechanics: [],
    workshop: {
      _id: '65d49819b9a0d30c7a756793',
      owner: '65d49675b9a0d30c7a7566f6',
      name: 'assans',
      address: 'bn, isb',
      phoneNumber: '+92345345345',
      email: 'asd@gmail.com',
      website: 'https://www.google.com/',
      checks: ['check oil', 'check engine', 'check windshield'],
      services: [],
      isDeleted: false,
      createdAt: '2024-02-20T12:16:25.868Z',
      updatedAt: '2024-02-21T10:11:05.127Z',
      __v: 0,
    },
    vehicleOwnerNotes: '<p>need new oil<br></p>',
    timeLogs: [
      {
        startTime: '2024-02-21T05:24:43.465Z',
        endTime: '2024-02-21T05:24:43.465Z',
        reason: 'Service Completed',
        notes:
          'Service is currently on hold as it requires additional resources such as parts or external expertise.',
      },
      {
        startTime: '2024-02-21T05:44:40.363Z',
        endTime: '2024-02-21T05:44:40.363Z',
        reason: 'Service Completed',
        notes:
          'Service is currently on hold as it requires additional resources such as parts or external expertise.',
      },
      {
        startTime: '2024-02-21T05:54:05.125Z',
        endTime: '2024-02-21T05:54:05.125Z',
        reason: 'Service Done',
        notes: 'Work completed, awaiting approval from senior mechanic.',
      },
    ],
    serviceLogs: [],
    checks: ['check oil'],
    products: [
      {
        _id: '65d58585041c034eae0205a7',
        workshop: '65d49819b9a0d30c7a756793',
        name: 'seat cover',
        type: 'car',
        brand: 'aser',
        units: 20,
        cost: 50,
        retailPrice: 59,
        isDeleted: false,
        createdAt: '2024-02-21T05:09:25.266Z',
        updatedAt: '2024-02-21T05:09:25.266Z',
      },
    ],
    contact: '345643634666',
    status: 3,
    isDeleted: false,
    createdAt: '2024-02-21T05:16:30.747Z',
    updatedAt: '2024-02-21T05:55:10.040Z',
    fixedBy: {
      _id: '65d582d8041c034eae02045a',
      name: 'JMsaif',
      email: 'JMsaif@yopmail.com',
      workshop: '65d49819b9a0d30c7a756793',
      role: 'JUNIOR_MECHANIC',
      emailVerificationTokenExpiresAt: '2024-02-21T04:47:27.018Z',
      emailVerified: false,
      isBlocked: false,
      isActive: true,
      isDeleted: false,
      createdAt: '2024-02-21T04:58:00.010Z',
      updatedAt: '2024-02-21T04:58:00.010Z',
      __v: 0,
    },
    currentTimeLapse: null,
  },

  {
    _id: '65d49cffd25a5c68036b67a8',
    vehicle: {
      _id: '65d49b35d25a5c68036b66ae',
      owner: '65d49a96d25a5c68036b6664',
      addedBy: '65d49a96d25a5c68036b6664',
      registrationNumber: 'use-89',
      make: 'VTI honda',
      model: 'st 4',
      year: 2020,
      type: 'car',
      startingOdoMeter: 0,
      vehicleIdentificationNumber: 76767676767676,
      isDeleted: false,
      createdAt: '2024-02-20T12:29:41.653Z',
      updatedAt: '2024-02-20T12:29:41.653Z',
      __v: 0,
    },
    vehicleIdentificationNumber: '76767676767676',
    vehicleOwner: {
      _id: '65d49a96d25a5c68036b6664',
      name: 'vehicle owner',
      email: 'vehicle-owner@yopmail.com',
      role: 'VEHICLE_OWNER',
      emailVerificationToken: 254359,
      emailVerificationTokenExpiresAt: '2024-02-20T12:30:48.595Z',
      emailVerified: false,
      isBlocked: false,
      isActive: true,
      isDeleted: false,
      createdAt: '2024-02-20T12:27:02.204Z',
      updatedAt: '2024-02-20T12:27:02.204Z',
      __v: 0,
    },
    otherMechanics: [],
    workshop: {
      _id: '65d497c837c228f82e7a62a8',
      owner: '65d4979b37c228f82e7a6295',
      name: 'Keely Frost',
      address: 'Nisi officia elit f',
      phoneNumber: '123456789',
      email: 'gizevavy@mailinator.com',
      website: 'https://www.sifamikejipeku.org',
      checks: ['Hic dolor tempor fug', '121dsa2d1', 'sadad'],
      services: [],
      isDeleted: false,
      createdAt: '2024-02-20T12:15:04.544Z',
      updatedAt: '2024-02-20T12:15:04.544Z',
      __v: 0,
    },
    vehicleOwnerNotes: '<p>asbxuyaxvyasvxagxvasghxvahgxvhsagxv<br></p>',
    timeLogs: [
      {
        startTime: '2024-02-20T12:40:38.876Z',
        endTime: '2024-02-20T12:40:38.876Z',
        reason: 'Service Done',
        notes: 'Work completed, awaiting approval from senior mechanic.',
      },
    ],
    serviceLogs: [],
    checks: ['121dsa2d1', 'Hic dolor tempor fug'],
    products: [],
    contact: '052752151561',
    status: 8,
    isDeleted: false,
    createdAt: '2024-02-20T12:37:19.136Z',
    updatedAt: '2024-02-20T12:42:21.763Z',
    fixedBy: {
      _id: '65d49a47d25a5c68036b663e',
      name: 'Myles Macdonald',
      email: 'zifigok@yopmail.com',
      workshop: '65d497c837c228f82e7a62a8',
      role: 'JUNIOR_MECHANIC',
      emailVerificationTokenExpiresAt: '2024-02-20T12:30:48.595Z',
      emailVerified: false,
      isBlocked: false,
      isActive: true,
      isDeleted: false,
      createdAt: '2024-02-20T12:25:43.930Z',
      updatedAt: '2024-02-20T12:35:45.577Z',
      __v: 0,
      passwordResetToken: 172980,
      passwordResetTokenExpiresAt: '2024-02-20T13:05:11.724Z',
    },
    currentTimeLapse: null,
  },
]
export const VehicleDetails = () => {
  const state = useLocation()
  const [stateVehicle, setStateVehicle] = useState<IVehicle>()
  const {vehicle} = useDataContext()
  const {servicesData, isLoadingServices, isSuccessgetServices} = useServiceByVehicleId(
    vehicle?._id
  )

  useEffect(() => {
    //console.log('state', state)
    if (state.state) {
      setStateVehicle((state as any).state)
    }
  }, [state])
  return (
    <div>
      <div className='card-header border-0 pt-5 mb-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Vehicle Details</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 new products</span> */}
        </h3>
      </div>
      <div className='row g-6 g-xl-9'>
        <div className='col-sm-6 col-xl-4'>
          <Card5
            image='fas fa-car'
            title='Make'
            description='$500.00'
            status='down'
            statusValue={40.5}
            statusDesc='more impressions'
            progress={0.5}
            progressType='MRR'
            vehicleValue={vehicle?.make}
          />
        </div>
        <div className='col-sm-6 col-xl-4'>
          <Card5
            image='fas fa-th-large'
            title='Model'
            description='807k'
            status='up'
            statusValue={17.62}
            statusDesc='Followers growth'
            progress={5}
            progressType='New trials'
            vehicleValue={vehicle?.model}
          />
        </div>
        <div className='col-sm-6 col-xl-4'>
          <Card5
            image='far fa-calendar-alt'
            title='year'
            description='1,073'
            status='down'
            statusValue={10.45}
            statusDesc='Less comments than usual'
            progress={40}
            progressType='Impressions'
            vehicleValue={vehicle?.year}
          />
        </div>
        <div className='col-sm-6 col-xl-4'>
          <Card5
            image='fas fa-sort-numeric-up-alt'
            title='Registration Number'
            description='97'
            status='up'
            statusValue={26.1}
            statusDesc='More posts'
            progress={10}
            progressType='Spend'
            vehicleValue={vehicle?.registrationNumber}
          />
        </div>
        <div className='col-sm-6 col-xl-4'>
          <Card5
            image='
fas fa-caravan'
            title='Vehicle Type'
            description='4,109'
            status='down'
            statusValue={32.8}
            statusDesc='Less contributions'
            progress={40}
            progressType='Dispute'
            vehicleValue={vehicle?.type}
          />
        </div>
        <div className='col-sm-6 col-xl-4'>
          <Card5
            image='fas fa-info-circle'
            title='Vehicle Identification Number'
            description='354'
            status='up'
            statusValue={29.45}
            statusDesc='Subscribers growth'
            progress={40}
            progressType='Subscribers'
            vehicleValue={vehicle?.vehicleIdentificationNumber}
          />
        </div>
      </div>
      {isLoadingServices ? <CustomPageLoader /> : <VehicleServiceTable services={servicesData} />}
    </div>
  )
}
