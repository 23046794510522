import {KTIcon} from '_metronic/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import React, {useEffect, useState, useRef} from 'react'
import {useDataContext} from 'app/modules/data'
import Swal from 'sweetalert2'
import useUpdateService from '../../hooks/use-update-service'
import {Form} from 'react-bootstrap'
import moment from 'moment'

const productDetailsSchema = Yup.object().shape({
  scheduledFor: Yup.string().required('Date is required'),
})

const ServiceDateForm: React.FC = () => {
  const {service} = useDataContext()
  const {
    updateService,
    isLoadingupdateService,
    errorupdateService,
    isErrorupdateService,
    isSuccessupdateService,
  } = useUpdateService()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      scheduledFor: moment(Date.now()).format('YYYY-MM-DD'),
    },
    validationSchema: productDetailsSchema,
    onSubmit: async (values) => {
      await updateService({
        body: {scheduledFor: formik.values.scheduledFor, status: 1},
        id: service?._id,
      })
      //console.log('values ', values, service?._id)
      if (isErrorupdateService) {
        Swal.fire({
          title: 'Error',
          text: 'Error Updating date. Try again',
          icon: 'error',
          confirmButtonText: 'Ok',
        }).then((result) => {
          console.log('Fire erro result ', result)
        })
      } else if (isSuccessupdateService) {
        Swal.fire({
          title: 'Success',
          text: 'updated Successfully!',
          icon: 'success',
          confirmButtonText: 'Done',
        }).then((result) => {
          formik.resetForm()
        })
      }
    },
  })

  return (
    <div className='modal fade' id='kt_modal_serviceDate_selection' aria-hidden='true'>
      <div className='modal-dialog mw-750px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-space-between'>
            <h3 className='text-center'>Schedule Service</h3>
            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              data-bs-dismiss='modal'
              onClick={(e) => {
                e.preventDefault()
                formik.resetForm()
              }}
              style={{borderRadius: '50%', border: '1px solid'}}
            >
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <form onSubmit={formik.handleSubmit} noValidate className='form'>
              <div className='card-body border-top p-5'>
                <div className='row mb-6'>
                  <label className='col-lg-2 col-form-label required fw-bold fs-6'>Date</label>

                  <div className='col-lg-10'>
                    <Form.Group controlId='exampleForm.DatePicker'>
                      <Form.Control type='date' {...formik.getFieldProps('scheduledFor')} />
                    </Form.Group>
                  </div>
                </div>
              </div>
              <div className='card-footer d-flex justify-content-end py-6 px-5 '>
                <button
                  type='submit'
                  className='btn btn-primary'
                  data-bs-dismiss='modal'
                  disabled={isLoadingupdateService || !formik.isValid}
                  // {...(isSuccessupdateService && {'data-bs-dismiss': 'modal'})}
                >
                  Submit
                  {isLoadingupdateService && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export {ServiceDateForm}
