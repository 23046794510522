import React, {useState, useEffect} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'

import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useDataContext} from '../../../modules/data'
import Swal from 'sweetalert2'
import 'bootstrap/js/dist/modal'
import useWorkshop from 'app/pages/workshop/hooks/use-workshop'
import {useAuth} from 'app/modules/auth'
import useCreateServiceCheckList from '../core/hooks/use-add-service-checklist'
import useUpdateServiceCheckList from '../core/hooks/use-update-service-checklist'

const productDetailsSchema = Yup.object().shape({
  name: Yup.string().required('Checklist name is required'),

  checks: Yup.array().of(Yup.string().required('Check is required')).required('Checks Required'),
})

type Props = {
  setShowModal: any
}

const ServiceCheckListForm: React.FC<Props> = () => {
  const {currentUser} = useAuth()
  // console.log(currentUser?.data)
  const {workshopsData} = useWorkshop()
  const {
    createServiceCheckList,
    isErrorCreateServiceCheckList,
    isSuccessCreateServiceCheckList,
    isLoadingCreateServiceCheckList,
  } = useCreateServiceCheckList()
  const {
    updateServiceCheckList,
    isErrorUpdateServiceCheckList,
    isSuccessUpdateServiceCheckList,
    isLoadingUpdateServiceCheckList,
  } = useUpdateServiceCheckList()
  const {serviceCheckListId, setServiceCheckListId, checks, setChecks} = useDataContext()

  let initialValues = serviceCheckListId || {
    name: '',
    workshop: '',
    user: currentUser?.data?.user?._id,
    checks: [''],
  }

  // console.log('workshopchecks are ', checks)
  const [StateChecks, setStateChecks] = useState(checks.length > 0 ? checks : [''])

  useEffect(() => {
    // console.log(
    //   'success is ',
    //   isSuccessUpdateWorkshop,
    //   isSuccessCreateWorkshop,
    //   isErrorUpdateWorkshop,
    //   isErrorCreateWorkshop
    // )
    if (isSuccessUpdateServiceCheckList || isSuccessCreateServiceCheckList) {
      Swal.fire({
        title: 'Success',
        text: serviceCheckListId ? 'Updated Successfully!' : 'Created Successfully!',
        icon: 'success',
        confirmButtonText: 'Ok',
      }).then((result) => {
        setServiceCheckListId(undefined)
        setChecks([''])
      })
    } else if (isErrorUpdateServiceCheckList || isErrorCreateServiceCheckList) {
      Swal.fire({
        title: 'Error',
        text: serviceCheckListId ? 'Error Updating!' : 'Error Creating!',
        icon: 'error',
        confirmButtonText: 'Ok',
      }).then((result) => {
        setServiceCheckListId(undefined)
        setChecks([''])
        //console.log('in fire then =>', result)
      })
    }
  }, [
    isSuccessCreateServiceCheckList,
    isSuccessUpdateServiceCheckList,
    isErrorUpdateServiceCheckList,
    isErrorCreateServiceCheckList,
  ])
  useEffect(() => {
    setStateChecks(checks)
    //console.log('checks inside effect are ', StateChecks)
  }, [checks])
  const formik = useFormik<any>({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: productDetailsSchema,
    onSubmit: async (values) => {
      console.table(values)
      if (currentUser?.data?.user?.role === 'SERVICE_MANAGER') {
        values.workshop = currentUser?.data?.user?.workshop
      } else {
        // Remove the workshop value if it is not required
        if (!values.workshop) {
          delete values.workshop
        }
      }
      //console.log('values => ', values)
      serviceCheckListId
        ? await updateServiceCheckList(values)
        : await createServiceCheckList(values)
    },
  })
  const handleInputChange = (index: number, value: string) => {
    setStateChecks((prevChecks) => {
      const newChecks = [...prevChecks]
      newChecks[index] = value
      return newChecks
    })
  }

  const handleAddCheck = () => {
    setStateChecks((prevChecks) => [...prevChecks, ''])
  }

  const handleRemoveCheck = (index: number) => {
    setStateChecks((prevChecks) => {
      const newChecks = [...prevChecks]
      newChecks.splice(index, 1)
      return newChecks
    })
  }

  return (
    <div className='modal fade' id='kt_modal_servicechecklist_add' aria-hidden='true'>
      <div className='modal-dialog mw-700px '>
        <div className='modal-content '>
          <div className='modal-header pb-0 border-0 justify-content-space-between'>
            <h3 className='text-center'>
              {serviceCheckListId ? 'Update Checklist' : 'Add Checklist'}
            </h3>
            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              data-bs-dismiss='modal'
              style={{borderRadius: '50%', border: '1px solid'}}
              onClick={(e) => {
                e.preventDefault()
                setServiceCheckListId('')
                setChecks([''])
                //  console.log(workshop)
              }}
            >
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='modal-body scroll-y  mx-10 pt-0 pb-15 '>
            <form onSubmit={formik.handleSubmit} noValidate className='form'>
              <div className='card-body border-top py-9 '>
                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                    CheckList Name
                  </label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='Checklist Name'
                          {...formik.getFieldProps('name')}
                          // onChange={(e) => {
                          //   formik.handleChange('name')(e.target.value.trim())
                          // }}
                        />
                        {formik.touched.name && formik.errors.name && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{String(formik.errors.name)}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {currentUser?.data?.user?.role !== 'SERVICE_MANAGER' && (
                  <div className='row mb-6'>
                    <label className='col-lg-3 col-form-label  fw-bold fs-6'>Workshops</label>

                    <div className='col-lg-9'>
                      <div className='row'>
                        <div className='col-lg-12 fv-row'>
                          <select
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            {...formik.getFieldProps('workshop')}
                          >
                            <option value=''>Select Workshop</option>
                            {workshopsData?.map((workshop) => (
                              <option key={workshop._id} value={workshop._id}>
                                {workshop.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div
                  className='row mb-6'
                  onBlur={() => formik.setFieldValue('checks', StateChecks)}
                >
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                    Service Checks
                  </label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <div className='form-control form-control-lg d-flex flex-column'>
                          Add workshop services here
                          <button
                            type='button'
                            className='btn btn-sm fw-bold btn-primary align-self-end'
                            onClick={handleAddCheck}
                          >
                            +
                          </button>
                          {StateChecks?.map((check, index) => (
                            <div className='d-flex align-items-center mt-5' key={index}>
                              <input
                                placeholder='Enter check'
                                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                type='text'
                                value={check}
                                onChange={(e) => handleInputChange(index, e.target.value)}
                              />
                              <span onClick={() => handleRemoveCheck(index)}>
                                <KTIcon iconName='cross' className='fs-1 cursor-pointer' />
                              </span>
                            </div>
                          ))}
                        </div>
                        {formik.touched.checks && formik.errors.checks && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{String(formik.errors.checks)}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='card-footer d-flex justify-content-end '>
                <button
                  id='submitButton'
                  type='submit'
                  className='btn btn-primary'
                  disabled={
                    isLoadingCreateServiceCheckList ||
                    isLoadingUpdateServiceCheckList ||
                    !formik.isValid ||
                    !formik.dirty
                  }
                  // {...(isSuccessUpdateWorkshop || isSuccessCreateWorkshop
                  //   ? null
                  //   : {
                  //       'data-bs-dismiss': 'modal',
                  //     })}
                  {...(isSuccessCreateServiceCheckList ? null : {'data-bs-dismiss': 'modal'})}
                  {...(isSuccessUpdateServiceCheckList ? null : {'data-bs-dismiss': 'modal'})}
                >
                  {(!isLoadingCreateServiceCheckList || isLoadingUpdateServiceCheckList) &&
                    (serviceCheckListId ? 'Update' : 'Add')}
                  {isLoadingCreateServiceCheckList ||
                    (isLoadingUpdateServiceCheckList && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    ))}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export {ServiceCheckListForm}
