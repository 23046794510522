import {useMutation, useQueryClient} from 'react-query'
import {QUERIES_KEYS} from '../../../helpers/crud-helpers/consts'

import {servicePauseResume} from '../core/_requests'
function usePauseResumeService() {
  const queryClient = useQueryClient()

  const {mutate, isLoading, isError, error, isSuccess} = useMutation(
    ({body, id}: {body?: {reason: string}; id: string | undefined}) => {
      // const {_id, ...rest} = body
      return servicePauseResume(body, id)
    },
    {
      // ✅ Show success message
      onSuccess: () => {
        queryClient.invalidateQueries(QUERIES_KEYS.SERVICE_LIST)
      },
    }
  )
  return {
    pauseResumeService: mutate,
    isLoadingpauseResumeService: isLoading,
    isErrorpauseResumeService: isError,
    errorpauseResumeService: error,
    isSuccesspauseResumeService: isSuccess,
  }
}

export default usePauseResumeService
