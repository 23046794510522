import {useMutation, useQueryClient} from 'react-query'
import {QUERIES_KEYS} from '../../../helpers/crud-helpers/consts'
import {IWorkshop} from '../core/_models'
import {createWorkshop} from '../core/_requests'

function useCreateWorkshop() {
  const queryClient = useQueryClient()

  const {mutate, isLoading, isError, isSuccess} = useMutation(
    (body: IWorkshop) => createWorkshop(body),
    {
      onSuccess: () => {
        // console.log('Test created successfully')
        queryClient.invalidateQueries(QUERIES_KEYS.WORKSHOP_LIST)
      },
    }
  )

  return {
    createWorkshop: mutate,
    isLoadingCreateWorkshop: isLoading,
    isErrorCreateWorkshop: isError,
    isSuccessCreateWorkshop: isSuccess,
  }
}

export default useCreateWorkshop
