import axios from 'axios'
import {IUser, IUsersQueryResponse} from './_models'

const USERS = '/users'

export function getUsers(): Promise<IUsersQueryResponse | any> {
  return axios.get(USERS).then((response) => response.data)
}

export async function createUser(body: IUser): Promise<IUsersQueryResponse | undefined> {
  let result = await axios.post(USERS, body).then((response) => response.data)
  // console.log('User error api response ', result)
  return result
}
export async function deleteUser(id: string): Promise<IUsersQueryResponse | undefined> {
  let result = await axios.delete(`USERS/${id}`).then((response) => response.data)
  //  console.log('Add USERS api response ', result)
  return result
}
