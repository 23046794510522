export enum MediaType {
  WORKSHOP = 'workshop',
  USER = 'user',
  VEHICLE = 'vehicle',
  SERVICE = 'service',
}
export interface IPops {
  name: string
  type: MediaType
  url: any
}
