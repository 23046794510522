import {useMutation, useQueryClient} from 'react-query'
import {QUERIES_KEYS} from 'app/helpers/crud-helpers/consts'
import {IUpdateInspection} from '../../core/inspection/_inspectionModels'
import {UpdateInspection} from '../../core/inspection/_inspectionRequests'

function useUpdateInspection() {
  const queryClient = useQueryClient()
  const {mutate, data, isLoading, isError, isSuccess} = useMutation(
    ({body, _id}: {body: IUpdateInspection; _id: string | undefined}) => {
      // const {_id, ...rest} = body
      return UpdateInspection(body, _id)
    },
    {
      // ✅ Show success message
      onSuccess: (data) => {
        queryClient.invalidateQueries(QUERIES_KEYS.INSPECTION_LIST)
        return data
      },
    }
  )
  return {
    updateInspection: mutate,
    updatedService: data,
    isErrorUpdateInspection: isError,
    isLoadingUpdateInspection: isLoading,
    isSuccessUpdateInspection: isSuccess,
  }
}

export default useUpdateInspection
