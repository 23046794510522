/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {getUserByToken, register} from '../core/_requests'
import {Link, useNavigate} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'
import Swal from 'sweetalert2'
import {Dropdown} from 'react-bootstrap'
import {USER_ROLE} from 'app/constants/constants'
import {AxiosError} from 'axios'
const initialValues = {
  name: '',
  email: '',
  password: '',
  changepassword: '',
  role: '',
  acceptTerms: false,
}
const roles = [
  {name: 'Workshop Owner', value: 'WORKSHOP_OWNER'},
  {name: 'Vehicle Owner', value: 'VEHICLE_OWNER'},
]
const initialSelectedRole = {name: 'Select Role', value: 'SELECT_ROLE'}
const registrationSchema = Yup.object().shape({
  name: Yup.string()
    .matches(/^[a-zA-Z\s]*$/, 'Name must only contain letters and spaces')
    .min(3, 'Minimum 3 characters are required')
    .max(50, 'Maximum 50 characters are required')
    .required('First name is required'),
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9]([a-zA-Z0-9_.-]*[a-zA-Z0-9])?@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
      'Wrong email format'
    )
    .email('Wrong email format')
    .required('Email is required'),

  password: Yup.string()
    .min(8, 'Minimum 8 characters are required')
    .max(50, 'Maximum 50 characters are required')
    .required('Password is required'),
  changepassword: Yup.string()
    .min(8, 'Minimum 8 characters are required')
    .max(50, 'Maximum 50 characters are required')
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
  role: Yup.string().required('Role selection is required'),

  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

export function Registration() {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const {saveAuth, setCurrentUser} = useAuth()
  const [selectedRole, setSelectedRole] = useState('')
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      // console.log('signup values ', values)
      setLoading(true)
      try {
        const {data: auth} = await register(values.email, values.name, values.password, values.role)
        if (auth.message === 'Signup Successful') {
          Swal.fire({
            title:
              'You have successfully signed up. Please verify by logging in and entering in the confirmation code that has been sent to your email address.',
            text: auth.message,
            icon: 'success',
            confirmButtonText: 'ok',
          }).then((result) => {
            navigate(`/auth/login`)
          })
        }
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        console.log(error)
        const errorMessage =
          (error as any)?.response?.data?.message || 'The registration details are incorrect'
        setStatus(errorMessage)
        setSubmitting(false)
        setLoading(false)
      }
    },
  })
  const handleRoleSelect = (name: string, role: string) => {
    setSelectedRole(name)
    formik.setFieldValue('role', role)
  }
  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Sign Up</h1>
        {/* end::Title */}

        <div className='text-gray-500 fw-semibold fs-6'>Signup</div>
      </div>

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Firstname */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Name</label>
        <input
          placeholder='Name'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('name')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.name && formik.errors.name,
            },
            {
              'is-valid': formik.touched.name && !formik.errors.name,
            }
          )}
        />
        {formik.touched.name && formik.errors.name && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.name}</span>
            </div>
          </div>
        )}
      </div>

      {/* begin::Form group Email */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Email</label>
        <input
          placeholder='Email'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='fv-row mb-8' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Password</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className='text-muted'>
          Use 8 or more characters with a mix of letters, numbers & symbols.
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
        <input
          type='password'
          placeholder='Password confirmation'
          autoComplete='off'
          {...formik.getFieldProps('changepassword')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
            }
          )}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Email */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Role</label>

        <div
          className='d-flex justify-content-around '
          // style={{
          //   border: '1px solid black',
          //   borderRadius: '10px',
          //   paddingTop: '10px',
          //   paddingBottom: '10px',
          // }}
        >
          <div className='form-check form-check-custom form-check-success form-check-solid form-check-sm ms-10'>
            <input
              className='form-check-input'
              type='radio'
              checked={selectedRole === USER_ROLE.WORKSHOP_OWNER}
              onChange={(e) => {
                handleRoleSelect(USER_ROLE.WORKSHOP_OWNER, e.target.value)
              }}
              value={USER_ROLE.WORKSHOP_OWNER}
              id='kt_builder_header_content_menu'
              name='model.app.header.default.content'
            />
            <label
              className='form-check-label text-gray-700 fw-bold text-nowrap'
              htmlFor='kt_builder_header_content_menu'
            >
              Workshop Owner
            </label>
          </div>
          <div className='form-check form-check-custom form-check-success form-check-solid form-check-sm ms-10'>
            <input
              className='form-check-input'
              type='radio'
              value='VEHICLE_OWNER'
              checked={selectedRole === 'Vehicle Owner'}
              id='kt_builder_header_content_page-title'
              onChange={(e) => {
                handleRoleSelect('Vehicle Owner', e.target.value)
              }}
            />
            <label
              className='form-check-label text-gray-700 fw-bold text-nowrap'
              htmlFor='kt_builder_header_content_page-title'
            >
              Vehicle Owner
            </label>
          </div>
        </div>
        {/* <Dropdown>
          <Dropdown.Toggle variant='primary' id='dropdown-basic'>
            {selectedRole.name}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {roles.map((role, index) => (
              <Dropdown.Item key={index} onClick={() => handleRoleSelect(role)}>
                {role.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown> */}
        {/* {formik.touched.role && formik.errors.role && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.role}</span>
            </div>
          </div>
        )} */}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <span>
            I Accept the{' '}
            <a
              href='https://keenthemes.com/metronic/?page=faq'
              target='_blank'
              className='ms-1 link-primary'
            >
              Terms
            </a>
            .
          </span>
        </label>
        {formik.touched.acceptTerms && formik.errors.acceptTerms && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.acceptTerms}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>Login</Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}
