import {useMutation, useQueryClient} from 'react-query'

import {QUERIES_KEYS} from '../../../../helpers/crud-helpers/consts'
import {deleteUser} from '../_requests'

function useDeleteUser() {
  const queryClient = useQueryClient()

  const {mutate, isLoading, isSuccess} = useMutation((id: string) => deleteUser(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERIES_KEYS.USERS_LIST)
      queryClient.invalidateQueries(QUERIES_KEYS.SERVICE_LIST)
    },
  })

  return {deleteUserById: mutate, isLoadingUserDelete: isLoading, isSuccessUserDelete: isSuccess}
}

export default useDeleteUser
