import React, {useEffect, useState, useRef} from 'react'
import {KTIcon} from '_metronic/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {Dropdown, Form} from 'react-bootstrap'
import {useDataContext} from 'app/modules/data'
import {IAddService} from '../../core/_models'
import Swal from 'sweetalert2'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import JoditEditor from 'jodit-react'
import CustomMultiselectDropdown from './CustomMultiselectDropdown'
import useCreateService from '../../hooks/use-create-service'
import useWorkshop from 'app/pages/workshop/hooks/use-workshop'
import useVehicle from 'app/pages/vehicles/hooks/use-vehicle'
import {useAuth} from 'app/modules/auth'
import useWorkshopById from 'app/pages/workshop/hooks/use-get-by-id-workshop'
import ServiceDropdown from './CustomMultiselectDropdown'
import moment from 'moment'
import Editor from './Editor'
// import useCreateVehicle from '../hooks/use-create-vehicle'
// import useUpdateVehicle from '../hooks/use-update-vehicle'
const editorConfig = {readonly: false}
const dummyVehiclesData = [
    {id: '65ba2e493e33b32fc001ad7d', make: 'honda', model: 'VTI ORIEL'},
    {id: '75ba2e493e33b32fc001ad7d', make: 'Suzuki', model: 'cultus'},
    {id: '85ba2e493e33b32fc001ad7d', make: 'honda', model: 'civic'},
  ],
  dummyWokrshops = [
    {
      id: '65c31615da3002731f090e6c',
      name: 'Workshop 1',
      services: ['check 1', 'check 2', 'service C'],
    },
    {id: 'd001ad7d', name: 'workshop2', services: ['serviceD', 'serviceE', 'serviceF']},
    {id: 'e001ad7d', name: 'workshop3', services: ['serviceV', 'serviceX', 'serviceZ']},
  ]
const phoneNumberRegex = /^\+?\d+$/

const phoneNumberValidation = (phoneNumber) => {
  if (!phoneNumber) return true // Accept empty value if not required
  return phoneNumberRegex.test(phoneNumber) // Validate against regex
}
const vehicleType = ['car', 'truck', 'ute', 'forklift', 'other']
const phoneNumberErrorMessage = 'Please enter a valid phone number'
const initialData = {initialVehicle: 'Select Vehicle', initialWorkshop: 'Select Workshop'}
const AddServiceFormByManager: React.FC = () => {
  const {vehicleByServiceManager, setVehicleByServiceManager, modal, setModal} = useDataContext()

  const {vehicleData, isLoadingVehicle} = useVehicle()
  const {currentUser} = useAuth()
  const [vehicleTitle, setVehicleTitle] = useState(initialData.initialVehicle)
  const [workShopTitle, setWorkShopTitle] = useState(initialData.initialWorkshop)
  const {workshopsData, isLoadingWorkshops} = useWorkshop()
  const [editorData, setEditorData] = useState('')
  const editorRef = useRef(null)
  const [checks, setChecks] = useState<string[]>([''])
  const [getVehicleData, setGetVehicleData] = useState(false)
  const [SelectedVehicle, setSelectedVehicle] = useState('car')
  const [selectedServices, setSelectedServices] = useState<string[]>([])
  const {workshopData, isLoadingWorkshopData} = useWorkshopById(currentUser?.data.user.workshop)
  const [initializeFormik, setInitializeFormik] = useState(false)
  //const [apiSent, setApiSent] = useState(false)
  const {
    createService,
    isErrorCreateService,
    errorCreateService,
    isSuccessCreateService,
    isLoadingCreateService,
  } = useCreateService()
  const isVehicleOwner = currentUser?.data.user.role === 'VEHICLE_OWNER'

  const productDetailsSchema = Yup.object().shape({
    ...(!getVehicleData && {vehicle: Yup.string().required('Vehicle selection is required')}),
    // workshop: Yup.string().required('Workshop selection is Required'),
    ...(isVehicleOwner && {workshop: Yup.string().required('Workshop selection is Required')}),
    contact: Yup.string()
      .test('phoneNumber', phoneNumberErrorMessage, phoneNumberValidation)
      .required('Phone number is required'),
    vehicleOwnerNotes: Yup.string().required('Notes are Required'),
    ...(!isVehicleOwner && {scheduledFor: Yup.string().required('Date selection is Required')}),
    checks: Yup.array()
      .of(Yup.string().required('Check is required'))
      .required('At least one check is required')
      .min(1, 'At least one service is required'),
    ...(getVehicleData && {
      name: Yup.string().required('Name is Required'),
      email: Yup.string()
        .matches(
          /^[a-zA-Z0-9]([a-zA-Z0-9_.-]*[a-zA-Z0-9])?@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
          'Wrong email format'
        )
        .email('Wrong email format')
        .required('Email is required'),
      make: Yup.string()
        .matches(/^[A-Za-z\s]+$/, 'Make must contain only letters')
        .required('Make is required'),
      model: Yup.string().required('Model is Required'),
      year: Yup.number()
        .required('Year is required')
        .min(1950, 'Year must be greater than or equal to 1950')
        .max(moment().year(), `Year must be less than or equal to ${moment().year()}`),
      type: Yup.string().test(
        'is-vehicle',
        'Type selection is required',
        (value) => value !== 'Select Type'
      ),
      vehicleRegistrationNumber: Yup.string().required('Registration number is required'),
      vehicleIdentificationNumber: Yup.number().required('VIN Price is Required'),
      // .test(
      //   'is-fourteen-digits',
      //   'Vehicle Identification Number must be a 14-digit number',
      //   (value) => {
      //     if (value === undefined) return true
      //     const stringValue = String(value)
      //     return stringValue.length === 14 && /^\d+$/.test(stringValue)
      //   }
      // ),
    }),
  })
  const [validationSchema, setValidationSchema] = useState(productDetailsSchema)
  const initialFormValue = {
    ...(!getVehicleData && {vehicle: ''}),
    ...(isVehicleOwner && {workshop: ''}),
    ...(!isVehicleOwner && {scheduledFor: moment(Date.now()).format('YYYY-MM-DD')}),
    contact: '',
    vehicleOwnerNotes: '',
    checks: [''],
    ...(getVehicleData && {
      name: '',
      email: '',
      make: '',
      model: '',
      year: '',
      type: '',
      vehicleRegistrationNumber: '',
      vehicleIdentificationNumber: '',
    }),
  }

  const formik = useFormik<IAddService>({
    enableReinitialize: !initializeFormik,
    initialValues: initialFormValue,
    validationSchema: productDetailsSchema,
    onSubmit: (values) => {
      //log('DATA TO ADD ', values, currentUser?.data.user.role)
      if (isVehicleOwner) {
        createService(values)
      } else {
        if (getVehicleData == true) {
          const partialUserDetails = {
            name: values.name,
            email: values.email,
          }
          const partialVehicleDetails = {
            make: values.make,
            model: values.model,
            year: values.year,
            type: values.type,
            vehicleIdentificationNumber: values.vehicleIdentificationNumber,
          }
          const {vehicle, ...partialServiceData} = values
          const serviceData = {...partialServiceData, partialUserDetails, partialVehicleDetails}
          // console.log('Service Data => ', {
          //   ...serviceData,
          //   workshop: currentUser?.data.user.workshop,
          // })
          //console.log('send1')
          createService({...serviceData, workshop: currentUser?.data.user.workshop})
          //setGetVehicleData(false)
        } else {
          //console.log('values ', values)
          //console.log('send2')
          createService({...values, workshop: currentUser?.data.user.workshop})
          //setGetVehicleData(false)
        }
      }
    },
  })
  const handleVehicleSelection = (vehicleId: string | undefined, vehicleName: string) => {
    // console.log('vehicle selected ', vehicleId, vehicleName)
    setVehicleTitle(vehicleName)
    formik.setFieldValue('vehicle', vehicleId)
  }
  const handleWorkshopSelection = (workshop: string, workshopName: string) => {
    setWorkShopTitle(workshopName)
    formik.setFieldValue('workshop', workshop)
  }
  const getValue = (value) => {
    // check do you get dynamic value here

    setEditorData(value)
  }
  useEffect(() => {
    //console.log('Setting editor data in formik', editorData)
    formik.setFieldValue('vehicleOwnerNotes', editorData)
  }, [editorData])

  const handleSelectServices = (selectedServices: string[]) => {
    setChecks(selectedServices)
  }
  const handleSetToInitial = () => {
    setVehicleTitle(initialData.initialVehicle)
    setWorkShopTitle(initialData.initialWorkshop)
    formik.resetForm()
    setChecks([''])
    setEditorData('')
    setInitializeFormik(false)
    setSelectedServices([])
    setGetVehicleData(false)
    //formikVehicle.resetForm()
    setSelectedVehicle('car')
  }
  useEffect(() => {
    if (isErrorCreateService) {
      handleSetToInitial()
      // console.log('Error while creating service ', errorCreateService)
      Swal.fire({
        title: 'Error',
        text: 'Error Creating service. Try again',
        icon: 'error',
        confirmButtonText: 'Ok',
      }).then((result) => {
        // console.log('Fire erro result ', result)
      })
    } else if (isSuccessCreateService) {
      handleSetToInitial()
      Swal.fire({
        title: 'Success',
        text: 'Created Successfully!',
        icon: 'success',
        confirmButtonText: 'Done',
      }).then((result) => {
        // console.log('Fire result ', result)
      })
    }
  }, [isErrorCreateService, isSuccessCreateService])
  const handleTypeSelect = (type: string) => {
    formik.setFieldValue('type', type)
    setSelectedVehicle(type)
  }
  const handleUserNameFieldFocus = () => {
    document.getElementById('username')?.focus()
    document.getElementById('username')?.blur()
    document.getElementById('username')?.focus()
  }
  return (
    <div
      className='modal fade p-1'
      id='kt_modal_service_add_by_manager'
      aria-hidden='true'
      //   style={{zIndex: 2012}}
    >
      <div className='modal-dialog mw-850px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-space-between'>
            <h3 className='text-center'>Add Service</h3>
            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              data-bs-dismiss='modal'
              onClick={(e) => {
                e.preventDefault()
                handleSetToInitial()
              }}
              style={{borderRadius: '50%', border: '1px solid'}}
            >
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <form onSubmit={formik.handleSubmit} noValidate className='form'>
              <div className='card-body border-top p-5'>
                <div className='row mb-6'>
                  <label className='col-lg-2 col-form-label required fw-bold fs-6'>Vehicle</label>

                  <div className='col-lg-10'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <Dropdown>
                          <Dropdown.Toggle variant='primary' id='dropdown-basic'>
                            {vehicleTitle}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              //   data-bs-target='#kt_modal_vehicle_add_by_service_manager'
                              //   data-bs-toggle='modal'

                              onClick={() => {
                                setTimeout(handleUserNameFieldFocus, 0)
                                setVehicleTitle('New Vehicle')
                                setInitializeFormik(true)

                                // setSelectedVehicle('Select Type')
                                setGetVehicleData(true)

                                // setModal(true)
                              }}
                            >
                              New Vehicle
                            </Dropdown.Item>
                            {vehicleData?.map((vehicle, index) => {
                              const vehicleName = `${vehicle.make} ${vehicle.model}`
                              return (
                                <Dropdown.Item
                                  key={index}
                                  onClick={() => {
                                    handleVehicleSelection(vehicle?._id, vehicleName)
                                    setGetVehicleData(false)
                                    setInitializeFormik(false)
                                    setTimeout(() => {
                                      document.getElementById('cx')?.focus()
                                      document.getElementById('cx')?.blur()
                                    }, 0)

                                    setSelectedVehicle('car')
                                  }}
                                >
                                  {vehicleName}
                                </Dropdown.Item>
                              )
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                        {vehicleData?.length === 0 && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>Add vehicles first</div>
                          </div>
                        )}
                        {formik.touched.vehicle && formik.errors.vehicle && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.vehicle}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {getVehicleData && (
                  <>
                    <div className='row mb-6'>
                      <label className='col-lg-2 col-form-label required fw-bold fs-6'>Name</label>

                      <div className='col-lg-10'>
                        <div className='row'>
                          <div className='col-lg-12 fv-row'>
                            <input
                              type='text'
                              id='username'
                              className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                              placeholder='Enter vehicle owner name'
                              {...formik.getFieldProps('name')}
                              //   onChange={formik.handleChange}
                              //   onBlur={formik.handleBlur}
                              //   value={formik.values?.PartialUserDto?.name}
                              //   {...formik.getFieldProps('ownerName')}
                            />

                            {formik.touched.name && formik.errors.name && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.name}</div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row mb-6'>
                      <label className='col-lg-2 col-form-label required fw-bold fs-6'>Email</label>

                      <div className='col-lg-10'>
                        <div className='row'>
                          <div className='col-lg-12 fv-row'>
                            <input
                              type='text'
                              className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                              placeholder='Enter vehicle owner email'
                              //   {...formik.getFieldProps('email')}
                              //   onChange={formik.handleChange}
                              //   onBlur={formik.handleBlur}
                              //   value={formik.values?.PartialUserDto?.email}
                              {...formik.getFieldProps('email')}
                            />
                            {formik.touched.email && formik.errors.email && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.email}</div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row mb-6'>
                      <label className='col-lg-2 col-form-label required fw-bold fs-6'>Make</label>

                      <div className='col-lg-10'>
                        <div className='row'>
                          <div className='col-lg-12 fv-row'>
                            <input
                              type='text'
                              className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                              placeholder='Make'
                              //   {...formik.getFieldProps('make')}
                              //   onChange={formik.handleChange}
                              //   onBlur={formik.handleBlur}
                              //   value={formik.values?.PartialVehicleDto?.make}
                              {...formik.getFieldProps('make')}
                            />
                            {formik.touched?.make && formik.errors?.make && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors?.make}</div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-2 col-form-label required fw-bold fs-6'>Model</label>

                      <div className='col-lg-10'>
                        <div className='row'>
                          <div className='col-lg-12 fv-row'>
                            <input
                              type='text'
                              className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                              placeholder='Model'
                              {...formik.getFieldProps('model')}
                              //     onChange={formik.handleChange}
                              //   onBlur={formik.handleBlur}
                              //   value={formik.values?.PartialVehicleDto?.model}
                              //   {...formik.getFieldProps('model')}
                            />
                            {formik.touched?.model && formik.errors?.model && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors?.model}</div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-2 col-form-label required fw-bold fs-6'>Year</label>

                      <div className='col-lg-10'>
                        <div className='row'>
                          <div className='col-lg-12 fv-row'>
                            <input
                              type='number'
                              className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                              placeholder='Year'
                              //     onChange={formik.handleChange}
                              //   onBlur={formik.handleBlur}
                              //   value={formik.values?.PartialVehicleDto?.year}
                              {...formik.getFieldProps('year')}
                            />
                            {formik.touched?.year && formik.errors?.year && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors?.year}</div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-2 col-form-label required fw-bold fs-6'>VIN</label>

                      <div className='col-lg-10'>
                        <div className='row'>
                          <div className='col-lg-12 fv-row'>
                            <input
                              type='number'
                              className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                              placeholder='Vehicle Identification Number'
                              //   {...formik.getFieldProps('vehicleIdentificationNumber')}
                              //   onChange={formik.handleChange}
                              //   onBlur={formik.handleBlur}
                              //   value={formik.values?.PartialVehicleDto?.vehicleIdentificationNumber}
                              {...formik.getFieldProps('vehicleIdentificationNumber')}
                            />
                            {formik.touched?.vehicleIdentificationNumber &&
                              formik.errors?.vehicleIdentificationNumber && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>
                                    {formik.errors?.vehicleIdentificationNumber}
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row mb-6'>
                      <label className='col-lg-2 col-form-label required fw-bold fs-6'>
                        Vehicle Registration
                      </label>

                      <div className='col-lg-10'>
                        <div className='row'>
                          <div className='col-lg-12 fv-row'>
                            <input
                              type='text'
                              className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                              placeholder='Registration Number'
                              {...formik.getFieldProps('vehicleRegistrationNumber')}
                            />
                            {formik.touched.vehicleRegistrationNumber &&
                              formik.errors.vehicleRegistrationNumber && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>
                                    {formik.errors.vehicleRegistrationNumber}
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row mb-6'>
                      <label className='col-lg-2 col-form-label required fw-bold fs-6'>Type</label>

                      <div className='col-lg-10'>
                        <div className='row'>
                          <div className='col-lg-12 fv-row'>
                            <Dropdown>
                              <Dropdown.Toggle variant='primary' id='dropdown-basic'>
                                {SelectedVehicle}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {vehicleType?.map((type) => (
                                  <Dropdown.Item key={type} onClick={() => handleTypeSelect(type)}>
                                    {type}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                            {formik.touched?.type && formik.errors?.type && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors?.type}</div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {isVehicleOwner == false && (
                  <div className='row mb-6'>
                    <label className='col-lg-2 col-form-label required fw-bold fs-6'>Date</label>

                    <div className='col-lg-10'>
                      <Form.Group controlId='exampleForm.DatePicker'>
                        <Form.Control
                          id='cx'
                          type='date'
                          {...formik.getFieldProps('scheduledFor')}
                        />
                      </Form.Group>
                    </div>
                  </div>
                )}
                {isVehicleOwner == true && (
                  <div className='row mb-6'>
                    <label className='col-lg-2 col-form-label required fw-bold fs-6'>
                      Workshop
                    </label>

                    <div className='col-lg-10'>
                      <div className='row'>
                        <div className='col-lg-12 fv-row'>
                          {/* <input
                          type='text'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='Units'
                          {...formik.getFieldProps('workshopName')}
                        /> */}
                          <Dropdown>
                            <Dropdown.Toggle variant='primary' id='dropdown-basic'>
                              {workShopTitle}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {workshopsData?.map((workshop, index) => (
                                <Dropdown.Item
                                  key={index}
                                  onClick={() =>
                                    handleWorkshopSelection(workshop._id, workshop.name)
                                  }
                                >
                                  {workshop.name}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                          {formik.touched.workshop && formik.errors.workshop && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{formik.errors.workshop}</div>
                            </div>
                          )}
                          {/* <div
                          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                          data-kt-menu='true'
                        >
                          {workshopsData?.map((workshop, index) => {
                            return (
                              <div className='menu-item px-3' key={index}>
                                <a
                                  className='menu-link px-3'
                                  onClick={() =>
                                    handleWorkshopSelection(workshop._id, workshop.name)
                                  }
                                >
                                  {workshop.name}
                                </a>
                              </div>
                            )
                          })}
                        </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className='row mb-6'>
                  <label className='col-lg-2 col-form-label required fw-bold fs-6'>Contact</label>

                  <div className='col-lg-10'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='Enter contact'
                          {...formik.getFieldProps('contact')}
                        />
                        {formik.touched.contact && formik.errors.contact && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.contact}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-2 col-form-label required fw-bold fs-6'>Services</label>

                  <div className='col-lg-10'>
                    <div className='row'>
                      <div
                        className='col-lg-6 fv-row'
                        onBlur={() => formik.setFieldValue('checks', checks)}
                      >
                        <ServiceDropdown
                          checks={
                            isVehicleOwner
                              ? workshopsData?.find((workshop) => workshop.name === workShopTitle)
                                  ?.checks
                              : workshopData?.checks
                            // currentUser?.data.user.workshop
                            //   ? workshopsData?.find(
                            //       (workShop) => workShop.id == currentUser?.data.user.workshop
                            //     )
                            //   : dummyWokrshops?.find((workshop) => workshop.name === workShopTitle)
                            //       ?.services
                          }
                          onSelectServices={handleSelectServices}
                          selectedServices={selectedServices}
                          setSelectedServices={setSelectedServices}
                        />

                        {/* {formik.touched.checks && formik.errors.checks && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.checks}</div>
                          </div>
                        )} */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-2 col-form-label required fw-bold fs-6'>Notes</label>

                  <div className='col-lg-10'>
                    <div className='row' style={{maxHeight: '300px', overflowY: 'auto'}}>
                      <div className='col-lg-12 fv-row'>
                        <JoditEditor
                          ref={editorRef}
                          value={editorData}
                          // config={{autofocus: true, cursorAfterAutofocus: 'end'}}

                          onBlur={(newContent) => setEditorData(newContent)}
                          //onChange={(newContent) => setEditorData(`${editorData} ${newContent}`)}  setEditorData(newContent.replace(/<[^>]+>/g, '').trim())
                        />
                        {/* <Editor contents={editorData} getValue={getValue} /> */}
                      </div>
                    </div>
                    {formik.touched.vehicleOwnerNotes && formik.errors.vehicleOwnerNotes && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.vehicleOwnerNotes}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='card-footer d-flex justify-content-end py-6 px-5 '>
                <button
                  type='submit'
                  className='btn btn-primary'
                  disabled={
                    !formik.isValid || (!formik.dirty && getVehicleData == true)
                      ? formik.values?.name?.length &&
                        formik.values?.email?.length &&
                        formik.values?.make?.length &&
                        formik.values?.model?.length &&
                        formik.values?.checks?.length &&
                        formik.values?.contact?.length &&
                        formik.values?.type?.length &&
                        formik.values?.vehicleOwnerNotes?.length
                        ? false
                        : true
                      : false
                  }
                  // disabled={
                  //   formik.errors?.contact?.length
                  //     ? true
                  //     : false || formik.errors?.checks?.length
                  //     ? true
                  //     : false || formik.errors?.vehicleOwnerNotes?.length
                  //     ? true
                  //     : false || getVehicleData == true
                  // ? formik.values?.make?.length &&
                  //   formik.values?.email?.length &&
                  //   formik.values?.name?.length &&
                  //   formik.values?.type?.length
                  //       ? false
                  //       : true
                  //     : false || formik.errors?.scheduledFor?.length
                  //     ? true
                  //     : false || (!formik.dirty && getVehicleData)
                  //     ? true
                  //     : formik.errors?.vehicle?.length ||
                  //       formik.errors?.model?.length ||
                  //       formik.errors?.year?.length ||
                  //       formik.errors?.email?.length ||
                  //       formik.errors?.name?.length ||
                  //       formik.errors?.vehicleIdentificationNumber?.length ||
                  //       formik.errors?.vehicleRegistrationNumber?.length ||
                  //       formik.errors?.type?.length
                  //     ? true
                  //     : false
                  //   //|| getVehicleData == true
                  //   // ? true
                  //   // : formik.errors?.make?.length
                  //   // ? true
                  //   // : false || getVehicleData
                  //   // ? true
                  //   // : formik.errors?.model?.length
                  //   // ? true
                  //   // : false || getVehicleData
                  //   // ? true
                  //   // : formik.errors?.year?.length
                  //   // ? true
                  //   // : false || getVehicleData
                  //   // ? true
                  //   // : formik.errors?.email?.length
                  //   // ? true
                  //   // : false || getVehicleData
                  //   // ? true
                  //   // : formik.errors?.name?.length
                  //   // ? true
                  //   // : false || getVehicleData
                  //   // ? true
                  //   // : formik.errors?.vehicleIdentificationNumber?.length
                  //   // ? true
                  //   // : false || getVehicleData
                  //   // ? true
                  //   // : formik.errors?.type?.length
                  //   // ? true
                  //   // : false
                  // }
                  data-bs-dismiss={isLoadingCreateService ? 'null' : 'modal'}
                >
                  Submit
                  {isLoadingCreateService && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export {AddServiceFormByManager}
