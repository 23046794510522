import axios from 'axios'
import {IVehicle, IVehicleQueryResponse} from './_models'

const VEHICLE = '/vehicle'

export function getVehicles(): Promise<IVehicleQueryResponse | any> {
  return axios.get(VEHICLE).then((response) => response.data)
}

export function createVehicle(body: IVehicle): Promise<IVehicleQueryResponse | undefined> {
  return axios.post(VEHICLE, body).then((response) => response.data)
}
export function updateVehicle(
  body: IVehicle,
  id: string
): Promise<IVehicleQueryResponse | undefined> {
  return axios.patch(`${VEHICLE}/${id}`, body)
}

export function deleteVehicle(id: string) {
  return axios.delete(`${VEHICLE}/${id}`)
}
