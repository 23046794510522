import React, {useCallback, useEffect, useState} from 'react'

import {useAuth} from 'app/modules/auth'
import {USER_ROLE} from 'app/constants/constants'
import useService from '../service/hooks/use-get-service'
import {CustomPageLoader} from 'app/modules/auth/components/CustomPageLoader'
import Swal from 'sweetalert2'
import useUpdateCustomer from './core/hooks/use-update-customer'
import {IService} from '../service/core/_models'

export default function Customer() {
  const {currentUser} = useAuth()
  const {serviceData, isLoadingService} = useService()
  const {blockUser, isLoadingUpdateUser} = useUpdateCustomer()
  const [uniqueFilteredData, setUniqueFilteredData] = useState<IService[]>([])
  const [searchQuery, setSearchQuery] = useState('')
  const [initialFilteredData, setInitialFilteredData] = useState<IService[]>([])
  const [loadingStates, setLoadingStates] = useState<Record<string, boolean>>({}) // Track loading state for each user

  useEffect(() => {
    const uniqueIds = new Set()
    const filteredData: IService[] = serviceData?.filter((obj) => {
      if (obj && obj.vehicleOwner && !uniqueIds.has(obj.vehicleOwner._id)) {
        uniqueIds.add(obj?.vehicleOwner?._id)
        return true
      }
      return false
    })
    setInitialFilteredData(filteredData)
    setUniqueFilteredData(filteredData)
  }, [serviceData])

  const handleSearch = useCallback(
    (searchTerm: string) => {
      if (searchTerm.length !== 0) {
        return uniqueFilteredData.filter((service) =>
          service.vehicleOwner?.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      } else {
        return initialFilteredData // Return the original data when search term is empty
      }
    },
    [uniqueFilteredData, initialFilteredData]
  )

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target
    setSearchQuery(value)
    const filteredData = handleSearch(value)
    setUniqueFilteredData(filteredData) // Update the state with the filtered data
  }

  const handleButtonClick = (userId: string, isBlocked: boolean) => {
    Swal.fire({
      title: `Do you want to ${!isBlocked ? 'Block' : 'Unblock'} the Customer`,
      showDenyButton: true,
      confirmButtonText: 'Yes',
      icon: 'warning',
    }).then((result) => {
      if (result.isConfirmed) {
        setLoadingStates((prev) => ({...prev, [userId]: true}))

        blockUser(
          {
            id: userId,
            body: {
              isBlocked: !isBlocked,
            },
          },
          {
            onSuccess: () => {
              Swal.fire({
                title: 'Success',
                text: !isBlocked ? 'Blocked Successfully' : 'Unblocked Successfully',
                icon: 'success',
              })
              setLoadingStates((prev) => ({...prev, [userId]: false}))
            },
            onError: () => {
              setLoadingStates((prev) => ({...prev, [userId]: false}))
            },
          }
        )
      } else {
        setLoadingStates((prev) => ({...prev, [userId]: false}))
      }
    })
  }

  return (
    <div className={`card`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Customers</span>
        </h3>
      </div>
      <div className='row mb-8'>
        <div className='input-group w-50 ms-10'>
          <span className='input-group-text'>
            <i className='fa fa-search'></i>
          </span>
          <input
            type='text'
            className='form-control'
            placeholder='Search'
            value={searchQuery}
            onChange={handleChangeSearch}
          />
          {searchQuery.length ? (
            <span
              className='input-group-text'
              onClick={() => {
                setSearchQuery('')
                handleSearch('')
                setUniqueFilteredData(initialFilteredData)
              }}
            >
              <i className='far fa-window-close'></i>
            </span>
          ) : null}
        </div>
      </div>
      {!isLoadingService ? (
        uniqueFilteredData?.length > 0 ? (
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted bg-light'>
                    <th className='ps-4 min-w-125px rounded-start'>Name</th>
                    <th className='min-w-125px'>Email</th>
                    <th className='min-w-200px'>Registration Number</th>
                    <th className='min-w-125px'>Make</th>
                    <th className='min-w-125px'>Model</th>
                    <th className='min-w-125px'>Year</th>
                    {currentUser?.data.user.role === USER_ROLE.SUPER_USER && (
                      <th className='min-w-125px'>Action</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {uniqueFilteredData?.map((service) => (
                    <tr key={service.vehicleOwner?._id}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='symbol symbol-50px me-5'></div>
                          <div className='d-flex justify-content-start flex-column'>
                            <a className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                              {service?.vehicleOwner?.name}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'></span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span className='fs-7 fw-semibold'>{service?.vehicleOwner?.email}</span>
                      </td>
                      <td>
                        <span className='fs-7 fw-semibold'>
                          {service?.vehicle?.registrationNumber}
                        </span>
                      </td>
                      <td>
                        <span className='fs-7 fw-semibold'>{service?.vehicle?.make}</span>
                      </td>
                      <td>
                        <span className='fs-7 fw-semibold'>{service?.vehicle?.model}</span>
                      </td>
                      <td>
                        <span className='fs-7 fw-semibold'>{service?.vehicle?.year}</span>
                      </td>
                      {currentUser?.data.user.role === USER_ROLE.SUPER_USER && (
                        <td>
                          <button
                            type='button'
                            className='rounded border-0 px-5 py-2 min-w-80px text-light'
                            aria-disabled={isLoadingUpdateUser}
                            style={{
                              backgroundColor: service?.vehicleOwner?.isBlocked ? 'green' : 'red',
                            }}
                            onClick={() =>
                              handleButtonClick(
                                service.vehicleOwner?._id,
                                service?.vehicleOwner?.isBlocked
                              )
                            }
                          >
                            {loadingStates[service.vehicleOwner?._id]
                              ? 'Loading...'
                              : service?.vehicleOwner?.isBlocked
                              ? 'Unblock'
                              : 'Block'}
                          </button>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <p className='ms-10'>No customers found</p>
        )
      ) : (
        <CustomPageLoader />
      )}
    </div>
  )
}
