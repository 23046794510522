import React from 'react'
type props = {
  serviceProp: any
}
const statusMap = new Map([
  [0, 'CREATED'],
  [1, 'SCHEDULED'],
  [2, 'PENDING'],
  [3, 'ASSIGNED'],
  [4, 'WORK_IN_PROGRESS'],
  [5, 'PROBLEM_PENDING'],
  [6, 'AWAITING_RESOURCES'],
  [7, 'AWAITING_APPROVAL'],
  [8, 'AWAITING_CLOSURE'],
  [9, 'PAYMENT_PENDING'],
  [10, 'PAID'],
])
export const VehicleServiceRow: React.FC<props> = ({serviceProp}) => {
  function getStatusName(statusNumber: number) {
    return statusMap.get(statusNumber) || 'Unknown' // Return status name or 'Unknown' if not found
  }
  return (
    <tr>
      <td>
        <div className='d-flex align-items-center'>
          <div className='symbol symbol-50px me-5'></div>
          <div className='d-flex justify-content-start flex-column'>
            <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
              {serviceProp.vehicleIdentificationNumber}
            </a>
            <span className='text-muted fw-semibold text-muted d-block fs-7'></span>
          </div>
        </div>
      </td>
      <td>
        <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
          {serviceProp?.fixedBy?.name || '-'}
        </a>
        <span className='text-muted fw-semibold text-muted d-block fs-7'>
          {serviceProp?.fixedBy?.role || ''}
        </span>
      </td>
      <td>
        <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
          {new Date(serviceProp.createdAt)
            .toLocaleDateString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })
            .replace(/\//g, '-') || '-'}
        </a>
        <span className='text-muted fw-semibold text-muted d-block fs-7'>
          Scheduled For :{' '}
          {serviceProp?.scheduledFor
            ? new Date(serviceProp?.scheduledFor)
                .toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                })
                .replace(/\//g, '-')
            : '-'}
        </span>
      </td>
      <td>
        <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
          {serviceProp?.vehicleOwner?.name || 'User'}
        </a>
        <span className='text-muted fw-semibold text-muted d-block fs-7'>
          {serviceProp.vehicle?.year +
            ' - ' +
            serviceProp.vehicle?.make +
            ' - ' +
            serviceProp.vehicle?.model}
        </span>
      </td>
      <td>
        <span className='fs-7 fw-semibold'>{serviceProp.workshop.name}</span>
      </td>
      <td>
        <span className='badge badge-light-danger fs-7 fw-semibold'>
          {getStatusName(serviceProp.status)}
        </span>
      </td>
    </tr>
  )
}
