/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {useDataContext} from '../../../modules/data'
import {KTIcon} from '../../../../_metronic/helpers'
import Swal from 'sweetalert2'
import useDeleteVehicle from '../hooks/use-delete-vehicle'
import {useNavigate} from 'react-router-dom'

type Props = {
  vehicle: any
}

const VehicleRow: React.FC<Props> = ({vehicle: vehicleProp}) => {
  const {vehicle, setVehicle} = useDataContext()
  const {modal, setModal} = useDataContext()

  const {deleteVehicle, isSuccessVehicle} = useDeleteVehicle()
  const navigate = useNavigate()
  return (
    <div className={`card mb-3`}>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='tab-content'>
          {/* begin::Tap pane */}
          <div className='tab-pane fade show active' id='kt_table_widget_8_tab_1'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table align-middle gs-0 gy-3'>
                {/* begin::Table head */}
                <thead>
                  <tr>
                    <th className='p-0 w-50px'></th>
                    <th className='p-0 min-w-150px'></th>
                    <th className='p-0 min-w-120px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  <tr>
                    <td className='p-0 w-50px'>
                      <div className='symbol symbol-50px me-2'>
                        <span className='symbol-label bg-light-danger'>
                          <KTIcon
                            iconName={vehicleProp.type === 'CAR' ? 'car' : 'truck'}
                            className='fs-2x text-danger'
                          />
                        </span>
                      </div>
                    </td>
                    <td className='p-0 min-w-300px'>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        {vehicleProp.make}
                      </a>
                      <span className='text-muted fw-semibold d-block fs-7'>
                        {vehicleProp.model} {vehicleProp.year}
                      </span>
                    </td>
                    <td className='d-flex justify-content-between align-items-center'>
                      <div>
                        <div className='text-dark fw-bold fs-7'>
                          Registration Number: {vehicleProp.registrationNumber}
                        </div>
                        <div className='text-muted fw-semibold fs-8'>
                          VIN: {vehicleProp.vehicleIdentificationNumber}
                        </div>
                      </div>
                      <div className='text-end'>
                        <a
                          href='#'
                          className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_vehicle_add'
                          onClick={() => {
                            setVehicle(vehicleProp)
                            setModal(true)
                          }}
                        >
                          <i className='bi bi-pencil fs-5 text-primary'></i>
                        </a>
                        <a
                          href='#'
                          className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2'
                          onClick={() => {
                            Swal.fire({
                              title: 'Do you want to delete the product',
                              showDenyButton: true,
                              confirmButtonText: 'Yes',
                              icon: 'warning',
                            }).then((result) => {
                              /* Read more about isConfirmed, isDenied below */
                              if (result.isConfirmed) {
                                deleteVehicle(vehicleProp._id)
                                if (isSuccessVehicle) {
                                  Swal.fire({
                                    title: 'Success',
                                    text: 'Deleted Successfully',
                                    icon: 'success',
                                  })
                                }
                              } else if (result.isDenied) {
                              }
                            })
                          }}
                        >
                          <i className='bi bi-trash fs-5 text-danger'></i>
                        </a>
                        <a
                          href='#'
                          className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                          onClick={(e) => {
                            e.preventDefault()
                            setVehicle(vehicleProp)
                            navigate(`${vehicleProp._id}`)
                          }}
                        >
                          <i className='bi bi-arrow-right fs-5 text-success'></i>
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {VehicleRow}
