/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useCallback, useEffect, useState} from 'react'
import {useDataContext} from '../../../modules/data'
import {KTIcon} from '../../../../_metronic/helpers'
import {VehicleRow} from './vehicleRow'

type Props = {
  products: any
}

const VehicleTable: React.FC<Props> = ({products}) => {
  const {modal, setModal} = useDataContext()
  const [searchQuery, setSearchQuery] = useState('')

  const [filteredVehicles, setFilteredVehicles] = useState([])

  const updateVehicles = (str) => {
    setSearchQuery(str)
    setFilteredVehicles(() => searchArray(str))
  }

  const searchArray = useCallback(
    (searchTerm) => {
      searchTerm = searchTerm.toLowerCase() // Convert the search term to lowercase for case-insensitive search

      return products.filter((obj) => {
        // Check if any property of the object contains the search term
        return Object.values(obj).some((value) => {
          if (typeof value === 'string' || typeof value === 'number') {
            return value.toString().toLowerCase().includes(searchTerm)
          }
          return false
        })
      })
    },
    [products]
  )

  // Function to search for objects based on the search criteria

  return (
    //<ProductForm setShowModal={setModal} />
    <div className={`card`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>All Vehicles</span>
          <span className='text-muted mt-1 fw-semibold fs-7'></span>
        </h3>
        <div className='card-toolbar'>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a vehicle'
          >
            <button
              type='button'
              className='btn btn-primary'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_vehicle_add'
              onClick={() => {
                setModal(true)
              }}
            >
              Add Vehicle
            </button>
          </div>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='row mb-8'>
          <div className='w-300px'>
            <input
              type='text'
              className='form-control'
              placeholder='Search'
              onChange={(e) => {
                updateVehicles((e.target as HTMLInputElement).value)
              }}
            />
          </div>
        </div>

        {searchQuery.length > 0 ? (
          filteredVehicles?.length ? (
            filteredVehicles?.map((product) => <VehicleRow vehicle={product} />)
          ) : (
            <div>No vehicles found</div>
          )
        ) : (
          products?.map((product) => <VehicleRow vehicle={product} />)
        )}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {VehicleTable}
