import {useMutation, useQueryClient} from 'react-query'
import {QUERIES_KEYS} from '../../../helpers/crud-helpers/consts'
import {deleteWorkshop} from '../core/_requests'

function useDeleteWorkshop() {
  const queryClient = useQueryClient()

  const {mutate, isLoading, isSuccess} = useMutation((id: string) => deleteWorkshop(id), {
    // ✅ Show success message
    onSuccess: () => {
      queryClient.invalidateQueries(QUERIES_KEYS.WORKSHOP_LIST)
    },
  })

  return {deleteWorkshop: mutate, isLoadingWorkshop: isLoading, isSuccessWorkshop: isSuccess}
}

export default useDeleteWorkshop
