import React from 'react'
import Spinner from 'react-bootstrap/Spinner'
export const CustomPageLoader = () => {
  return (
    <div className='d-flex justify-content-center align-items-center ' style={{height: '100%'}}>
      <Spinner animation='border' />
      <h3 style={{marginLeft: '3%'}}> Loading...</h3>{' '}
    </div>
  )
}
