import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'

import {useDataContext} from '../../../modules/data'
import {Dropdown} from 'react-bootstrap'
import Swal from 'sweetalert2'
import {IVehicle} from '../core/_models'
import useCreateVehicle from '../hooks/use-create-vehicle'
import useUpdateVehicle from '../hooks/use-update-vehicle'
import moment from 'moment'
const vehicleType = ['car', 'truck', 'ute', 'forklift', 'other']
const productDetailsSchema = Yup.object().shape({
  make: Yup.string()
    .matches(/^[A-Za-z\s]+$/, 'Make must contain only letters')
    .required('Make is required'),
  model: Yup.string().required('Model is Required'),
  year: Yup.number()
    .required('Year is required')
    .min(1950, 'Year must be greater than or equal to 1950')
    .max(moment().year(), `Year must be less than or equal to ${moment().year()}`),
  vehicleIdentificationNumber: Yup.number().required('VIN Price is Required'),
  // .test(
  //   'is-fourteen-digits',
  //   'Vehicle Identification Number must be a 14-digit number',
  //   (value) => {
  //     if (value === undefined) return true
  //     const stringValue = String(value)
  //     return stringValue.length === 14 && /^\d+$/.test(stringValue)
  //   }
  // )
  type: Yup.string().test(
    'is-vehicle',
    'Type selection is required',
    (value) => value !== 'Select Type'
  ),
  registrationNumber: Yup.string().required('Registration number is required'),
  startingOdoMeter: Yup.number()
    .required('Starting Odometer is Required')
    .positive('odometers must be positive'),
  days: Yup.number().required('Days are Required').positive('Days must be positive'),
  distance: Yup.number().required('Distance is Required').positive('Distance must be positive'),
})

type Props = {
  setShowModal: any
}

const VehicleForm: React.FC<Props> = ({setShowModal}) => {
  const {createVehicle, isErrorCreateVehicle, isLoadingCreateVehicle, isSuccessCreateVehicle} =
    useCreateVehicle()
  const {updateVehicle, isErrorUpdateVehicle, isLoadingUpdateVehicle, isSuccessUpdateVehicle} =
    useUpdateVehicle()
  const {vehicle, setVehicle} = useDataContext()

  const [SelectedVehicle, setSelectedVehicle] = useState(vehicle?.type)
  //console.log('vehicle is ', vehicle, vehicle?.type, SelectedVehicle)
  useEffect(() => {
    if (isSuccessCreateVehicle || isSuccessUpdateVehicle) {
      Swal.fire({
        title: 'Success',
        text: vehicle ? 'Updated Successfully!' : 'Created Successfully!',
        icon: 'success',
        confirmButtonText: 'Ok',
      }).then((result) => {
        setShowModal(false)
        setVehicle(undefined)
        formik.resetForm()
        setSelectedVehicle('Select Type')
      })
    } else if (isErrorUpdateVehicle || isErrorCreateVehicle) {
      Swal.fire({
        title: 'Error',
        text: vehicle ? 'Error Updating!' : 'Error Creating!',
        icon: 'error',
        confirmButtonText: 'Ok',
      }).then((result) => {
        setShowModal(false)
        setVehicle(undefined)
        formik.resetForm()
        setSelectedVehicle('Select Type')
      })
    }
  }, [isSuccessCreateVehicle, isSuccessUpdateVehicle, isErrorCreateVehicle, isErrorUpdateVehicle])

  const formik = useFormik<IVehicle>({
    enableReinitialize: true,
    initialValues: vehicle
      ? {
          ...vehicle,
          days: vehicle?.serviceInterval?.days,
          distance: vehicle?.serviceInterval?.distance,
        }
      : {
          make: '',
          model: '',
          year: '',
          vehicleIdentificationNumber: '',
          type: 'Select Type',
          registrationNumber: '',
          startingOdoMeter: 0,
          days: 0,
          distance: 0,
        },
    validationSchema: productDetailsSchema,
    onSubmit: (values) => {
      if (formik.values.days && formik.values.distance) {
        const serviceInterval = {
          days: formik.values.days,
          distance: formik.values.distance,
        }

        // Remove days and distance from formik values
        const {days, distance, ...formikValuesWithoutServiceInterval} = formik.values
        const finalizedVehicleData = {...formikValuesWithoutServiceInterval, serviceInterval}
        // Log the modified formik values and the service interval object
        // console.log('Formik values:', {...formikValuesWithoutServiceInterval, serviceInterval})

        vehicle ? updateVehicle(finalizedVehicleData) : createVehicle(finalizedVehicleData)
      }
    },
  })

  const handleTypeSelect = (type: string) => {
    formik.setFieldValue('type', type)
    //setSelectedVehicle(type)
  }
  return (
    <div className='modal fade' id='kt_modal_vehicle_add' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-space-between'>
            <h3 className='text-center'>Add Vehicle</h3>
            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              data-bs-dismiss='modal'
              style={{borderRadius: '50%', border: '1px solid'}}
              onClick={(e) => {
                e.preventDefault()
                setVehicle(undefined)
                setShowModal(false)
                formik.resetForm()
                setSelectedVehicle('Select Type')
              }}
            >
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <form onSubmit={formik.handleSubmit} noValidate className='form'>
              <div className='card-body border-top p-9'>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>Make</label>

                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='Make'
                          {...formik.getFieldProps('make')}
                        />
                        {formik.touched.make && formik.errors.make && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.make}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>Model</label>

                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='model'
                          {...formik.getFieldProps('model')}
                        />
                        {formik.touched.model && formik.errors.model && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.model}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>year</label>

                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='number'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='Year'
                          {...formik.getFieldProps('year')}
                        />
                        {formik.touched.year && formik.errors.year && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.year}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>VIN</label>

                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='number'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='Vehicle Identification Number'
                          {...formik.getFieldProps('vehicleIdentificationNumber')}
                        />
                        {formik.touched.vehicleIdentificationNumber &&
                          formik.errors.vehicleIdentificationNumber && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                {formik.errors.vehicleIdentificationNumber}
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    Vehicle Registration
                  </label>

                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='Registration Number'
                          {...formik.getFieldProps('registrationNumber')}
                        />
                        {formik.touched.registrationNumber && formik.errors.registrationNumber && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.registrationNumber}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>Type</label>

                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <Dropdown>
                          <Dropdown.Toggle variant='primary' id='dropdown-basic' className='w-100'>
                            {formik.values.type}
                          </Dropdown.Toggle>
                          <Dropdown.Menu className='w-100'>
                            {vehicleType?.map((type) => (
                              <Dropdown.Item key={type} onClick={() => handleTypeSelect(type)}>
                                {type}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                        {formik.touched.type && formik.errors.type && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.type}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>OdoMeter</label>

                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <div className='input-group'>
                          {/* <span className='input-group-text' style={{border: '0px'}}>
                            $
                          </span> */}
                          <input
                            type='number'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder='Enter starting odometer'
                            {...formik.getFieldProps('startingOdoMeter')}
                          />
                        </div>
                        {formik.touched.startingOdoMeter && formik.errors.startingOdoMeter && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.startingOdoMeter}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>Days</label>

                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <div className='input-group'>
                          {/* <span className='input-group-text' style={{border: '0px'}}>
                            $
                          </span> */}
                          <input
                            type='number'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder='Enter serivce interval in days'
                            {...formik.getFieldProps('days')}
                          />
                        </div>
                        {formik.touched.days && formik.errors.days && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.days}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>Distance</label>

                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <div className='input-group'>
                          <input
                            type='number'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder='Enter service interval in days'
                            {...formik.getFieldProps('distance')}
                          />
                          <span className='input-group-text h-auto' style={{border: '0px'}}>
                            KM
                          </span>
                        </div>
                        {formik.touched.distance && formik.errors.distance && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.distance}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='card-footer d-flex justify-content-end py-6 px-9 '>
                <button
                  type='submit'
                  className='btn btn-primary'
                  disabled={
                    isLoadingCreateVehicle ||
                    isLoadingUpdateVehicle ||
                    !formik.isValid ||
                    !formik.dirty
                  }
                  {...(isSuccessCreateVehicle || isSuccessUpdateVehicle
                    ? {'data-bs-dismiss': 'modal'}
                    : {'data-bs-dismiss': 'modal'})}
                >
                  {!isLoadingCreateVehicle &&
                    !isLoadingUpdateVehicle &&
                    (vehicle ? 'Update' : 'Add')}
                  {(isLoadingCreateVehicle || isLoadingUpdateVehicle) && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export {VehicleForm}
