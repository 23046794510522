/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useRef} from 'react'
import {KTIcon} from '../../../../../_metronic/helpers'
import {useThemeMode} from '../../../../../_metronic/partials'
import {getCSSVariableValue} from '../../../../../_metronic/assets/ts/_utils'
import moment from 'moment'
const statusMap = new Map([
  [0, 'CREATED'],
  [1, 'SCHEDULED'],
  [2, 'PENDING'],
  [3, 'ASSIGNED'],
  [4, 'WORK_IN_PROGRESS'],
  [5, 'PROBLEM_PENDING'],
  [6, 'AWAITING_RESOURCES'],
  [7, 'AWAITING_APPROVAL'],
  [8, 'AWAITING_CLOSURE'],
  [9, 'PAYMENT_PENDING'],
  [10, 'PAID'],
])
type Props = {
  className: string
  scheduledFor?: string | undefined
  startDate?: string | undefined
  endDate?: string | undefined
  creationDate: string
  payment?: any
  //timeLapse?:string|undefined
  status: number
  chartSize?: number
  chartLine?: number
  chartRotate?: number
}

const ServiceInfo: FC<Props> = ({
  className,
  scheduledFor,
  startDate,
  endDate,
  status,
  payment,
  creationDate,
  chartSize = 70,
  chartLine = 11,
  chartRotate = 145,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  // useEffect(() => {
  //   refreshChart()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [mode])

  // const refreshChart = () => {
  //   if (!chartRef.current) {
  //     return
  //   }

  //   setTimeout(() => {
  //     initChart(chartSize, chartLine, chartRotate)
  //   }, 10)
  // }
  function getStatusName(statusNumber: number) {
    return statusMap.get(statusNumber) || ' - ' // Return status name or 'Unknown' if not found
  }
  return (
    <div className={`card card-flush ${className}`}>
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column'>
          <div className='d-flex align-items-center'>
            <span className='fs-1hx fw-bold text-dark me-2 lh-1 ls-n2'>Status</span>
          </div>
          <span className='text-gray-400 pt-1 fw-semibold fs-6'>{getStatusName(status)}</span>
        </div>
        {payment && (
          <div className='card-title d-flex flex-column'>
            <div className='d-flex align-items-center'>
              <span className='fs-1hx  text-dark me-2 lh-1 ls-n2'>Payment Type</span>
            </div>
            <span className='text-gray-400 pt-1 fw-semibold fs-6'>{payment?.type}</span>
          </div>
        )}
      </div>

      <div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
        {/* <div className='d-flex flex-center me-5 pt-2'>
          <div
            id='kt_card_widget_17_chart'
            ref={chartRef}
            style={{minWidth: chartSize + 'px', minHeight: chartSize + 'px'}}
            data-kt-size={chartSize}
            data-kt-line={chartLine}
          ></div>
        </div> */}

        <div className='d-flex flex-column content-justify-center flex-row-fluid'>
          <div className='d-flex fw-semibold align-items-center'>
            <div className='bullet w-8px h-5px rounded-2 bg-success me-3'></div>
            <div className='text-gray-500 flex-grow-1 me-4'>Scheduled Date </div>
            <div className='fw-bolder text-gray-700 text-xxl-end'>
              {/* {scheduledFor || 'In progress'} */}
              {scheduledFor
                ? new Date(scheduledFor)
                    .toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    })
                    .replace(/\//g, '-')
                : '-'}
              {/* {new Date(scheduledFor).toLocaleDateString()} */}
            </div>
          </div>
          <div className='d-flex fw-semibold align-items-center my-6'>
            <div className='bullet w-8px h-5px rounded-2 bg-primary me-3'></div>
            <div className='text-gray-500 flex-grow-1 me-4'>Creation Date</div>
            <div className='fw-bolder text-gray-700 text-xxl-end'>
              {new Date(creationDate)
                .toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                })
                .replace(/\//g, '-') || '-'}
            </div>
          </div>
          <div className='d-flex fw-semibold align-items-center'>
            <div
              className='bullet w-8px h-5px rounded-2 me-3'
              style={{backgroundColor: '#E4E6EF'}}
            ></div>
            <div className='text-gray-500 flex-grow-1 me-4'>End Date</div>
            <div className=' fw-bolder text-gray-700 text-xxl-end'>
              {endDate ? moment(endDate).format('MM-DD-YYYY') : '-'}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// const initChart = function (
//   chartSize: number = 70,
//   chartLine: number = 11,
//   chartRotate: number = 145
// ) {
//   const el = document.getElementById('kt_card_widget_17_chart')
//   if (!el) {
//     return
//   }
//   el.innerHTML = ''

//   var options = {
//     size: chartSize,
//     lineWidth: chartLine,
//     rotate: chartRotate,
//     //percent:  el.getAttribute('data-kt-percent') ,
//   }

//   const canvas = document.createElement('canvas')
//   const span = document.createElement('span')

//   // @ts-ignore
//   if (typeof G_vmlCanvasManager !== 'undefined') {
//     // @ts-ignore
//     G_vmlCanvasManager.initElement(canvas)
//   }

//   const ctx = canvas.getContext('2d')
//   canvas.width = canvas.height = options.size

//   el.appendChild(span)
//   el.appendChild(canvas)

//   // @ts-ignore
//   ctx.translate(options.size / 2, options.size / 2) // change center
//   // @ts-ignore
//   ctx.rotate((-1 / 2 + options.rotate / 180) * Math.PI) // rotate -90 deg

//   //imd = ctx.getImageData(0, 0, 240, 240);
//   const radius = (options.size - options.lineWidth) / 2

//   const drawCircle = function (color: string, lineWidth: number, percent: number) {
//     percent = Math.min(Math.max(0, percent || 1), 1)
//     if (!ctx) {
//       return
//     }

//     ctx.beginPath()
//     ctx.arc(0, 0, radius, 0, Math.PI * 2 * percent, false)
//     ctx.strokeStyle = color
//     ctx.lineCap = 'round' // butt, round or square
//     ctx.lineWidth = lineWidth
//     ctx.stroke()
//   }

//   // Init 2
//   drawCircle('#E4E6EF', options.lineWidth, 100 / 100)
//   drawCircle(getCSSVariableValue('--bs-primary'), options.lineWidth, 100 / 150)
//   drawCircle(getCSSVariableValue('--bs-success'), options.lineWidth, 100 / 250)
// }

export {ServiceInfo}
