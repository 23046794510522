import axios from 'axios'
import {IWorkshop, IWorkshopQueryResponse} from './_models'

const WORKSHOPS = '/workshop'
const SERVICE_CHECKLIST = '/check'
export function getWorkshops(): Promise<IWorkshopQueryResponse | any> {
  return axios.get(WORKSHOPS).then((response) => response.data)
}
export function getServicesCheckList(): Promise<IWorkshopQueryResponse | any> {
  return axios.get(SERVICE_CHECKLIST).then((response) => response.data)
}
export async function getWorkshopById(id: string): Promise<IWorkshop | any> {
  if (id) {
    let result = await axios.get(`${WORKSHOPS}/${id}`).then((response) => response.data)
    //console.log('got workshop by id is ', result)
    return result
  }
}

export async function createWorkshop(body: IWorkshop): Promise<IWorkshopQueryResponse | undefined> {
  return await axios.post(WORKSHOPS, body).then((response) => response.data)
}
export function updateWorkshop(
  body: IWorkshop,
  id: string
): Promise<IWorkshopQueryResponse | undefined> {
  return axios.patch(`${WORKSHOPS}/${id}`, body)
}

export function deleteWorkshop(id: string) {
  return axios.delete(`${WORKSHOPS}/${id}`)
}
