/* eslint-disable jsx-a11y/anchor-is-valid */
import {CSSProperties, FC, useEffect, useRef} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import {toAbsoluteUrl} from '../../../helpers'
import {USER_ROLE} from 'app/constants/constants'
import {Button} from 'react-bootstrap'
function getRole(gotRole: string) {
  switch (gotRole) {
    case USER_ROLE.JUNIOR_MECHANIC:
      return 'Junior Mechanic'
    case USER_ROLE.WORKSHOP_OWNER:
      return 'Workshop Owner'
    case USER_ROLE.SERVICE_MANAGER:
      return 'Service Manager'
    case USER_ROLE.SENIOR_MECHANIC:
      return 'Senior Mechanic'
    case USER_ROLE.VEHICLE_OWNER:
      return 'Vehicle Owner'
    default:
      return 'Unknown'
  }
}
const menuStyles: CSSProperties = {
  width: '275px',
  backgroundColor: '#fff',
  borderRadius: '0.42rem',
  position: 'absolute',
  padding: '16px',
  fontSize: '1.25rem',
  marginTop: '250px',
  marginLeft: '-20px',
  // Add top margin
  boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)', // Add box shadow for better appearance
}

const menuItemStyles = {
  padding: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: '#000',
  textDecoration: 'none',
  fontSize: '1rem',
}

const separatorStyles = {
  margin: '8px 0',
  borderBottom: '1px solid #000',
}

const linkStyles = {
  color: '#000', // Black color
  textDecoration: 'none', // Remove underline
  transition: 'color 0.3s, transform 0.3s', // Add transition for smooth effect
}

type props = {
  setIsDropdownOpen: any
}
const HeaderUserMenu: FC<props> = ({setIsDropdownOpen}) => {
  const {currentUser, logout} = useAuth()
  const dropdownRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [setIsDropdownOpen])
  const handleMouseOver = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    const target = event.target as HTMLAnchorElement
    target.style.color = '#007bff'
    target.style.transform = 'scale(1.1)'
  }

  const handleMouseOut = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    const target = event.target as HTMLAnchorElement
    target.style.color = '#000'
    target.style.transform = 'scale(1.0)'
  }

  return (
    <div ref={dropdownRef} style={menuStyles}>
      <div style={menuItemStyles}>
        <div>
          <div style={{fontWeight: 'bold', fontSize: '1.25rem'}}>{currentUser?.data.user.name}</div>
          <div style={{fontSize: '1rem', color: '#6c757d'}}>{currentUser?.data.user.email}</div>
        </div>
      </div>
      <div style={menuItemStyles}>
        <span>Role:</span>
        <span>{getRole(currentUser?.data.user.role)}</span>
      </div>
      <div style={separatorStyles}></div>
      <div style={menuItemStyles}>
        <Link
          to='/profile'
          onClick={() => {
            setIsDropdownOpen(false)
          }}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          style={{...linkStyles}}
        >
          My Profile
        </Link>
      </div>
      <div style={menuItemStyles}>
        <Link
          to='/'
          onClick={() => {
            setIsDropdownOpen(false)
            logout()
          }}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          style={{...linkStyles}}
        >
          Sign Out
        </Link>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
