import axios from 'axios'
import {
  IAddInspection,
  IInspection,
  IUpdateInspection,
  IServiceQueryResponse,
} from './_inspectionModels'
const INSPECTION = '/inspection'

// export function getInspections(): Promise<IInspectionQueryResponse | any> {
//   return axios.get(INSPECTION).then((response) => response.data)
// }
export async function createInspection(body: IAddInspection) {
  let result = await axios.post(INSPECTION, body).then((response) => response.data)
  // console.log('ADDING INSPECTION RESPONSE', result)
  return result
}
export async function UpdateInspection(body: IUpdateInspection, _id: string | undefined) {
  // console.log('Updt API URL IS ', `${INSPECTION}/${_id}`, 'body to update', body, 'id', _id)
  let result = await axios.patch(`${INSPECTION}/${_id}`, body).then((response) => response.data)
  // console.log('Updt Patching INSPECTION RESPONSE', result)
  return result
}

export async function getInspectionByServiceId(id: string): Promise<any> {
  if (id) {
    // console.log('hooklp Got service id in getInspectionByServiceId', id)
    let result = await axios.get(`${INSPECTION}?service=${id}`).then((response) => response.data)
    // console.log('result by getInspectionByServiceId', result)
    return result
  }
}
