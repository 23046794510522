import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import {useDataContext} from '../../../modules/data'
import {Dropdown, Form} from 'react-bootstrap'
import Swal from 'sweetalert2'
import {IVehicle} from '../core/_models'
import useCreateVehicle from '../hooks/use-create-vehicle'
import useUpdateVehicle from '../hooks/use-update-vehicle'
const vehicleType = ['car', 'truck', 'ute', 'forklift', 'other']
const phoneNumberRegex = /^\+?\d+$/

const phoneNumberValidation = (phoneNumber) => {
  if (!phoneNumber) return true // Accept empty value if not required
  return phoneNumberRegex.test(phoneNumber) // Validate against regex
}

const phoneNumberErrorMessage = 'Please enter a valid phone number'
const productDetailsSchema = Yup.object().shape({
  make: Yup.string()
    .matches(/^[A-Za-z\s]+$/, 'Make must contain only letters')
    .required('Make is required'),
  model: Yup.string().required('Model is Required'),
  ownerName: Yup.string().required('User name is Required'),
  year: Yup.number()
    .required('Year is required')
    .min(1950, 'Year must be greater than or equal to 1950')
    .max(2024, 'Year must be less than or equal to current year'),
  contact: Yup.string()
    .test('phoneNumber', phoneNumberErrorMessage, phoneNumberValidation)
    .required('Phone number is required'),
  vehicleIdentificationNumber: Yup.number()
    .required('VIN Price is Required')
    .test(
      'is-fourteen-digits',
      'Vehicle Identification Number must be a 14-digit number',
      (value) => {
        if (value === undefined) return true
        const stringValue = String(value)
        return stringValue.length === 14 && /^\d+$/.test(stringValue)
      }
    ),
  //   type: Yup.string().test(
  //     'is-vehicle',
  //     'Type selection is required',
  //     (value) => value !== 'Select Type'
  //   ),
  //   registrationNumber: Yup.string().required('Registration number is required'),
  //   startingOdoMeter: Yup.number()
  //     .required('Starting Odometer is Required')
  //     .positive('odometers must be positive'),
  //   days: Yup.number().required('Days are Required').positive('Days must be positive'),
  //   distance: Yup.number().required('Distance is Required').positive('Distance must be positive'),
})

type Props = {
  setShowModal: any
}

export const VehicleFormByManager: React.FC<Props> = ({setShowModal}) => {
  const {createVehicle, isErrorCreateVehicle, isLoadingCreateVehicle, isSuccessCreateVehicle} =
    useCreateVehicle()
  const {updateVehicle, isErrorUpdateVehicle, isLoadingUpdateVehicle, isSuccessUpdateVehicle} =
    useUpdateVehicle()
  const {vehicle, setVehicle, setVehicleByServiceManager, modal, setModal} = useDataContext()
  const [namestate, setnamestate] = useState('sf')
  const [SelectedVehicle, setSelectedVehicle] = useState(vehicle?.type)
  // console.log('vehicle is ', vehicle, vehicle?.type, SelectedVehicle)
  //   useEffect(() => {
  //     if (isSuccessCreateVehicle || isSuccessUpdateVehicle) {
  //       Swal.fire({
  //         title: 'Success',
  //         text: vehicle ? 'Updated Successfully!' : 'Created Successfully!',
  //         icon: 'success',
  //         confirmButtonText: 'Ok',
  //       }).then((result) => {
  //         setShowModal(false)
  //         setVehicle(undefined)
  //         formik.resetForm()
  //         setSelectedVehicle('Select Type')
  //       })
  //     } else if (isErrorUpdateVehicle || isErrorCreateVehicle) {
  //       Swal.fire({
  //         title: 'Error',
  //         text: vehicle ? 'Error Updating!' : 'Error Creating!',
  //         icon: 'error',
  //         confirmButtonText: 'Ok',
  //       }).then((result) => {
  //         setShowModal(false)
  //         setVehicle(undefined)
  //         formik.resetForm()
  //         setSelectedVehicle('Select Type')
  //       })
  //     }
  //   }, [isSuccessCreateVehicle, isSuccessUpdateVehicle, isErrorCreateVehicle, isErrorUpdateVehicle])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ownerName: '',
      contact: '',
      make: '',
      model: '',
      year: '',
      vehicleIdentificationNumber: '',
      //   type: 'Select Type',
      //   registrationNumber: '',
      //   startingOdoMeter: 0,
      //   days: 0,
      //   distance: 0,
    },
    validationSchema: productDetailsSchema,
    onSubmit: (values) => {
      setVehicleByServiceManager(values)
      //vehicle ? updateVehicle(finalizedVehicleData) : createVehicle(finalizedVehicleData)
    },
  })
  const handleTypeSelect = (type: string) => {
    formik.setFieldValue('type', type)
    //setSelectedVehicle(type)
  }

  return (
    <></>
    // <Modal
    //   show={modal}
    //   onHide={() => {
    //     setModal(false)
    //   }}
    //   style={{minWidth: '950px', zIndex: 20213}}
    // >
    //   <Modal.Header closeButton style={{marginBottom: '15px'}}>
    //     <Modal.Title>Add Vehicle</Modal.Title>
    //   </Modal.Header>
    //   <Modal.Body>
    //     <Form>
    //       <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
    //         <Form.Label>Email address</Form.Label>
    //         <Form.Control type='text' placeholder='name@example.com' />
    //       </Form.Group>
    //       <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
    //         <Form.Label>Example textarea</Form.Label>
    //         <Form.Control as='textarea' rows={3} />
    //       </Form.Group>
    //     </Form>
    //   </Modal.Body>
    //   <Modal.Footer>
    //     <Button variant='secondary' onClick={()=>setModal(false)}>
    //       Close
    //     </Button>
    //     <Button variant='primary'>Save Changes</Button>
    //   </Modal.Footer>
    // </Modal>
  )
  {
    /* <div className='mw-950px '>
        <div className=' '>
         

          <div className='modal-body mx-0 mx-xl-10 pt-0 pb-15'>
            <form onSubmit={formik.handleSubmit} noValidate className='form'>
              <div className='card-body   '>
                <div className='row mb-6'>
                  <label className='col-lg-2 col-form-label required fw-bold fs-6'>Name</label>

                  <div className='col-lg-10'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          readOnly={false}
                          type='text'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='Enter vehicle owner name'
                          name='ownerName'
                          value={namestate}
                          onChange={(e) => {
                            setnamestate(e.target.value)
                          }}
                         
                        />
                        {formik.touched.ownerName && formik.errors.ownerName && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.ownerName}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-2 col-form-label required fw-bold fs-6'>
                    Phone Number
                  </label>

                  <div className='col-lg-10'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='Enter user phone number'
                          {...formik.getFieldProps('contact')}
                        />
                        {formik.touched.contact && formik.errors.contact && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.contact}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-2 col-form-label required fw-bold fs-6'>Make</label>

                  <div className='col-lg-10'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='Make'
                          {...formik.getFieldProps('make')}
                        />
                        {formik.touched.make && formik.errors.make && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.make}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-2 col-form-label required fw-bold fs-6'>Model</label>

                  <div className='col-lg-10'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='model'
                          {...formik.getFieldProps('model')}
                        />
                        {formik.touched.model && formik.errors.model && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.model}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-2 col-form-label required fw-bold fs-6'>year</label>

                  <div className='col-lg-10'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='number'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='Year'
                          {...formik.getFieldProps('year')}
                        />
                        {formik.touched.year && formik.errors.year && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.year}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-2 col-form-label required fw-bold fs-6'>VIN</label>

                  <div className='col-lg-10'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='number'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='Vehicle Identification Number'
                          {...formik.getFieldProps('vehicleIdentificationNumber')}
                        />
                        {formik.touched.vehicleIdentificationNumber &&
                          formik.errors.vehicleIdentificationNumber && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                {formik.errors.vehicleIdentificationNumber}
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='card-footer d-flex justify-content-end py-6 px-9 '>
                <button
                  type='submit'
                  className='btn btn-primary'
                  disabled={!formik.isValid || !formik.dirty}
                  data-bs-dismiss='modal'
                >
                  Add
                 
                </button>
              </div>
            </form>
          </div>
        </div>
      </div> 
     
    // <div className='modal fade' id='kt_modal_vehicle_add_by_service_manager' aria-hidden='true'>
    //   <div className='modal-dialog mw-650px'>
    //     <div className='modal-content'>
    //       <div className='modal-header pb-0 border-0 justify-content-space-between'>
    //         <h3 className='text-center'>Add Vehicle</h3>
    //         <div
    //           className='btn btn-sm btn-icon btn-active-color-primary'
    //           data-bs-dismiss='modal'
    //           style={{borderRadius: '50%', border: '1px solid'}}
    //   onClick={(e) => {
    //     e.preventDefault()

    //     setVehicle(undefined)
    //     setShowModal(false)
    //     setVehicleByServiceManager(undefined)
    //     formik.resetForm()
    //     setSelectedVehicle('Select Type')
    //   }}
    //         >
    //           <KTIcon iconName='cross' className='fs-1' />
    //         </div>
    //       </div>

    //       <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
    //         <form onSubmit={formik.handleSubmit} noValidate className='form'>
    //           <div className='card-body border-top p-9'>
    //             <div className='row mb-6'>
    //               <label className='col-lg-4 col-form-label required fw-bold fs-6'>Name</label>

    //               <div className='col-lg-8'>
    //                 <div className='row'>
    //                   <div className='col-lg-12 fv-row'>
    //                     <input
    //                       type='text'
    //                       className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
    //                       placeholder='Enter vehicle owner name'
    //                       {...formik.getFieldProps('ownerName')}
    //                     />
    //                     {formik.touched.ownerName && formik.errors.ownerName && (
    //                       <div className='fv-plugins-message-container'>
    //                         <div className='fv-help-block'>{formik.errors.ownerName}</div>
    //                       </div>
    //                     )}
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //             <div className='row mb-6'>
    //               <label className='col-lg-4 col-form-label required fw-bold fs-6'>
    //                 Phone Number
    //               </label>

    //               <div className='col-lg-8'>
    //                 <div className='row'>
    //                   <div className='col-lg-12 fv-row'>
    //                     <input
    //                       type='text'
    //                       className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
    //                       placeholder='Enter user phone number'
    //                       {...formik.getFieldProps('contact')}
    //                     />
    //                     {formik.touched.contact && formik.errors.contact && (
    //                       <div className='fv-plugins-message-container'>
    //                         <div className='fv-help-block'>{formik.errors.contact}</div>
    //                       </div>
    //                     )}
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //             <div className='row mb-6'>
    //               <label className='col-lg-4 col-form-label required fw-bold fs-6'>Make</label>

    //               <div className='col-lg-8'>
    //                 <div className='row'>
    //                   <div className='col-lg-12 fv-row'>
    //                     <input
    //                       type='text'
    //                       className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
    //                       placeholder='Make'
    //                       {...formik.getFieldProps('make')}
    //                     />
    //                     {formik.touched.make && formik.errors.make && (
    //                       <div className='fv-plugins-message-container'>
    //                         <div className='fv-help-block'>{formik.errors.make}</div>
    //                       </div>
    //                     )}
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>

    //             <div className='row mb-6'>
    //               <label className='col-lg-4 col-form-label required fw-bold fs-6'>Model</label>

    //               <div className='col-lg-8'>
    //                 <div className='row'>
    //                   <div className='col-lg-12 fv-row'>
    //                     <input
    //                       type='text'
    //                       className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
    //                       placeholder='model'
    //                       {...formik.getFieldProps('model')}
    //                     />
    //                     {formik.touched.model && formik.errors.model && (
    //                       <div className='fv-plugins-message-container'>
    //                         <div className='fv-help-block'>{formik.errors.model}</div>
    //                       </div>
    //                     )}
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>

    //             <div className='row mb-6'>
    //               <label className='col-lg-4 col-form-label required fw-bold fs-6'>year</label>

    //               <div className='col-lg-8'>
    //                 <div className='row'>
    //                   <div className='col-lg-12 fv-row'>
    //                     <input
    //                       type='number'
    //                       className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
    //                       placeholder='Year'
    //                       {...formik.getFieldProps('year')}
    //                     />
    //                     {formik.touched.year && formik.errors.year && (
    //                       <div className='fv-plugins-message-container'>
    //                         <div className='fv-help-block'>{formik.errors.year}</div>
    //                       </div>
    //                     )}
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>

    //             <div className='row mb-6'>
    //               <label className='col-lg-4 col-form-label required fw-bold fs-6'>VIN</label>

    //               <div className='col-lg-8'>
    //                 <div className='row'>
    //                   <div className='col-lg-12 fv-row'>
    //                     <input
    //                       type='number'
    //                       className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
    //                       placeholder='Vehicle Identification Number'
    //                       {...formik.getFieldProps('vehicleIdentificationNumber')}
    //                     />
    //                     {formik.touched.vehicleIdentificationNumber &&
    //                       formik.errors.vehicleIdentificationNumber && (
    //                         <div className='fv-plugins-message-container'>
    //                           <div className='fv-help-block'>
    //                             {formik.errors.vehicleIdentificationNumber}
    //                           </div>
    //                         </div>
    //                       )}
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>

    //           <div className='card-footer d-flex justify-content-end py-6 px-9 '>
    //             <button
    //               type='submit'
    //               className='btn btn-primary'
    //               disabled={!formik.isValid || !formik.dirty}
    //               data-bs-dismiss='modal'
    //             >
    //               Add
    //               {/* {(isLoadingCreateVehicle || isLoadingUpdateVehicle) && (
    //                 <span className='indicator-progress' style={{display: 'block'}}>
    //                   Please wait...{' '}
    //                   <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
    //                 </span>
    //               )} 
    //             </button>
    //           </div>
    //         </form>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  )
}

</Modal></div>
export {VehicleFormByManager}
{
  /**<div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>RN</label>

                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='Registration Number'
                          {...formik.getFieldProps('registrationNumber')}
                        />
                        {formik.touched.registrationNumber && formik.errors.registrationNumber && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.registrationNumber}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>Type</label>

                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <Dropdown>
                          <Dropdown.Toggle variant='primary' id='dropdown-basic' className='w-100'>
                            {formik.values.type}
                          </Dropdown.Toggle>
                          <Dropdown.Menu className='w-100'>
                            {vehicleType?.map((type) => (
                              <Dropdown.Item key={type} onClick={() => handleTypeSelect(type)}>
                                {type}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                        {formik.touched.type && formik.errors.type && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.type}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>OdoMeter</label>

                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <div className='input-group'>
                          {/* <span className='input-group-text' style={{border: '0px'}}>
                            $
                          </span> 
                          <input
                            type='number'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder='Enter starting odometer'
                            {...formik.getFieldProps('startingOdoMeter')}
                          />
                        </div>
                        {formik.touched.startingOdoMeter && formik.errors.startingOdoMeter && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.startingOdoMeter}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>Days</label>

                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <div className='input-group'>
                         
                          <input
                            type='number'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder='Enter serivce interval in days'
                            {...formik.getFieldProps('days')}
                          />
                        </div>
                        {formik.touched.days && formik.errors.days && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.days}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>Distance</label>

                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <div className='input-group'>
                         
                          <input
                            type='number'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder='Enter service interval in days'
                            {...formik.getFieldProps('distance')}
                          />
                        </div>
                        {formik.touched.distance && formik.errors.distance && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.distance}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                        </div> */
  }
}
