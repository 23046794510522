/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useCallback, useEffect, useState} from 'react'

import {CustomPageLoader} from 'app/modules/auth/components/CustomPageLoader'
import {CustomRow} from 'app/modules/apps/user-management/users-list/table/columns/CustomRow'
import {IService} from 'app/pages/service/core/_models'
import useService from 'app/pages/service/hooks/use-get-service'

export default function CustomerVehicle() {
  const {serviceData, isLoadingService} = useService()
  const [uniqueFilteredData, setUniqueFilteredData] = useState<IService[]>([])
  const [searchQuery, setSearchQuery] = useState('')
  const [initialFilteredData, setInitialfilteredData] = useState<IService[]>([])
  useEffect(() => {
    const uniqueIds = new Set()
    const filteredData: IService[] = serviceData?.filter((obj) => {
      if (obj && obj.vehicleOwner && !uniqueIds.has(obj.vehicleOwner._id)) {
        uniqueIds.add(obj?.vehicleOwner?._id)
        return true
      }
      return false
    })
    setInitialfilteredData(filteredData)
    setUniqueFilteredData(filteredData)
  }, [serviceData])
  const handleSearch = useCallback(
    (searchTerm: string) => {
      if (searchTerm.length !== 0) {
        return uniqueFilteredData.filter((service) =>
          service?.vehicle?.registrationNumber.toLowerCase().includes(searchTerm.toLowerCase())
        )
      } else {
        return initialFilteredData // Return the original data when search term is empty
      }
    },
    [uniqueFilteredData]
  )
  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target
    setSearchQuery(value)
    const filteredData = handleSearch(value)
    setUniqueFilteredData(filteredData) // Update the state with the filtered data
  }
  return (
    <div className={`card`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Vehicles</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 new products</span> */}
        </h3>
      </div>
      <div className='row mb-8'>
        <div className='input-group w-50 ms-10'>
          <span className='input-group-text'>
            <i className='fa fa-search'></i>
          </span>
          <div className=''></div>
          <input
            type='text'
            className='form-control'
            placeholder='Enter vehicle registration number to be searched'
            value={searchQuery}
            onChange={handleChangeSearch}
          />
          {searchQuery?.length ? (
            <span
              className='input-group-text'
              onClick={() => {
                setSearchQuery('')
                handleSearch('')
                setUniqueFilteredData(initialFilteredData)
              }}
            >
              <i className='far fa-window-close'></i>
            </span>
          ) : null}
        </div>
      </div>
      {!isLoadingService ? (
        uniqueFilteredData?.length > 0 ? (
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted bg-light'>
                    <th className='min-w-125px'>VIN</th>
                    <th className='min-w-200px'>Registration Number</th>
                    <th className='min-w-125px'>Make</th>
                    <th className='min-w-125px'>Model</th>
                    <th className='min-w-125px'>Year</th>
                    <th className='min-w-125px'>Type</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}

                <tbody>
                  {uniqueFilteredData?.map((service) => (
                    <tr>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <a className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                              {service?.vehicle?.vehicleIdentificationNumber}
                            </a>
                          </div>
                        </div>
                      </td>

                      <td>
                        <span className='fs-7 fw-semibold'>
                          {service?.vehicle?.registrationNumber}
                        </span>
                      </td>
                      <td>
                        <span className='fs-7 fw-semibold'>{service?.vehicle?.make}</span>
                      </td>
                      <td>
                        <span className='fs-7 fw-semibold'>{service?.vehicle?.model}</span>
                      </td>
                      <td>
                        <span className='fs-7 fw-semibold'>{service?.vehicle?.year}</span>
                      </td>
                      <td>
                        <span className='fs-7 fw-semibold'>{service?.vehicle?.type}</span>
                      </td>
                    </tr>
                  ))}
                </tbody>

                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
            {/* end::Table container */}
          </div>
        ) : (
          <p className='ms-10'>No Vehicles found</p>
        )
      ) : (
        <CustomPageLoader />
      )}
      {/* begin::Body */}
    </div>
  )
}
