import {useMutation, useQueryClient} from 'react-query'
import {QUERIES_KEYS} from '../../../helpers/crud-helpers/consts'

import {updateService} from '../core/_requests'
import {IUpdateService} from '../core/_models'
function useUpdateService() {
  const queryClient = useQueryClient()

  const {mutate, isLoading, isError, error, isSuccess} = useMutation(
    ({body, id}: {body: IUpdateService; id: string | undefined}) => {
      // const {_id, ...rest} = body
      return updateService(body, id)
    },
    {
      // ✅ Show success message
      onSuccess: () => {
        queryClient.invalidateQueries(QUERIES_KEYS.SERVICE_LIST)
      },
    }
  )
  return {
    updateService: mutate,
    isLoadingupdateService: isLoading,
    isErrorupdateService: isError,
    errorupdateService: error,
    isSuccessupdateService: isSuccess,
  }
}

export default useUpdateService
