/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {IconUserModel} from '../../../../app/modules/profile/ProfileModels'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers/'
import {useDataContext} from '../../../modules/data'
import useDeleteServiceCheckList from '../core/hooks/use-delete-service-checklist'

type Props = {
  workshop: any

  badgeColor: string
  users?: Array<IconUserModel>
}

const CheckListCard: FC<Props> = ({workshop, badgeColor, users = undefined}) => {
  const {setServiceCheckListId, setChecks} = useDataContext()
  const {modal, setModal} = useDataContext()
  const navigate = useNavigate()
  const {deleteServiceCheckList} = useDeleteServiceCheckList()

  const handleDelete = (id: string) => {
    deleteServiceCheckList(id)
  }
  return (
    <div className='card border border-2 border-gray-300 border-hover'>
      <div className='card-body p-9'>
        <div
          // data-bs-toggle='modal'
          // data-bs-target='#kt_modal_workshop_add'
          // onClick={() => {
          //   setWorkshop(workshop)
          //   setChecks(workshop.checks)
          // }}
          className='fs-3 fw-bolder text-dark'
        >
          {workshop.name}
        </div>
        <p className='text-gray-400 fw-bold fs-5 mt-1 mb-7'>{workshop?.checks?.join(',')}</p>

        <div className='float-end me-5 '>
          <button
            className='btn btn-icon btn-circle btn-success'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_servicechecklist_add'
            onClick={() => {
              setServiceCheckListId(workshop)
              setChecks(workshop.checks)
            }}
          >
            <KTIcon iconName='pencil' className='fs-3' />
            {/* <i className='fas fa-arrow-right'></i> */}
          </button>
          <button
            className='ms-2 btn btn-icon btn-circle btn-danger'
            onClick={() => {
              handleDelete(workshop?._id)
              //   setServiceCheckListId(workshop)
              //   setChecks(workshop.checks)
            }}
          >
            <KTIcon iconName='trash' className='fs-3' />
            {/* <i className='fas fa-arrow-right'></i> */}
          </button>
        </div>
      </div>
    </div>
  )
}

export {CheckListCard}
