import {FC} from 'react'

type Props = {
  photos: any[]
}

const ServiceImageCarousel = ({photos}) => {
  return photos && photos?.length > 0 ? (
    <div
      id='kt_carousel_1_carousel'
      className='carousel carousel-custom slide'
      data-bs-ride='carousel'
      data-bs-interval='8000'
    >
      <div className='d-flex align-items-center justify-content-between flex-wrap'>
        <span className='fs-4 fw-bold pe-2'>Photos</span>

        <ol className='p-0 m-0 carousel-indicators carousel-indicators-dots'>
          {photos?.map((photo, index) => (
            <li
              key={index}
              data-bs-target='#kt_carousel_1_carousel'
              data-bs-slide-to={index}
              className={index === 0 ? 'ms-1 active' : 'ms-1'}
            ></li>
          ))}
        </ol>
      </div>
      <div className='container carousel-inner pt-8'>
        <div className='row justify-content-center'>
          <div className='col-auto'>
            {photos?.map((photo, index) => (
              <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                <img src={photo} className='img-fluid h-300px'></img>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* <div className='carousel-inner pt-8 '>
        {photos?.map((photo, index) => (
          <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
            <img src={photo} className='h-300px'></img>
          </div>
        ))}
      </div> */}
    </div>
  ) : (
    <h1>No photos</h1>
  )
}

export {ServiceImageCarousel}
