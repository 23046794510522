import React, {useEffect, useState} from 'react'
import {Calendar, theme} from 'antd'
import type {CalendarProps} from 'antd'
import {CellRenderInfo} from 'rc-picker/lib/interface'
import {Dayjs} from 'dayjs'
import CustomCellRender from './CustomCellRender'
import useCalendar from '../core/hooks/use-calendar'
export default function CustomizedCalendar() {
  const {calendarData, isLoadingCalendarData} = useCalendar()
  const {token} = theme.useToken()
  const wrapperStyle: React.CSSProperties = {
    height: 400,
    marginLeft: '2%',
    border: `1px solid ${token.colorBorderSecondary}`,
    borderRadius: token.borderRadiusLG,
  }
  let dummyData = [
    {
      date: '2024-04-02',
      scheduled: 0,
      completed: 0,
    },
    {
      date: '2024-04-03',
      scheduled: 1,
      completed: 1,
    },
    {
      date: '2024-04-04',
      scheduled: 2,
      completed: 2,
    },
  ]
  //const [selectedStatus, setSelectedStatus] = useState({name: '', id: ''})
  // const letterDate = new Date()
  //   useEffect(() => {
  //     if (calendarData) {
  //       calendarData.forEach(({_id, date, name}: any) => {
  //         if (date.split('T')[0] === letterDate.toISOString().split('T')[0]) {
  //           setSelectedStatus((prevSelectedMood) => ({...prevSelectedMood, name, id: _id}))
  //         }
  //       })
  //     }
  //   }, [calendarData])
  const renderCustomCell:
    | ((date: Dayjs, info: CellRenderInfo<Dayjs>) => React.ReactNode)
    | undefined = (date) => <CustomCellRender value={date} serviceData={calendarData} />
  return (
    <div style={wrapperStyle} className='mb-5'>
      <h1 className='ms-8 mt-5'>Calendar</h1>
      <Calendar cellRender={renderCustomCell} fullscreen={false} />
    </div>
  )
}
