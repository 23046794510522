import {useEffect} from 'react'
import {Card2} from '../../../_metronic/partials/content/cards/Card2'
import {MixedWidget5} from '../../../_metronic/partials/widgets'
import {useDataContext} from '../../modules/data'

import {CustomPageLoader} from 'app/modules/auth/components/CustomPageLoader'
import useServiceCheckList from './core/hooks/use-service-checklist'
import ServiceCheckListTable from './components/checklistTable'
function ServicesList() {
  const {serviceChecklistData, isLoadingServiceChecklist} = useServiceCheckList()
  const {setServiceCheckList} = useDataContext()
  useEffect(() => {
    if (serviceChecklistData) {
      setServiceCheckList(serviceChecklistData)
    }
  }, [serviceChecklistData, setServiceCheckList])

  return isLoadingServiceChecklist ? (
    <CustomPageLoader />
  ) : (
    <>
      <ServiceCheckListTable workshops={serviceChecklistData} />
    </>
  )
}

export default ServicesList
