/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {useDataContext} from '../../../modules/data'
import Swal from 'sweetalert2'
import useDeleteProduct from '../core/hooks/use-delete-products'

type Props = {
  product: any
  sId: number
  workshopsData: any
}

const ProductRow: React.FC<Props> = ({product: productProp, sId, workshopsData}) => {
  const {product, setProduct} = useDataContext()
  const {modal, setModal} = useDataContext()
  const {deleteProduct, isSuccessProduct} = useDeleteProduct()

  useEffect(() => {}, [isSuccessProduct])

  const getWorkshopName = (id) => {
    if (workshopsData) {
      return workshopsData?.find((workshop) => workshop?._id === id)?.name
    }
    return null
  }

  return (
    <tr>
      <td>
        <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
          {sId}
        </a>
      </td>
      <td>
        <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
          {productProp?.name}
        </a>
      </td>
      <td>
        <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
          {productProp?.type}
        </a>
        {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>{productProp?.type}</span> */}
      </td>
      <td>
        <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
          {getWorkshopName(productProp?.workshop)}
        </a>
        {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>{productProp?.type}</span> */}
      </td>
      <td>
        <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
          $ {productProp?.cost}
        </a>
        {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>Web, UI/UX Design</span> */}
      </td>
      <td className='text-dark fw-bold text-hover-primary fs-6'>$ {productProp?.retailPrice}</td>
      <td>
        {productProp?.units && +productProp?.units > 0 ? (
          <span className='badge badge-light-success'>{productProp?.units}</span>
        ) : (
          <span className='badge badge-light-danger'>OutOfStock</span>
        )}
      </td>
      <td className='text-end'>
        <a
          href='#'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          data-bs-toggle='modal'
          data-bs-target='#kt_modal_product_add'
          onClick={() => {
            setProduct(productProp)
            setModal(true)
          }}
        >
          <KTIcon iconName='pencil' className='fs-3' />
        </a>
        <a
          href='#'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          onClick={() => {
            Swal.fire({
              title: 'Do you want to delete the product',
              showDenyButton: true,
              confirmButtonText: 'Yes',
              icon: 'warning',
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                deleteProduct(productProp._id)
                if (isSuccessProduct) {
                  Swal.fire({
                    title: 'Success',
                    text: 'Deleted Successfully',
                    icon: 'success',
                  })
                }
              } else if (result.isDenied) {
              }
            })
          }}
        >
          <KTIcon iconName='trash' className='fs-3' />
        </a>
      </td>
    </tr>
  )
}

export {ProductRow}
