const QUERIES_KEYS = {
  TEMPLATE_LIST: 'template-list',
  TESTS_LIST: 'tests-list',
  LIMITER_LIST: 'limiter-list',
  USERS_LIST: 'users-list',
  FILE_MANAGER_LIST: 'file-manager-list',
  PRODUCT_LIST: 'products-list',
  WORKSHOP_LIST: 'workshops-list',
  SERVICE_CHECK_LIST: 'service-checklist',
  VEHICLE_LIST: 'vehicle-list',
  SERVICE_LIST: 'service-list',
  INSPECTION_LIST: 'inspection-list',
}

export const PAGINATION_LIMIT = 10

export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY HH:mm:ss A'

type TYPE_QUERY_KEY = string | [string, ...Array<string | number | undefined>]

export {QUERIES_KEYS}
export type {TYPE_QUERY_KEY}

export const fileManagerPaths = {
  TEMPLATES: 'templates/',
}
