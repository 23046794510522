import {useMutation, useQueryClient} from 'react-query'

import {QUERIES_KEYS} from '../../../../helpers/crud-helpers/consts'
import {createProduct} from '../_request'
import {IProduct} from '../_models'

function useCreateProduct() {
  const queryClient = useQueryClient()

  const {mutate, isLoading, isError, isSuccess, error} = useMutation(
    (body: IProduct) => createProduct(body),
    {
      // ✅ Show success message
      onSuccess: () => {
        // console.log('Test created successfully')
        queryClient.invalidateQueries(QUERIES_KEYS.PRODUCT_LIST)
      },
    }
  )

  return {
    createProduct: mutate,
    isLoadingCreateProduct: isLoading,
    isErrorCreateProduct: isError,
    isSuccessCreateProduct: isSuccess,
    createProductError: error,
  }
}

export default useCreateProduct
