import {useQuery} from 'react-query'

import {QUERIES_KEYS} from '../../../helpers/crud-helpers/consts'
import {getServices} from '../core/_requests'

function useService() {
  const {data, isLoading, isFetching, isSuccess} = useQuery(
    QUERIES_KEYS.SERVICE_LIST,
    getServices,
    {
      select: (res) => res.data,
      refetchOnWindowFocus: false,
    }
  )

  return {
    serviceData: data,
    isLoadingService: isLoading || isFetching,
    isSuccessgetService: isSuccess,
  }
}

export default useService
