/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import useCreateInspection from '../../hooks/inspection-hooks/use-create-inspection'
import {getCurrentDate} from 'app/helpers'
import Swal from 'sweetalert2'
import {useDataContext} from 'app/modules/data'
import {useAuth} from 'app/modules/auth'
import {USER_ROLE} from 'app/constants/constants'
import useInspectionByServiceId from '../../hooks/inspection-hooks/use-get-insepection-by-serviceid'
import {CustomPageLoader} from 'app/modules/auth/components/CustomPageLoader'
type Props = {
  className: string
  serviceId: string | undefined
  serviceStatus: number | undefined
  mechanicId: string | undefined
  service: any
}
function getStatusDescription(status: number) {
  switch (status) {
    case 0:
      return 'Pending'
    case 1:
      return 'Failed'
    case 2:
      return 'Completed'
    default:
      return 'Unknown'
  }
}
const dummyInspections = [
  {
    mechanic: '65a123ce8af6c43904ccd874',
    service: '65c316f2da3002731f090e7e',
    checks: [
      {
        name: 'Check 1',
        status: 0,
      },
      {
        name: 'Check 2',
        status: 0,
      },
      {
        name: 'Check 3',
        status: 0,
      },
      {
        name: 'check 4',
        status: 0,
      },
    ],
    status: 0,
    isDeleted: false,
    notes: '',
    _id: '65c5b846c172c23057f1ada4',
    createdAt: '2024-02-09T05:29:42.166Z',
    updatedAt: '2024-02-09T05:29:42.166Z',
    __v: 0,
  },
]
const ServiceInspection: React.FC<Props> = ({
  mechanicId,
  serviceStatus,
  serviceId,
  className,
  service,
}) => {
  const {inspectionData, isLoadingInspection, isGetInspectionSuccess, isGetInspectionError} =
    useInspectionByServiceId(serviceId)

  // console.log(serviceId, 'hooklp got inspection in component ', inspectionData)
  const {
    createInspection,
    isErrorCreateInspection,
    isLoadingCreateInspection,
    isSuccessCreateInspection,
    newInspection,
  } = useCreateInspection()
  //const [callCreate, setCallCreate] = useState(true)
  const {setInspection, callCreate, setCallCreate, setService} = useDataContext()
  //console.log('inspections received', inspections)
  const {currentUser} = useAuth()
  const isSeniorMechanic = currentUser?.data.user.role === USER_ROLE.SENIOR_MECHANIC
  //console.log('user in inspection table', currentUser?.data.user.role)
  //console.log('mechanic id ', mechanicId, 'user id ', currentUser?.data.user._id)
  // const handleAddInspection = async () => {
  //   if (callCreate === true) {
  //     console.log('newInspection call made')
  //     await createInspection({service: serviceId || 'undefined', date: getCurrentDate()})
  //     setCallCreate(false)
  //     const newInspectionButton = document.querySelector(
  //       '.btn.btn-sm.btn-light-primary'
  //     ) as HTMLButtonElement
  //     if (newInspectionButton) {
  //       newInspectionButton.setAttribute('data-bs-toggle', 'null')
  //       newInspectionButton.setAttribute('data-bs-target', 'null')
  //       setCallCreate(false)
  //     }
  //   }
  // }
  const handleAddInspection = async () => {
    if (callCreate == true) {
      // console.log('newInspection call made')
      await createInspection({service: serviceId || 'undefined', date: getCurrentDate()})

      setCallCreate(false)
    }
    const newInspectionButton = document.querySelector(
      '.btn.btn-sm.btn-light-primary'
    ) as HTMLButtonElement
    if (newInspectionButton) {
      newInspectionButton.setAttribute('data-bs-toggle', 'null')
      newInspectionButton.setAttribute('data-bs-target', 'null')
    }
  }

  const handleUpdateInspection = (inspection) => {
    //console.log('newInspection set', inspection)
    setService(service)
    setCallCreate(false)
    setInspection(inspection)
  }
  useEffect(() => {
    if (isErrorCreateInspection) {
      Swal.fire({
        title: 'Failed',
        text: 'Error creating',
        icon: 'error',
        confirmButtonText: 'Ok',
      }).then((result) => {
        // console.log('Fire result ', result)
      })
    } else if (isSuccessCreateInspection) {
      //console.log('newInspection', newInspection?.data)
      if (newInspection.data) {
        handleUpdateInspection(newInspection?.data)
        const newInspectionButton = document.querySelector(
          '.btn.btn-sm.btn-light-primary'
        ) as HTMLButtonElement
        if (newInspectionButton) {
          newInspectionButton.setAttribute('data-bs-toggle', 'modal')
          newInspectionButton.setAttribute('data-bs-target', '#kt_modal_update_inspection')
          newInspectionButton.click()
        }
      }
    }
  }, [isSuccessCreateInspection, isErrorCreateInspection, newInspection?.data])

  // useEffect(() => {
  //   getInspections()
  // }, [serviceId, isSuccessCreateInspection])
  return (
    <div className={`card ${className} overflow-y-auto `}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Service Inspections</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 members</span> currentUser?.data.user._id !== mechanicId */}
        </h3>
        {isSeniorMechanic && serviceStatus == 7 && (
          <div
            className='card-toolbar'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click To Add Inspection'
          >
            <a
              onClick={handleAddInspection}
              className='btn btn-sm btn-light-primary'
              data-bs-toggle={null}
              data-bs-target={null}
            >
              <KTIcon iconName='plus' className='fs-3' />
              New Inspection
            </a>
          </div>
        )}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          {isGetInspectionSuccess && (
            <>
              {inspectionData?.length ? (
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='fw-bold text-muted'>
                      <th className='w-25px'>
                        {/* <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div> */}
                      </th>
                      <th className='min-w-100px'>S. No</th>
                      <th className='min-w-140px'>Created On</th>
                      {/* <th className='min-w-120px'>Updated On</th> */}
                      <th className='min-w-100px '>Status</th>
                      <th className='min-w-100px text-end'>Action</th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    {inspectionData?.map((inspection, index) => {
                      return (
                        <tr
                          key={index}
                          {...(isSeniorMechanic &&
                            inspection.status === 0 &&
                            serviceStatus === 7 &&
                            {
                              // onClick: () => handleUpdateInspection(inspection),
                              // 'data-bs-toggle': 'modal',
                              // 'data-bs-target': '#kt_modal_update_inspection',
                              // 'data-bs-trigger': 'hover',
                              // cursor: 'pointer',
                              // title: 'Click To View Inspection',
                            })}
                        >
                          <td>
                            {/* <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input widget-9-check'
                          type='checkbox'
                          value='1'
                        />
                      </div> */}
                          </td>
                          <td>
                            <div className='d-flex align-items-center'>
                              {/* <div className='symbol symbol-45px me-5'>
                      <img src={toAbsoluteUrl('/media/avatars/300-14.jpg')} alt='' />
                    </div> */}
                              <div className='d-flex justify-content-start flex-column'>
                                <a className='text-dark  text-hover-primary fs-6'>{index + 1}</a>
                                {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        HTML, JS, ReactJS
                      </span> */}
                              </div>
                            </div>
                          </td>
                          <td>
                            <a className='text-dark fw-bold text-hover-primary d-block fs-6'>
                              {new Date(inspection.createdAt).toLocaleDateString() +
                                '-' +
                                new Date(inspection.createdAt).toLocaleTimeString()}
                            </a>
                            {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>
                    Web, UI/UX Design
                  </span> */}
                          </td>
                          {/* <td className='text-end'>
                  <div className='d-flex flex-column w-100 me-2'>
                    <div className='d-flex flex-stack mb-2'>
                      <span className='text-muted me-2 fs-7 fw-semibold'>50%</span>
                    </div>
                    <div className='progress h-6px w-100'>
                      <div
                        className='progress-bar bg-primary'
                        role='progressbar'
                        style={{width: '50%'}}
                      ></div>
                    </div>
                  </div>
                </td> */}
                          <td>
                            <div className='d-flex justify-content-start flex-shrink-0'>
                              <div className='d-flex flex-stack mb-2'>
                                <span className='text-muted me-2 fs-7 fw-semibold'>
                                  {getStatusDescription(inspection.status)}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                              <div className='d-flex flex-stack '>
                                <a
                                  onClick={() => handleUpdateInspection(inspection)}
                                  data-bs-toggle='modal'
                                  data-bs-target='#kt_modal_update_inspection'
                                  data-bs-trigger='hover'
                                  style={{cursor: 'pointer'}}
                                  title='Click To View Inspection'
                                  className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 '
                                >
                                  View
                                </a>
                                {/* <span className='text-muted me-2 fs-7 fw-semibold'>
                                  {getStatusDescription(inspection.status)}
                                </span> */}
                              </div>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                    {/* <tr>
                <td>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                  </div>
                </td>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px me-5'>
                      <img src={toAbsoluteUrl('/media/avatars/300-2.jpg')} alt='' />
                    </div>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                        Jessie Clarcson
                      </a>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        C#, ASP.NET, MS SQL
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    Agoda
                  </a>
                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                    Houses &amp; Hotels
                  </span>
                </td>
                <td className='text-end'>
                  <div className='d-flex flex-column w-100 me-2'>
                    <div className='d-flex flex-stack mb-2'>
                      <span className='text-muted me-2 fs-7 fw-semibold'>70%</span>
                    </div>
                    <div className='progress h-6px w-100'>
                      <div
                        className='progress-bar bg-danger'
                        role='progressbar'
                        style={{width: '70%'}}
                      ></div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-end flex-shrink-0'>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <KTIcon iconName='switch' className='fs-3' />
                    </a>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <KTIcon iconName='pencil' className='fs-3' />
                    </a>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                    >
                      <KTIcon iconName='trash' className='fs-3' />
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                  </div>
                </td>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px me-5'>
                      <img src={toAbsoluteUrl('/media/avatars/300-5.jpg')} alt='' />
                    </div>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                        Lebron Wayde
                      </a>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        PHP, Laravel, VueJS
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    RoadGee
                  </a>
                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                    Transportation
                  </span>
                </td>
                <td className='text-end'>
                  <div className='d-flex flex-column w-100 me-2'>
                    <div className='d-flex flex-stack mb-2'>
                      <span className='text-muted me-2 fs-7 fw-semibold'>60%</span>
                    </div>
                    <div className='progress h-6px w-100'>
                      <div
                        className='progress-bar bg-success'
                        role='progressbar'
                        style={{width: '60%'}}
                      ></div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-end flex-shrink-0'>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <KTIcon iconName='switch' className='fs-3' />
                    </a>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <KTIcon iconName='pencil' className='fs-3' />
                    </a>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                    >
                      <KTIcon iconName='trash' className='fs-3' />
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                  </div>
                </td>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px me-5'>
                      <img src={toAbsoluteUrl('/media/avatars/300-20.jpg')} alt='' />
                    </div>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                        Natali Goodwin
                      </a>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        Python, PostgreSQL, ReactJS
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    The Hill
                  </a>
                  <span className='text-muted fw-semibold text-muted d-block fs-7'>Insurance</span>
                </td>
                <td className='text-end'>
                  <div className='d-flex flex-column w-100 me-2'>
                    <div className='d-flex flex-stack mb-2'>
                      <span className='text-muted me-2 fs-7 fw-semibold'>50%</span>
                    </div>
                    <div className='progress h-6px w-100'>
                      <div
                        className='progress-bar bg-warning'
                        role='progressbar'
                        style={{width: '50%'}}
                      ></div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-end flex-shrink-0'>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <KTIcon iconName='switch' className='fs-3' />
                    </a>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <KTIcon iconName='pencil' className='fs-3' />
                    </a>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                    >
                      <KTIcon iconName='trash' className='fs-3' />
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                  </div>
                </td>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px me-5'>
                      <img src={toAbsoluteUrl('/media/avatars/300-23.jpg')} alt='' />
                    </div>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                        Kevin Leonard
                      </a>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        HTML, JS, ReactJS
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    RoadGee
                  </a>
                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                    Art Director
                  </span>
                </td>
                <td className='text-end'>
                  <div className='d-flex flex-column w-100 me-2'>
                    <div className='d-flex flex-stack mb-2'>
                      <span className='text-muted me-2 fs-7 fw-semibold'>90%</span>
                    </div>
                    <div className='progress h-6px w-100'>
                      <div
                        className='progress-bar bg-info'
                        role='progressbar'
                        style={{width: '90%'}}
                      ></div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-end flex-shrink-0'>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <KTIcon iconName='switch' className='fs-3' />
                    </a>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <KTIcon iconName='pencil' className='fs-3' />
                    </a>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                    >
                      <KTIcon iconName='trash' className='fs-3' />
                    </a>
                  </div>
                </td> 
             </tbody> </tr>*/}
                  </tbody>
                  {/* end::Table body */}
                </table>
              ) : (
                <>{isLoadingInspection ? <CustomPageLoader /> : 'no record found'}</>
              )}
            </>
          )}
          {isGetInspectionError && <div>Error getting inspections</div>}
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {ServiceInspection}
