import React, {useEffect} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {useDataContext} from 'app/modules/data'
import {NotesConverter} from './NotesConverter'
type Props = {
  setShowModal: any
}
const NotesForm: React.FC<Props> = ({setShowModal}) => {
  const {invoiceUrl} = useDataContext()
  const {notes} = useDataContext()
  //console.log('Note nbnbnb => ', notes)
  return (
    <>
      <div className='modal fade' id='kt_modal_Service_User_Notes' aria-hidden='true'>
        <div className='modal-dialog mw-650px'>
          <div className='modal-content'>
            <div className='modal-header pb-0 border-0 justify-content-space-between'>
              <h3 className='text-center'>Note</h3>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary'
                data-bs-dismiss='modal'
                onClick={() => {}}
              >
                <KTIcon iconName='cross' className='fs-1' />
              </div>
            </div>

            <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
              <NotesConverter
                data={
                  notes == 'invoiceNote'
                    ? invoiceUrl !== undefined
                      ? ''
                      : 'No notes found'
                    : notes || 'No notes found'
                }
              />
              <div>
                {invoiceUrl !== undefined && (
                  <a
                    href={`${process.env.REACT_APP_INVOICE_URL}/${invoiceUrl}`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    View Invoice
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {NotesForm}
//http://65ce2889e1c3b0938cf16c45.pdf
