/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useDataContext} from '../../../modules/data'
import Swal from 'sweetalert2'
import {useNavigate} from 'react-router-dom'
import {IService} from '../core/_models'
import {useAuth} from 'app/modules/auth'
import useUpdateService from '../hooks/use-update-service'
import {USER_ROLE} from 'app/constants/constants'
import useCreateInvoiceService from '../hooks/inspection-hooks/use-service-invoice-generation'
import {ServiceActionDropdown} from './service-detail-components/ServiceActionDropdown'

type Props = {
  service: IService
}
const statusMap = new Map([
  [0, 'CREATED'],
  [1, 'SCHEDULED'],
  [2, 'PENDING'],
  [3, 'ASSIGNED'],
  [4, 'WORK_IN_PROGRESS'],
  [5, 'PROBLEM_PENDING'],
  [6, 'AWAITING_RESOURCES'],
  [7, 'AWAITING_APPROVAL'],
  [8, 'AWAITING_CLOSURE'],
  [9, 'PAYMENT_PENDING'],
  [10, 'PAID'],
])
const ServiceRow: React.FC<Props> = ({service: serviceProp}) => {
  const {currentUser} = useAuth()
  const {setService} = useDataContext()
  const {generateInvoice} = useCreateInvoiceService()
  const role = currentUser?.data.user.role
  const {
    updateService,
    isLoadingupdateService,
    errorupdateService,
    isErrorupdateService,
    isSuccessupdateService,
  } = useUpdateService()
  // console.log('User role ', role)
  const navigate = useNavigate()
  function getStatusName(statusNumber: number) {
    return statusMap.get(statusNumber) || 'Unknown' // Return status name or 'Unknown' if not found
  }

  const handleServiceSchedule = () => {
    // updateService({
    //   id: serviceProp._id,
    //   body: {status: 1},
    // })
    setService(serviceProp)
  }
  const handleServicePlay = () => {
    //api call to set status to 4-work in progress
    updateService({
      id: serviceProp._id,
      body: {status: 4, fixedBy: currentUser?.data.user._id},
    })
  }
  const handleServiceAwaitingApproval = () => {
    //api call to set status to 7-awaiting approval
    updateService({
      id: serviceProp._id,
      body: {status: 7, fixedBy: currentUser?.data.user._id},
    })
  }
  const handleServiceProducts = () => {
    //set workshop products and service products in context
    setService(serviceProp)
  }
  return (
    <tr>
      <td>
        <div className='d-flex align-items-center'>
          <div className='symbol symbol-50px me-5'></div>
          <div className='d-flex justify-content-start flex-column'>
            <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
              {serviceProp.vehicleIdentificationNumber}
            </a>
            <span className='text-muted fw-semibold text-muted d-block fs-7'></span>
          </div>
        </div>
      </td>
      <td>
        <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
          {serviceProp?.fixedBy?.name || '-'}
        </a>
        <span className='text-muted fw-semibold text-muted d-block fs-7'>
          {serviceProp?.fixedBy?.role || '-'}
        </span>
      </td>
      <td>
        <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
          {new Date(serviceProp.createdAt)
            .toLocaleDateString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })
            .replace(/\//g, '-') || '-'}
        </a>
        <span className='text-muted fw-semibold text-muted d-block fs-7'>
          Scheduled For :{' '}
          {serviceProp?.scheduledFor
            ? new Date(serviceProp?.scheduledFor)
                .toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                })
                .replace(/\//g, '-')
            : '-'}
        </span>
      </td>
      <td>
        <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
          {!serviceProp?.partialUserDetails
            ? serviceProp?.vehicleOwner?.name || '-'
            : serviceProp?.partialUserDetails.name}
        </a>
        <span className='text-muted fw-semibold text-muted d-block fs-7'>
          {!serviceProp?.partialVehicleDetails
            ? serviceProp.vehicle?.year +
              ' - ' +
              serviceProp.vehicle?.make +
              ' - ' +
              serviceProp.vehicle?.model
            : serviceProp?.partialVehicleDetails?.year +
              ' - ' +
              serviceProp?.partialVehicleDetails?.make +
              ' - ' +
              serviceProp?.partialVehicleDetails?.model}
        </span>
      </td>
      <td>
        <span className='fs-7 fw-semibold'>
          {serviceProp?.workshop ? serviceProp?.workshop?.name : null}
        </span>
      </td>
      <td>
        <span className='badge badge-light-danger fs-7 fw-semibold'>
          {getStatusName(serviceProp.status)}
        </span>
      </td>
      {}
      {role !== USER_ROLE.SUPER_USER && (
        <td className='text-end'>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <ServiceActionDropdown role={role} serviceProp={serviceProp} />
          </div>
        </td>
      )}
    </tr>
  )
}

export {ServiceRow}
