import React, {useState, useRef, ChangeEvent, KeyboardEvent, useEffect} from 'react'

const OtpBoxes = ({formik}) => {
  const [otp, setOtp] = useState(['', '', '', '', '', ''])
  const otpInputRefs = useRef<Array<HTMLInputElement | null>>(Array(6).fill(null))

  useEffect(() => {
    // Set initial OTP if provided by Formik
    if (formik.values.otp && formik.values.otp.length === 6) {
      setOtp(formik.values.otp.split(''))
    }
  }, [formik.values.otp])

  const handleInputChange = (index: number, value: string) => {
    if (value.match(/^\d$/) || value === '') {
      const newOtp = [...otp]
      newOtp[index] = value
      setOtp(newOtp)

      // Move to the next input box
      if (value !== '' && index < otp.length - 1 && otpInputRefs.current[index + 1]) {
        otpInputRefs.current[index + 1]?.focus()
      }

      // Update the Formik values
      const newOtpString = newOtp.join('')
      formik.handleChange({target: {name: 'otp', value: newOtpString}})
    }
  }

  const handleBackspace = (index: number, event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace' && index > 0 && otp[index] === '') {
      otpInputRefs.current[index - 1]?.focus()
    }
  }

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault()
    const pastedData = event.clipboardData.getData('text/plain').slice(0, 6)
    const newOtp = pastedData.split('').concat(Array(6 - pastedData.length).fill(''))
    setOtp(newOtp)

    // Update the Formik values
    formik.handleChange({target: {name: 'otp', value: pastedData}})
  }

  return (
    <div className='otp-boxes d-flex justify-content-between'>
      {otp?.map((digit, index) => (
        <input
          key={index}
          ref={(ref) => (otpInputRefs.current[index] = ref)}
          type='text'
          className='otp-box form-control'
          style={{width: '3em'}}
          value={digit}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(index, e.target.value)}
          onBlur={formik.handleBlur}
          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => handleBackspace(index, e)}
          onPaste={handlePaste}
        />
      ))}
    </div>
  )
}

export default OtpBoxes
