import {useDataContext} from 'app/modules/data'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import useUpdateService from '../../hooks/use-update-service'
import moment from 'moment'
import Swal from 'sweetalert2'
import {KTIcon} from '_metronic/helpers'
const productDetailsSchema = Yup.object().shape({
  type: Yup.string().required('Date is required'),
})
export enum PAYMENT_TYPE {
  DEBIT_CARD = 'DEBIT_CARD',
  CASH = 'CASH',
  CREDIT_CARD = 'CREDIT_CARD',
}
export const AddPaymentDetails = () => {
  const {service} = useDataContext()
  const {
    updateService,
    isLoadingupdateService,
    errorupdateService,
    isErrorupdateService,
    isSuccessupdateService,
  } = useUpdateService()
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: PAYMENT_TYPE.CASH,
      amount: 0,
      paymentDate: moment(Date.now()).format('YYYY-MM-DD'),
    },
    validationSchema: productDetailsSchema,
    onSubmit: async (values) => {
      await updateService({
        body: {
          paymentDetails: {
            amount: formik?.values?.amount,
            type: formik?.values?.type,
            paymentDate: moment(Date.now()).format('YYYY-MM-DD'),
          },
          status: 10,
        },
        id: service?._id,
      })

      if (isErrorupdateService) {
        Swal.fire({
          title: 'Error',
          text: 'Error Updating payment details. Try again',
          icon: 'error',
          confirmButtonText: 'Ok',
        }).then((result) => {
          console.log('Fire error result ', result)
        })
      } else if (isSuccessupdateService) {
        Swal.fire({
          title: 'Success',
          text: 'updated Successfully!',
          icon: 'success',
          confirmButtonText: 'Done',
        }).then((result) => {
          formik.resetForm()
        })
      }
    },
  })

  return (
    <div className='modal fade' id='kt_modal_service_Payment_Details' aria-hidden='true'>
      <div className='modal-dialog mw-750px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-space-between'>
            <h3 className='text-center'>Payment Details</h3>
            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              data-bs-dismiss='modal'
              onClick={(e) => {
                e.preventDefault()
                formik.resetForm()
              }}
              style={{borderRadius: '50%', border: '1px solid'}}
            >
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <form onSubmit={formik.handleSubmit} noValidate className='form'>
              <div className='card-body border-top p-5'>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>Type</label>

                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <select
                          {...formik.getFieldProps('type')}
                          aria-label='Select Payment Type'
                          data-control='select2'
                          className='form-select form-select-solid'
                        >
                          <option value={PAYMENT_TYPE.CASH}>{PAYMENT_TYPE.CASH}</option>

                          <option value={PAYMENT_TYPE.CREDIT_CARD}>
                            {PAYMENT_TYPE.CREDIT_CARD}
                          </option>
                          <option value={PAYMENT_TYPE.DEBIT_CARD}>{PAYMENT_TYPE.DEBIT_CARD}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>Amount</label>

                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='number'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='Enter amount'
                          {...formik.getFieldProps('amount')}
                        />
                        {formik.touched.amount && formik.errors.amount && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.amount}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='card-footer d-flex justify-content-end py-6 px-5 '>
                <button
                  type='submit'
                  className='btn btn-primary'
                  data-bs-dismiss='modal'
                  disabled={isLoadingupdateService || !formik.isValid || !formik.dirty}
                  // {...(isSuccessupdateService && {'data-bs-dismiss': 'modal'})}
                >
                  Submit
                  {isLoadingupdateService && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
