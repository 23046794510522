import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {IProduct} from '../core/_models'
import {useDataContext} from '../../../modules/data'
import useCreateProduct from '../core/hooks/userCreateProduct'
import useUpdateProduct from '../core/hooks/use-update-product'
import Swal from 'sweetalert2'
import {Dropdown} from 'react-bootstrap'
import useWorkshop from 'app/pages/workshop/hooks/use-workshop'
import {useAuth} from 'app/modules/auth'
import {USER_ROLE} from 'app/constants/constants'
const productDetailsSchema = Yup.object().shape({
  name: Yup.string().required('First name is required'),
  unitType: Yup.string().required('Unit Type is required'),
  units: Yup.number().required('Units are Required').positive('Units must be a positive number'),
  cost: Yup.number().required('Cost is Required').positive('Cost must be a positive number'),
  retailPrice: Yup.number()
    .required('Retail Price is Required')
    .positive('Retail Price must be a positive number'),
  type: Yup.string().required('Type is Required'),
  brand: Yup.string().required('brand is Required'),
  workshop: Yup.string().required('Workshop selection is required'),
})

type Props = {
  setShowModal: any
}

const initialData = {
  types: ['car', 'truck', 'ute', 'forklift', 'other'],
  initialSelectedWorkshop: 'Select Workshop',
  initialSelectedType: 'Select type',
}
const ProductForm: React.FC<Props> = ({setShowModal}) => {
  const {currentUser} = useAuth()
  const {
    createProduct,
    isSuccessCreateProduct,
    createProductError,
    isLoadingCreateProduct,
    isErrorCreateProduct,
  } = useCreateProduct()
  const {updateProduct, isSuccessUpdateProduct, isLoadingUpdateProduct, isErrorUpdateProduct} =
    useUpdateProduct()
  const {product, setProduct} = useDataContext()
  const {workshopsData, isLoadingWorkshops} = useWorkshop()
  // console.log('workshopdata in form ', workshopsData)
  const [selectedWorkshop, setSelectedWorkshop] = useState('')
  const [selectedCarType, setSelectedCarType] = useState<string | undefined>('')
  const [formTouched, setFormTouched] = useState(true) // Track whether the form has been touched

  const isServiceManager = currentUser?.data.user.role === USER_ROLE.SERVICE_MANAGER
  const formInitialData = {
    name: '',
    cost: 0,
    units: 0,
    retailPrice: 0,
    type: '',
    brand: '',
    unitType: '',
    workshop: isServiceManager ? currentUser?.data.user.workshop : '',
  }
  let initialValues = product || formInitialData

  useEffect(() => {
    if (isErrorCreateProduct || isErrorUpdateProduct) {
      Swal.fire({
        title: 'Oops ',
        text: 'Got error try again',
        icon: 'error',
        confirmButtonText: 'ok',
      }).then((result) => {
        handleInitialDataCall()
      })
    } else if (isSuccessCreateProduct || isSuccessUpdateProduct) {
      Swal.fire({
        title: 'Success',
        text: product ? 'Updated Successfully!' : 'Added Successfully!',
        icon: 'success',
        confirmButtonText: 'Ok',
      }).then((result) => {
        //setShowModal(false)
        handleInitialDataCall()
      })
    }
  }, [isSuccessCreateProduct, isSuccessUpdateProduct, isErrorCreateProduct, isErrorUpdateProduct])

  const formik = useFormik<IProduct>({
    enableReinitialize: true,
    initialValues,
    validationSchema: productDetailsSchema,
    onSubmit: (values) => {
      console.log('PRODUCT TO POST ', values)
      product ? updateProduct(values) : createProduct(values)
      // setProduct(undefined)
      //console.log('DATA =>', values)
      // console.log(
      //   createProductError,
      //   isErrorCreateProduct,
      //   isErrorUpdateProduct,
      //   isSuccessCreateProduct
      // )
      // {
      //   isLoadingUpdateProduct && <>{console.log('mlkji loading')}</>
      // }
      // {
      //   isErrorUpdateProduct && <>{console.log('mlkji got error')}</>
      // }
      // {
      //   isSuccessUpdateProduct && <>{console.log('mlkji success')}</>
      // }
    },
  })
  const handleTypeSelect = (type: string) => {
    formik.setFieldValue('type', type)
    setSelectedCarType(type)
  }
  const handleWorkshopSelect = (selectedWorkshop) => {
    formik.setFieldValue('workshop', selectedWorkshop._id)
    setSelectedWorkshop(selectedWorkshop.name)
  }
  const handleInitialDataCall = () => {
    setSelectedWorkshop(initialData.initialSelectedWorkshop)
    setSelectedCarType(initialData.initialSelectedType)
    //formik.setValues(formInitialData)
    formik.resetForm()
    setFormTouched(true)
    setProduct(undefined)
  }
  useEffect(() => {
    if (product) {
      setSelectedWorkshop(workshopsData.find((workshop) => workshop._id === product.workshop).name)
      setSelectedCarType(initialData.types.find((Cartype) => Cartype == product.type))
    } else {
      setSelectedWorkshop(initialData.initialSelectedWorkshop)
      setSelectedCarType(initialData.initialSelectedType)
    }
  }, [product])

  return (
    <div className='modal fade' id='kt_modal_product_add' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-space-between'>
            <h3 className='text-center'>{product ? 'Update Product' : 'Add Product'}</h3>
            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              data-bs-dismiss='modal'
              style={{borderRadius: '50%', border: '1px solid'}}
              onClick={(e) => {
                e.preventDefault()
                setProduct(undefined)
                setShowModal(false)
                handleInitialDataCall()
              }}
            >
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                setFormTouched(false) // Mark the form as touched when submitting
                formik.handleSubmit()
              }}
              noValidate
              className='form'
            >
              <div className='card-body border-top p-9'>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    Product Name
                  </label>

                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='Name'
                          {...formik.getFieldProps('name')}
                        />
                        {formik.touched.name && formik.errors.name && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.name}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>Units</label>

                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='number'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='Units'
                          {...formik.getFieldProps('units')}
                        />
                        {formik.touched.units && formik.errors.units && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.units}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>Unit Type</label>

                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='litres, kgs, pcs etc'
                          {...formik.getFieldProps('unitType')}
                        />
                        {formik.touched.unitType && formik.errors.unitType && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.unitType}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>Cost</label>

                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <div className='input-group'>
                          <span className='input-group-text' style={{border: '0px'}}>
                            $
                          </span>
                          <input
                            type='number'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder='Cost'
                            {...formik.getFieldProps('cost')}
                          />
                        </div>
                        {formik.touched.cost && formik.errors.cost && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.cost}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    Retail Price
                  </label>

                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <div className='input-group'>
                          <span className='input-group-text' style={{border: '0px'}}>
                            $
                          </span>
                          <input
                            type='number'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder='Retail Price'
                            {...formik.getFieldProps('retailPrice')}
                          />
                        </div>

                        {formik.touched.retailPrice && formik.errors.retailPrice && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.retailPrice}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>Type</label>

                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <Dropdown>
                          <Dropdown.Toggle variant='primary' id='dropdown-basic' className='w-100'>
                            {selectedCarType}
                          </Dropdown.Toggle>
                          <Dropdown.Menu className='w-100'>
                            {initialData.types?.map((type) => (
                              <Dropdown.Item key={type} onClick={() => handleTypeSelect(type)}>
                                {type}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                        {formik.touched.type && formik.errors.type && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.type}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {!isServiceManager && (
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                      Workshop
                    </label>

                    <div className='col-lg-8'>
                      <div className='row'>
                        <div className='col-lg-12 fv-row'>
                          <Dropdown className='w-100'>
                            <Dropdown.Toggle
                              variant='primary'
                              id='dropdown-basic'
                              className='w-100'
                            >
                              {selectedWorkshop}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className='w-100'>
                              {workshopsData?.map((workshop, index) => (
                                <Dropdown.Item
                                  key={index}
                                  onClick={() => handleWorkshopSelect(workshop)}
                                >
                                  {workshop.name}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                          {formik.touched.workshop && formik.errors.workshop && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{formik.errors.workshop}</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>Brand</label>

                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='Brand'
                          {...formik.getFieldProps('brand')}
                        />
                        {formik.touched.brand && formik.errors.brand && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.brand}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='card-footer d-flex justify-content-end py-6 px-9 '>
                <button
                  type='submit'
                  className='btn btn-primary'
                  disabled={
                    isLoadingCreateProduct ||
                    isLoadingUpdateProduct ||
                    !formik.isValid ||
                    !formik.dirty
                  }
                  data-bs-dismiss='modal'
                  // {...(isSuccessCreateProduct && {'data-bs-dismiss': 'modal'})}
                  // {...(isSuccessUpdateProduct && {'data-bs-dismiss': 'modal'})}
                >
                  {!isLoadingCreateProduct &&
                    !isLoadingUpdateProduct &&
                    (product ? 'Update' : 'Add')}
                  {(isLoadingCreateProduct || isLoadingUpdateProduct) && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export {ProductForm}
