import {useDataContext} from 'app/modules/data'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import Swal from 'sweetalert2'
import {KTIcon} from '_metronic/helpers'
import useCreateInvoiceService from '../../hooks/inspection-hooks/use-service-invoice-generation'
import {useState} from 'react'

export const AddInvoiceDetails = () => {
  const {service} = useDataContext()
  const {
    generateInvoice,
    isErrorgenerateInvoice,
    isLoadinggenerateInvoice,
    isSuccessgenerateInvoice,
  } = useCreateInvoiceService()
  const [includeServiceHistory, setIncludeServiceHistory] = useState(false)
  const [includeOtherCharges, setIncludeOtherCharges] = useState(false)
  const productDetailsSchema = Yup.object().shape({
    ...(includeOtherCharges && {name: Yup.string().required('charge name is required')}),
    ...(includeOtherCharges && {amount: Yup.string().required('Amount is required')}),
  })
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      productsToBeInvoiced: [],
      ...(includeOtherCharges && {name: ''}),
      ...(includeOtherCharges && {amount: ''}),
    },
    validationSchema: productDetailsSchema,
    onSubmit: async (values) => {
      await generateInvoice({
        serviceId: service?._id || '',
        productsToBeInvoiced: values.productsToBeInvoiced,
        includeServiceHistory: includeServiceHistory,
        ...(includeOtherCharges && {services: [{name: values?.name, charges: values?.amount}]}),
      })
      //   console.log({
      //     serviceId: service?._id || '',
      //     productsToBeInvoiced: values.productsToBeInvoiced,
      //     ...(includeOtherCharges && {Services: [{name: values?.name, charges: values?.amount}]}),
      //   })
      if (isErrorgenerateInvoice) {
        Swal.fire({
          title: 'Error',
          text: 'Error adding invoice details. Try again',
          icon: 'error',
          confirmButtonText: 'Ok',
        }).then((result) => {
          console.log('Fire error result ', result)
          formik.resetForm()
          setIncludeOtherCharges(false)
          setIncludeServiceHistory(false)
        })
      } else if (isSuccessgenerateInvoice) {
        Swal.fire({
          title: 'Success',
          text: 'updated Successfully!',
          icon: 'success',
          confirmButtonText: 'Done',
        }).then((result) => {
          formik.resetForm()
          setIncludeOtherCharges(false)
          setIncludeServiceHistory(false)
        })
      }
    },
  })
  const handleCheckboxChange = (product, event) => {
    if (event.target.checked) {
      formik.setFieldValue('productsToBeInvoiced', [...formik.values.productsToBeInvoiced, product])
    } else {
      formik.setFieldValue('productsToBeInvoiced', [
        ...formik.values.productsToBeInvoiced.filter((productItem) => product !== productItem),
      ])
    }
  }
  return (
    <div className='modal fade' id='kt_modal_service_Invoice_Details' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-space-between'>
            <h3 className='text-center'>Invoice Details</h3>
            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              data-bs-dismiss='modal'
              onClick={(e) => {
                e.preventDefault()
                formik.resetForm()
                setIncludeOtherCharges(false)
                setIncludeServiceHistory(false)
              }}
              style={{borderRadius: '50%', border: '1px solid'}}
            >
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <form onSubmit={formik.handleSubmit} noValidate className='form'>
              <div className='card-body border-top p-5'>
                {/* check the products to be invoiced */}
                <div className='row mb-6'>
                  <div className='col-lg-4 form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input widget-13-check mx-10'
                      type='checkbox'
                      checked={includeServiceHistory}
                      data-bs-trigger='hover'
                      onChange={() => {
                        setIncludeServiceHistory(!includeServiceHistory)
                      }}
                    />
                  </div>

                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <div className='fw-bold form-check form-check-sm form-check-custom form-check-solid ms-3'>
                          Email service history
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <div className='col-lg-4 form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input widget-13-check mx-10'
                      type='checkbox'
                      checked={includeOtherCharges}
                      data-bs-trigger='hover'
                      onChange={() => {
                        setIncludeOtherCharges(!includeOtherCharges)
                      }}
                    />
                  </div>

                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <div className='fw-bold form-check form-check-sm form-check-custom form-check-solid ms-3'>
                          Include Other Charges
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {includeOtherCharges && (
                  <>
                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                        charge name
                      </label>

                      <div className='col-lg-8'>
                        <div className='row'>
                          <div className='col-lg-12 fv-row'>
                            <input
                              type='text'
                              className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                              placeholder='Enter the charge name for example. mechanic charges'
                              {...formik.getFieldProps('name')}
                            />
                            {formik.touched.name && formik.errors.name && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.name}</div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                        charge Amount
                      </label>

                      <div className='col-lg-8'>
                        <div className='row'>
                          <div className='col-lg-12 fv-row'>
                            <input
                              type='number'
                              className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                              placeholder='Enter the charge amount'
                              {...formik.getFieldProps('amount')}
                            />
                            {formik.touched.amount && formik.errors.amount && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.amount}</div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    Products used
                  </label>

                  <div className='col-lg-8'>
                    {service?.products?.map((product, index) => (
                      <div className='row mt-5'>
                        <div className='col-lg-12 fv-row' key={index}>
                          <div className='form-check form-check-sm form-check-custom form-check-solid'>
                            <input
                              className='form-check-input widget-13-check mx-5'
                              type='checkbox'
                              title='check the product to include it in invoice'
                              checked={formik?.values?.productsToBeInvoiced?.some(
                                (productChecked) => product === productChecked
                              )}
                              onChange={(e) => {
                                handleCheckboxChange(product, e)
                              }}
                            />
                            <a className='text-gray-900 fw-bold text-hover-primary fs-6 '>
                              {product?.name}
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className='card-footer d-flex justify-content-end py-6 px-5 '>
                <button
                  type='submit'
                  className='btn btn-primary'
                  data-bs-dismiss='modal'
                  disabled={isLoadinggenerateInvoice || !formik.isValid || !formik.dirty}
                  // {...(isSuccessupdateService && {'data-bs-dismiss': 'modal'})}
                >
                  Submit
                  {isLoadinggenerateInvoice && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
