import {useMutation, useQueryClient} from 'react-query'
import {QUERIES_KEYS} from '../../../helpers/crud-helpers/consts'
import {updateWorkshop} from '../core/_requests'
import {IWorkshop} from '../core/_models'

function useUpdateWorkshop() {
  const queryClient = useQueryClient()

  const {mutate, isLoading, isError, isSuccess} = useMutation(
    (body: IWorkshop) => {
      const {_id, ...rest} = body
      return updateWorkshop(rest, _id ?? '')
    },
    {
      // ✅ Show success message
      onSuccess: () => {
        queryClient.invalidateQueries(QUERIES_KEYS.WORKSHOP_LIST)
      },
    }
  )

  return {
    updateWorkshop: mutate,
    isLoadingUpdateWorkshop: isLoading,
    isErrorUpdateWorkshop: isError,
    isSuccessUpdateWorkshop: isSuccess,
  }
}

export default useUpdateWorkshop
