import React, {useState, useEffect} from 'react'
import {Dropdown} from 'react-bootstrap'

interface IServiceDropdownProps {
  checks: string[] | undefined
  onSelectServices: (selectedServices: string[]) => void
  selectedServices: string[]
  setSelectedServices: (selectedServices: string[]) => void
}

const ServiceDropdown: React.FC<IServiceDropdownProps> = (props) => {
  const {checks, onSelectServices, selectedServices, setSelectedServices} = props
  //console.log('SERVICE CHECKS got in dropdown are ', checks, props)

  useEffect(() => {
    setSelectedServices([])
  }, [checks])

  const handleServiceSelect = (service: string) => {
    let updatedSelectedServices: string[]

    if (selectedServices.includes(service)) {
      updatedSelectedServices = selectedServices.filter((s) => s !== service)
    } else {
      updatedSelectedServices = [...selectedServices, service]
    }

    setSelectedServices(updatedSelectedServices)

    // Call the onSelectServices function to update the parent component
    onSelectServices(updatedSelectedServices)
  }

  return (
    <Dropdown>
      <Dropdown.Toggle variant='primary' id='dropdown-basic'>
        {selectedServices.length > 0
          ? `${selectedServices.length} services selected`
          : 'Select Services'}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {checks?.map((service, index) => (
          <Dropdown.Item
            key={index}
            onClick={() => handleServiceSelect(service)}
            active={selectedServices.includes(service)}
          >
            {service}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default ServiceDropdown
