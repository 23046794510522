/* eslint-disable jsx-a11y/anchor-is-valid */
import {Fragment} from 'react'

type Props = {
  className: string
  products: any
}

export const ProductUsed = ({className, products}: Props) => {
  return (
    <div
      className={`card overflow-y-auto  ${className} pb-10`}
      style={{maxHeight: '36%', minHeight: '50%'}}
    >
      <div className='card-header pt-1'>
        <h3 className='card-title text-gray-800 fw-bold'>Products Used</h3>
      </div>
      <div
        className=' pt-5 px-10 d-flex flex-row flex-wrap gap-3'
        style={{height: '40% !important'}}
      >
        {products?.length > 0
          ? products?.map((product, index) => {
              return (
                <Fragment key={`lw26-rows-${index}`}>
                  <p className='fs-4 fw-bold text-hover-primary text-gray-600 m-0 '>
                    {product.name},
                  </p>
                </Fragment>
              )
            })
          : 'No products Found'}
      </div>
    </div>
  )
}
