/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useCallback, useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {ServiceRow} from './serviceRow'
import {IService} from '../core/_models'
import {useDataContext} from 'app/modules/data'
import useWorkshop from 'app/pages/workshop/hooks/use-workshop'
import {useAuth} from 'app/modules/auth'
import {USER_ROLE} from 'app/constants/constants'
import {Select} from 'antd'
type Props = {
  className: string
  services: IService[]
}

const ServiceTable: React.FC<Props> = ({services}) => {
  // const {vehicles, setVehicles} = useDataContext()
  const {currentUser} = useAuth()
  const {workshopsData, isLoadingWorkshops} = useWorkshop()
  // console.log('VEHICLES in table ', vehicles, 'workshops: ', workshopsData)

  const [searchQuery, setSearchQuery] = useState('')
  const [filteredWorkshop, setFilteredWorkshop] = useState<any>([])
  const [serviceData, setServiceData] = useState<any>(undefined)

  useEffect(() => {
    if (services && services.length > 0) setServiceData(services)
  }, [services])

  const updateWorkshops = (str: string) => {
    setSearchQuery(str)
    setServiceData(() => searchArray(str))
  }

  const searchArray = useCallback(
    (searchTerm: any) => {
      searchTerm = searchTerm.toLowerCase() // Convert the search term to lowercase for case-insensitive search

      return serviceData.filter((obj) => {
        // Check if name, email, or phone contains the search term
        return ['name', 'email', 'phoneNumber', 'address', 'website', 'workshop'].some((key) => {
          const value = obj[key]
          console.log(typeof value)
          if (typeof value === 'string' || typeof value === 'number') {
            return value.toString().toLowerCase().includes(searchTerm)
          } else if (typeof value === 'object' && value?._id) {
            console.log(value, searchTerm)
            return value?._id.toString().toLowerCase().includes(searchTerm)
          }

          return false
        })
      })
    },
    [serviceData]
  )

  const filterOption = (input: string, option?: {children: string; value: string}) => {
    return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
  }

  const onSelectWorkShop = (value: string) => {
    updateWorkshops(value)
  }
  const onClearWorkShop = () => {
    setSearchQuery('')
    setServiceData(services)
  }

  const role = currentUser?.data.user.role
  return (
    <div className={`card`}>
      {/* begin::Header */}
      <div className=' w-400px ms-8 mt-8'>
        <Select
          showSearch
          allowClear
          className='w-full ml-0 h-100'
          placeholder='Select WorkShop'
          optionFilterProp='children'
          onSelect={onSelectWorkShop}
          onClear={onClearWorkShop}
          filterOption={filterOption}
          loading={isLoadingWorkshops}
        >
          {workshopsData &&
            workshopsData?.map((workShop) => (
              <Select.Option key={workShop?._id}>{`${workShop?.name}`}</Select.Option>
            ))}
        </Select>
      </div>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Services</span>

          {/* <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 new products</span> */}
        </h3>
        {(role == 'VEHICLE_OWNER' || role == 'SERVICE_MANAGER') && (
          <div className='card-toolbar'>
            <a
              href='#'
              className='btn btn-sm btn-light-primary'
              data-bs-toggle='modal'
              data-bs-target={
                role === 'VEHICLE_OWNER'
                  ? '#kt_modal_service_add'
                  : '#kt_modal_service_add_by_manager'
              }
              data-bs-trigger='hover'
              title='Click to add a service'
            >
              <KTIcon iconName='plus' className='fs-2' />
              New Service
            </a>
          </div>
        )}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      {serviceData?.length > 0 ? (
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-125px rounded-start'>VIN</th>
                  <th className='min-w-125px'>Assigned To</th>
                  <th className='min-w-125px'>Creation Date</th>
                  <th className='min-w-200px'>Vehicle Owner</th>
                  <th className='min-w-100px'>Workshop</th>
                  <th className='min-w-150px'>Status</th>
                  {currentUser?.data.user.role !== USER_ROLE.SUPER_USER && (
                    <th className='min-w-250px text-center rounded-end'>Actions</th>
                  )}
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}

              <tbody>
                {serviceData?.map((product, index) => (
                  <ServiceRow key={index} service={product} />
                ))}
              </tbody>

              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
      ) : (
        <p className='ms-10'>No services found</p>
      )}
      {/* begin::Body */}
    </div>
  )
}

export {ServiceTable}
