import {useMutation, useQueryClient} from 'react-query'

import {QUERIES_KEYS} from '../../../../helpers/crud-helpers/consts'
import {deleteProduct} from '../_request'

function useDeleteProduct() {
  const queryClient = useQueryClient()

  const {mutate, isLoading, isSuccess} = useMutation((id: string) => deleteProduct(id), {
    // ✅ Show success message
    onSuccess: () => {
      queryClient.invalidateQueries(QUERIES_KEYS.PRODUCT_LIST)
    },
  })

  return {deleteProduct: mutate, isLoadingProduct: isLoading, isSuccessProduct: isSuccess}
}

export default useDeleteProduct
