import {useMutation, useQueryClient} from 'react-query'
import {QUERIES_KEYS} from 'app/helpers/crud-helpers/consts'
import {generateHistory} from '../core/_requests'

function useGenerateServiceHistory() {
  const queryClient = useQueryClient()

  const {data, mutate, isLoading, isError, error, isSuccess} = useMutation(
    (body: {serviceId: string}) => generateHistory(body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERIES_KEYS.SERVICE_LIST)
      },
    }
  )

  return {
    generateHistory: mutate,
    generatedHistory: data,
    isLoadinggenerateHistory: isLoading,
    isErrorgenerateHistory: isError,
    errorgenerateHistory: error,
    isSuccessgenerateHistory: isSuccess,
  }
}

export default useGenerateServiceHistory
